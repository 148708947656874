// useDataLoader.ts
import { useState, useEffect, useRef } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListIndustryRequest } from '../../../../services/soap/main/requests/ListIndustryRequest';
import { Industry } from '../../../../services/soap/main/responses/ListIndustryResponse';
import { CountIndustryRequest } from '../../../../services/soap/main/requests/CountIndustryRequest';
import { ListCountryRequest } from '../../../../services/soap/translate/reqeusts/ListCountryRequest';
import { Country } from '../../../../services/soap/translate/responses/ListCountryResponse';
import { Language } from '../../../../services/soap/translate/responses/ListLanguagesResponse';
import { ListLanguagesRequest } from '../../../../services/soap/translate/reqeusts/ListLanguagesRequest';
import { Position } from '../../../../services/soap/main/responses/ListPositionResponse';
import { CountPositionRequest } from '../../../../services/soap/main/requests/CountPositionRequest';
import { ListPositionRequest } from '../../../../services/soap/main/requests/ListPositionRequest';
import { Department } from '../../../../services/soap/main/responses/ListDepartmentResponse';
import { CountDepartmentRequest } from '../../../../services/soap/main/requests/CountDepartmentRequest';
import { ListDepartmentRequest } from '../../../../services/soap/main/requests/ListDepartmentRequest';
import { Contact } from '../../../../services/soap/main/responses/GetContactListResponse';
import { GetContactListRequest } from '../../../../services/soap/main/requests/GetContactListRequest';

import { ListFPARequest2 } from '../../../../services/soap/project/requests/ListFPARequest2';
import { FPAData, FPADataFactory } from '../../../../infra/protected/FPA/FPAData';
import { usePreloadAssets } from '../../../../hooks/UsePreloadAssets';
import { Col, FilterActivity, FilterCol, FilterFolder, Order, Parent } from '../../../../services/soap/project/requests/ListFPARequest';
import { User } from '../../../../services/soap/access/responses/ListUserResponse';
import { ListUserRequest } from '../../../../services/soap/access/requests/ListUserRequest';

// Cache type
type CacheType = { [key: string]: any };
enum CacheKeys {
    INDUSTRY = 'industry',
    COUNTRY = 'country',
    LANGUAGE = 'language',
    POSITION = 'position',
    DEPARTMENT = 'department',
    CONTACT = 'contact',
    USER = 'user',
}

// Global cache object
const cache: CacheType = {};

export const useIndustryDataLoader = () => {
    const { mainService, store } = useDependency();
    const [data, setData] = useState<Industry[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.INDUSTRY]) {
                setData(cache[CacheKeys.INDUSTRY]);
                return;
            }

            setLoading(true);
            try {
                const count_response = await mainService.countIndustry(new CountIndustryRequest(store.Server, store.SessionId));
                const response = await mainService.listIndustry(new ListIndustryRequest(
                    store.Server, 
                    store.SessionId,
                    0,
                    count_response.count
                ));

                const result = response.INDUSTRY;
                cache[CacheKeys.INDUSTRY] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [mainService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const useCountryDataLoader = () => {
    const { translateService, store } = useDependency();
    const [data, setData] = useState<Country[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.COUNTRY]) {
                setData(cache[CacheKeys.COUNTRY]);
                return;
            }

            setLoading(true);
            try {
                const response = await translateService.listCountry(new ListCountryRequest(store.Server, store.SessionId, 
                    {
                        limit: '1000',
                        offset: '0',
                    }));

                const result = response.ROWS.ROW;
                cache[CacheKeys.COUNTRY] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [translateService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const useLanguageDataLoader = () => {
    const { translateService, store } = useDependency();
    const [data, setData] = useState<Language[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.LANGUAGE]) {
                setData(cache[CacheKeys.LANGUAGE]);
                return;
            }

            setLoading(true);
            try {
                const response = await translateService.listLanguages(new ListLanguagesRequest(store.Server, store.SessionId));

                const result = response.LANGUAGE;
                cache[CacheKeys.LANGUAGE] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [translateService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const usePositionDataLoader = () => {
    const { mainService, store } = useDependency();
    const [data, setData] = useState<Position[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.POSITION]) {
                setData(cache[CacheKeys.POSITION]);
                return;
            }

            setLoading(true);
            try {
                const count_response = await mainService.countPosition(new CountPositionRequest(store.Server, store.SessionId));
                const response = await mainService.listPosition(new ListPositionRequest(
                    store.Server, 
                    store.SessionId,
                    0,
                    count_response.count
                ));

                const result = response.POSITION;
                cache[CacheKeys.POSITION] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [mainService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const useDepartmentDataLoader = () => {
    const { mainService, store } = useDependency();
    const [data, setData] = useState<Department[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.DEPARTMENT]) {
                setData(cache[CacheKeys.DEPARTMENT]);
                return;
            }

            setLoading(true);
            try {
                const count_response = await mainService.countDepartment(new CountDepartmentRequest(store.Server, store.SessionId));
                const response = await mainService.listDepartment(new ListDepartmentRequest(
                    store.Server, 
                    store.SessionId,
                    0,
                    count_response.count
                ));

                const result = response.DEPARTMENT;
                cache[CacheKeys.DEPARTMENT] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [mainService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const useContactDataLoader = (additional_options: any) => {
    const { mainService, store } = useDependency();
    const [data, setData] = useState<Contact[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.CONTACT]) {
                setData(cache[CacheKeys.CONTACT]);
                return;
            }

            setLoading(true);
            try {
                const count_only_options = { ...additional_options, countOnly: true };
                const count_response = await mainService.getContactList(
                    new GetContactListRequest(
                        store.Server, store.SessionId,
                        count_only_options
                    )
                );

                const response = await mainService.getContactList(
                    new GetContactListRequest(
                        store.Server, store.SessionId,
                        { ...additional_options, offset: 0, limit: count_response.count }
                    )
                );

                const result = response.CONTACT;
                cache[CacheKeys.CONTACT] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [mainService, store.Server, store.SessionId, additional_options]);

    return { data, error, loading };
}

export const useFolderDataLoader = ({ folder_type_id, parent_folder_id }: { folder_type_id?: number, parent_folder_id?: number }) => {
    const { projectService, store } = useDependency();
    const { folderStatusMapping, projectStatusMapping, activityStatusMapping } = usePreloadAssets();
    const [data, setData] = useState<FPAData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);
    const folder_filter: FilterCol[] = [];

    if (folder_type_id) {
        folder_filter.push(new FilterCol('folderType', 'equals', folder_type_id.toString()));
    }
    if(parent_folder_id) {
        folder_filter.push(new FilterCol('parent', 'equals', parent_folder_id.toString()));
    }

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await projectService.listFPA2(
                    new ListFPARequest2(
                        store.Server, store.SessionId,
                        {
                            offset: 0,
                            limit: 100,
                            folderFilter:{
                                COL: folder_filter
                            },
                            ORDER: new Order([
                                new Col('name'), 
                                new Col('created', true)
                            ])
                        }
                    )
                );

                const result = response.ITEMS.map((item: any) => FPADataFactory.createFromItem(
                                item, 
                                folderStatusMapping, 
                                projectStatusMapping, 
                                activityStatusMapping));

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                console.log("error in Folder data load: ", err);
                if (isMounted.current) {
                    setError('Failed to fetch data: ');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [projectService, store.Server, store.SessionId, folder_type_id]);

    return { data, error, loading };

}

export const useProjectDataLoader = ({ parent_folder_id, activity_type_id }: { parent_folder_id?: number, activity_type_id?: number }) => {
    const { projectService, store } = useDependency();
    const { folderStatusMapping, projectStatusMapping, activityStatusMapping } = usePreloadAssets();
    const [data, setData] = useState<FPAData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);
    var parent: Parent | undefined= undefined;
    var project_filter:FilterFolder | undefined = undefined;

    if(parent_folder_id) {
        parent = new Parent(parent_folder_id, 'FOLDER');
    }

    if(activity_type_id) {
        project_filter = new FilterFolder([
            new FilterCol('relatedToActivityType', 'equals', activity_type_id.toString())
        ]);
    }
    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await projectService.listFPA2(
                    new ListFPARequest2(
                        store.Server, store.SessionId,
                        {
                            offset: 0,
                            limit: 100,
                            parent,
                            projectFilter: project_filter,
                            ORDER: new Order([
                                new Col('name'), 
                                new Col('created', true)
                            ])
                        }
                    )
                );

                const result = response.ITEMS ? response.ITEMS.map((item: any) => FPADataFactory.createFromItem(
                                item, 
                                folderStatusMapping, 
                                projectStatusMapping, 
                                activityStatusMapping)): [];

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                console.log("error in Project data load: ", err);
                if (isMounted.current) {
                    setError('Failed to fetch data: ');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        if(parent_folder_id) {
            fetchData();
        }

        return () => {
            isMounted.current = false;
        };
    }, [projectService, store.Server, store.SessionId, parent_folder_id]);

    return { data, error, loading };

}

export const useActivityDataLoader = (
    { 
        parent_project_id, 
        parent_activity_id, 
        related_to_activity_type 
    }: { 
        parent_project_id?: number, 
        parent_activity_id?: number,
        related_to_activity_type?: number
    }) => {
    const { projectService, store } = useDependency();
    const { folderStatusMapping, projectStatusMapping, activityStatusMapping } = usePreloadAssets();
    const [data, setData] = useState<FPAData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);
    var parent: Parent | undefined = undefined;
    var activity_filter:FilterActivity | undefined = undefined;

    if(parent_project_id) {
        parent = new Parent(parent_project_id, 'PROJECT');
    }

    if(parent_activity_id) {
        parent = new Parent(parent_activity_id, 'ACTIVITY');
    }

    if(related_to_activity_type) {
        activity_filter = new FilterActivity([
            new FilterCol('relatedToActivityType', 'equals', related_to_activity_type.toString())
        ]);
    }
    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await projectService.listFPA2(
                    new ListFPARequest2(
                        store.Server, store.SessionId,
                        {
                            offset: 0,
                            limit: 100,
                            parent,
                            flatView: related_to_activity_type ? true : false,
                            activityFilter: activity_filter,
                            ORDER: new Order([
                                new Col('name'), 
                                new Col('created', true)
                            ])
                        }
                    )
                );


                const result = response.ITEMS ? response.ITEMS.map((item: any) => FPADataFactory.createFromItem(
                                item, 
                                folderStatusMapping, 
                                projectStatusMapping, 
                                activityStatusMapping)): [];

                
                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                    setError('Failed to fetch data: ');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        if(parent_project_id || parent_activity_id || related_to_activity_type) {
            fetchData();
        }

        return () => {
            isMounted.current = false;
        };
    }, [
        projectService, 
        store.Server, 
        store.SessionId, 
        parent_project_id, 
        parent_activity_id,
        related_to_activity_type
    ]);

    return { data, error, loading };

}

export const useUserDataLoader = ( ) => {
    const { accessService, store } = useDependency();
    const [data, setData] = useState<User[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.USER]) {
                setData(cache[CacheKeys.USER]);
                return;
            }

            setLoading(true);
            try {
                const count_response = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, 
                    undefined, undefined, undefined,
                    true,undefined,undefined, 
                    true, false));

                const response = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, 
                    Math.max(20, count_response.count), 0, undefined,
                    false,undefined,undefined, 
                    true, false));

                const result = response.USERS.map((item: any) => item.USER);
                cache[CacheKeys.USER] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [accessService, store.Server, store.SessionId]);

    return { data, error, loading };
}
import { useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Cell, Column, Row, TableView, TableBody, TableHeader, Flex, Content, ProgressCircle } from '@adobe/react-spectrum';
import moment from 'moment';
import { formatDecimalToString } from '../../../../utils/helperUtil';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListProjectItemRequest } from '../../../../services/soap/item/requests/ListProjectItemRequest';
import { ListProjectItemRequestOptions_ItemGrid } from '../../../../services/soap/item/requests/ListProjectItemRequestOptions';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useTranslation } from 'react-i18next';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { GetItemRequest } from '../../../../services/soap/item/requests/GetItemRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import styles from './project_item_grid_component.module.css';

interface ProjectItemGridProps {
  showPurchasePriceBCCY?: boolean;
  showPurchasePriceCCY?: boolean;
  showSalesPriceBCCY?: boolean;
  showCatalogPrice?: boolean;
  showInvoiceContact?: boolean;
  showControllingData?: boolean;
  showBillingDate?: boolean;
  showCustomAttributes?: boolean;
  showTrackingCode?: boolean;
  showSupplier?: boolean;
  componentTitle?: string;
  minHeight?: number;
  selectedItem?: FPAData;
}

function ProjectItemGrid({
  showPurchasePriceBCCY,
  showPurchasePriceCCY,
  showSalesPriceBCCY,
  showCatalogPrice,
  showInvoiceContact,
  showControllingData,
  showBillingDate,
  showCustomAttributes,
  showTrackingCode,
  showSupplier,
  componentTitle = 'Business Case',
  minHeight = 64,
  selectedItem,
}: ProjectItemGridProps) {
  const { t } = useTranslation();
  const componentRef = useRef<HTMLDivElement>(null);
  const [componentWidth, setComponentWidth] = useState<number>(0);
  const [reloadComponent] = useComonentReload();
  const { itemService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const [rowsData, setRowsData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  const [columnsData, setColumnsData] = useState<any[]>([
    { name: t('product_name', { ns: 'layout_components' }), key: 'productName' },
    { name: t('quantity', { ns: 'layout_components' }), key: 'quantity', width: 100 },
    { name: t('sold_quantity', { ns: 'layout_components' }), key: 'soldQuantity', width: 120 }, //Custom
    { name: t('remaining_sold', { ns: 'layout_components' }), key: 'remainingSold', width: 130 }, //Custom
    { name: t('purchase_quantity', { ns: 'layout_components' }), key: 'purchaseQuantity', width: 140 }, //Custom
    { name: t('remaining_purchase', { ns: 'layout_components' }), key: 'remainingPurchase', width: 160 }, //Custom
    { name: t('attrib0', { ns: 'layout_components' }), key: 'attrib0', width: 100 }, //Custom
    { name: t('unit_sales_price', { ns: 'layout_components' }), key: 'unitPriceCurr', width: 150 },
    { name: t('sales_price', { ns: 'layout_components' }), key: 'totalAmountBccy', width: 100 }, //Custom
    { name: t('total_sales_price', { ns: 'layout_components' }), key: 'totalPriceCurr', width: 140 },
    { name: t('unit_purchase_price', { ns: 'layout_components' }), key: 'purchasePriceCurr', width: 150 },
    { name: t('purchase_price', { ns: 'layout_components' }), key: 'totalPurchaseBccy', width: 150 }, //Custom
    { name: t('billing_date', { ns: 'layout_components' }), key: 'billingDateFormatted', width: 100 }, //Custom
    { name: t('supplier', { ns: 'layout_components' }), key: 'supplierName', width: 150 }, //Custom
    { name: t('ratio', { ns: 'layout_components' }), key: 'ratio', width: 50 },
  ]);

  const [columnsDataMobile, setColumnsDataMobile] = useState<any[]>([
    { name: t('product_name', { ns: 'layout_components' }), key: 'productName' },
    { name: t('quantity', { ns: 'layout_components' }), key: 'quantity', width: 100 },
    { name: t('sold_quantity', { ns: 'layout_components' }), key: 'soldQuantity', width: 120 }, //Custom
    { name: t('remaining_sold', { ns: 'layout_components' }), key: 'remainingSold', width: 130 }, //Custom
    { name: t('purchase_quantity', { ns: 'layout_components' }), key: 'purchaseQuantity', width: 140 }, //Custom
    { name: t('remaining_purchase', { ns: 'layout_components' }), key: 'remainingPurchase', width: 160 }, //Custom
    { name: t('attrib0', { ns: 'layout_components' }), key: 'attrib0', width: 100 }, //Custom
    { name: t('unit_sales_price', { ns: 'layout_components' }), key: 'unitPriceCurr', width: 150 },
    { name: t('sales_price', { ns: 'layout_components' }), key: 'totalAmountBccy', width: 100 }, //Custom
    { name: t('total_sales_price', { ns: 'layout_components' }), key: 'totalPriceCurr', width: 140 },
    { name: t('unit_purchase_price', { ns: 'layout_components' }), key: 'purchasePriceCurr', width: 150 },
    { name: t('purchase_price', { ns: 'layout_components' }), key: 'totalPurchaseBccy', width: 150 }, //Custom
    { name: t('billing_date', { ns: 'layout_components' }), key: 'billingDateFormatted', width: 100 }, //Custom
    { name: t('supplier', { ns: 'layout_components' }), key: 'supplierName', width: 150 }, //Custom
    { name: t('ratio', { ns: 'layout_components' }), key: 'ratio', width: 100 },
  ]);

  useEffect(() => {
    if (componentRef.current) {
      setComponentWidth(componentRef.current.offsetWidth);
    }
  }, [componentRef.current?.offsetWidth]);

  useEffect(() => {
    // Define the columns to hide based on their visibility state
    const columnsToHide: any = [
      { key: 'totalPurchaseBccy', visible: showPurchasePriceBCCY },
      { key: 'totalAmountBccy', visible: showSalesPriceBCCY },
      { key: 'attrib0', visible: showCustomAttributes },
      { key: 'billingDateFormatted', visible: showBillingDate },
      { key: 'supplierName', visible: showSupplier },
      { key: 'soldQuantity', visible: showControllingData },
      { key: 'remainingSold', visible: showControllingData },
      { key: 'purchaseQuantity', visible: showControllingData },
      { key: 'remainingPurchase', visible: showControllingData },
    ];

    // Filter the columnsData array to exclude columns that are not visible
    const filteredColumnsData = columnsData.filter(column => columnsToHide.every((col: any) => col.visible || col.key !== column.key));

    // Filter the columnsDataMobile array to exclude columns that are not visible
    const filteredColumnsDataMobile = columnsDataMobile.filter(column => columnsToHide.every((col: any) => col.visible || col.key !== column.key));

    // Update the state with the filtered columns data
    setColumnsData(filteredColumnsData);
    setColumnsDataMobile(filteredColumnsDataMobile);
  }, [
    // Dependencies: the effect will run whenever any of these visibility states change
    showPurchasePriceBCCY,
    showPurchasePriceCCY,
    showSalesPriceBCCY,
    showCatalogPrice,
    showInvoiceContact,
    showControllingData,
    showBillingDate,
    showCustomAttributes,
    showTrackingCode,
  ]);

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      await loadProjectItemGridData();
      setShowLoader(false);
    })();
  }, [selectedItem, reloadComponent]);

  const updateProjectItemGridData = async (projectItemGridData: any[]) => {
    if (!projectItemGridData) return [];
    return Promise.all(
      projectItemGridData.map(async item => {
        const partNumber = await getPartNumber(item);
        return { ...item, _refId: `${partNumber}_${item.productName}_ref`, partNumber };
      })
    );
  };

  const setRecordIds = (record: any, selectedItem: any) => {
    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        record.folderId = selectedItem?.parent?.parent_id;
        record.projectId = String(selectedItem?.parent_id);
        record.activityId = String(selectedItem?.id);
        break;
      case FPADataTypes.PROJECT:
        record.folderId = '';
        record.projectId = String(selectedItem?.id);
        record.activityId = '';
        break;
      case FPADataTypes.FOLDER:
        record.folderId = String(selectedItem?.id);
        record.projectId = '';
        record.activityId = '';
        break;
    }
  };

  // Function to load project product data
  const loadProjectItemGridData = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);
    let record: any = {};
    setRecordIds(record, selectedItem);

    // Call API to fetch project product data
    let projecrProdRes: any = await itemService.listProjectItem(new ListProjectItemRequest(store.Server, store.SessionId, 50, 0, new ListProjectItemRequestOptions_ItemGrid(record.projectId)));

    // If API call is successful
    if (projecrProdRes?.result === 'OK') {
      //console.log('projecrProdRes', projecrProdRes);
      // Map the project product data to desired format
      let projectItemGridData = projecrProdRes?.ROWS?.map((item: any) => {
        return {
          id: item.ITEM.id,
          quantity: formatDecimalToString(Number.parseFloat(item.ITEM.quantity).toFixed(2)),
          partID: item.ITEM.item,
          _refId: '',
          partNumber: 'Part Number',
          productName: item.ITEM.name,
          supplier: item.ITEM.supplier,
          supplierName: item.ITEM.supplierName,
          currency: item.ITEM.currency,
          vatValue: item.ITEM.vatValue,
          amountWithVat: item.ITEM.amountWithVat,
          unitPrice: formatDecimalToString(item.ITEM.amount),
          unitPriceCurr: formatDecimalToString(item.ITEM.amount) + ' ' + item.ITEM.currency,
          totalPrice: formatDecimalToString(Number.parseFloat(item.ITEM.totalAmount).toFixed(2)),
          totalPriceCurr: formatDecimalToString(Number.parseFloat(item.ITEM.totalAmount).toFixed(2)) + ' ' + item.ITEM.currency,
          purchasePrice: item.ITEM.purchasePrice,
          purchasePriceCurr: formatDecimalToString(Number.parseFloat(item.ITEM.purchasePrice).toFixed(2)) + ' ' + item.ITEM.currency,
          totalPurchase: item.ITEM.totalPurchase,
          totalPurchaseCurr: formatDecimalToString(Number.parseFloat(item.ITEM.totalPurchase).toFixed(2)) + ' ' + item.ITEM.currency,
          totalPurchaseBccy: item.ITEM.totalPurchaseBccy,
          totalPurchaseBccyCurr: formatDecimalToString(Number.parseFloat(item.ITEM.totalPurchaseBccy).toFixed(2)) + ' ' + item.ITEM.currency,
          totalAmountBccy: item.ITEM.totalAmountBccy,
          totalAmountBccyCurr: formatDecimalToString(Number.parseFloat(item.ITEM.totalAmountBccy).toFixed(2)) + ' ' + item.ITEM.currency,
          totalAmountWithVat: item.ITEM.totalAmountWithVat,
          refId: item.ITEM.refId,
          billingDate: item.ITEM.billingDate,
          billingDateFormatted: moment(item.ITEM.billingDate).format('MM/DD/YYYY'),
          ratio: item.ITEM.ratio + '%',
          attrib0: item.ITEM.attrib0,
          soldQuantity: formatDecimalToString(Number.parseFloat(item.ITEM.totalQuantity).toFixed(2)),
          remainingSold: '0',
          purchaseQuantity: formatDecimalToString(Number.parseFloat(item.ITEM.freeValue).toFixed(2)),
          remainingPurchase: '0',
        };
      });

      // Update project product data with additional information
      updateProjectItemGridData(projectItemGridData).then((updatedData: any) => {
        //console.log('updatedData', updatedData);
        setRowsData(updatedData);
        //console.log('rowsData', rowsData);
      });
    }
  };

  const getPartNumber = async (item: any) => {
    //console.log('item', item);
    let itemResp = await itemService.getItem(new GetItemRequest(store.Server, store.SessionId, item.partID));
    //console.log('itemResp', itemResp);
    return itemResp.ITEM.refId;
  };

  const handleRowClick = (rowId: any): void => {
    //console.log('rowId', rowId);
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            {componentTitle}
          </Content>

          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <></>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px', M: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            <div ref={componentRef}>
              {rowsData.length > 0 && (
                <TableView
                  UNSAFE_className="tblLayoutComponent"
                  aria-label="Project Item"
                  onSelectionChange={handleRowClick}
                  selectionMode="single"
                  selectionStyle="highlight"
                  maxWidth={componentWidth}
                  minHeight={'80px'}
                  maxHeight={{ base: '1000px', L: '450px' }}
                  marginBottom={'size-250'}
                >
                  <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                    {column => (
                      <Column showDivider key={column.key} width={column?.width}>
                        {column.name}
                      </Column>
                    )}
                  </TableHeader>
                  <TableBody items={rowsData}>{item => <Row>{columnKey => <Cell>{(item as any)[String(columnKey)]}</Cell>}</Row>}</TableBody>
                </TableView>
              )}
            </div>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export const ProjectItemGridComponent = LayoutComponent(ProjectItemGrid);

import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { Divider, Flex } from '@adobe/react-spectrum';
import { CreateCompanyFields, CreateContextInfoFields, CreateCustomFormFieldForContextType, CreateCustomFormFields, CreateEmployeeFields, CreatePersonFields, CreateSimpleFolderFields, CreateTrackingCodeFields } from './ContextFields';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';
import { FPAData, FPADataTypes } from '../../../infra/protected/FPA/FPAData';

export interface IFolderContextFieldsProps {
    settings: SettingItem[];
    parentItem: FPAData | null;
    selectedItem?: FPATemplateItem;
    setFormData?: (key: string, value: any, isNew: boolean) => void;
    contextTypeFormId?: string;
    activityType?: number;
}

export function FolderContextFields ({
    settings,
    parentItem,
    selectedItem,
    setFormData,
    contextTypeFormId,
    activityType
}: IFolderContextFieldsProps) {
  return (
    <Flex direction={'column'} gap={'size-100'}>
        <CreateCompanyFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateTrackingCodeFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        {parentItem !== null && parentItem.type === FPADataTypes.FOLDER && (
          <>
          <Divider size='M' />
          <CreateContextInfoFields settings={settings} 
                                parentItem={parentItem} 
                                selectedItem={selectedItem} 
                                setFormData={setFormData} 
                                folderType={selectedItem?.itemType} 
                                showFolders={true} 
                                showProjects={false} 
                                showActivities={false} 
                                activityType={activityType} 
                              />
          </>
        )}
        <Divider size='M' />
        <CreatePersonFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateEmployeeFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateSimpleFolderFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateCustomFormFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateCustomFormFieldForContextType formId={contextTypeFormId} setFormData={setFormData} />
    </Flex>    
  );
}

import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IListDistributionGroupRequest extends IBaseRequest{
    projectId?: number;
}

export class ListDistributionGroupRequest extends BaseRequest implements IListDistributionGroupRequest {
    projectId?: number;

    constructor(server: string, 
        session: string,
        projectId?: number) {
            super(server, session);
            this.projectId = projectId;
    }

    toXml(): string {
        var parameters:string = super.toXml();
        if(this.projectId){
            parameters += `<projectId>${this.projectId}</projectId>`;
        }

        return super.wrapperXml('ListDistributionGroup', parameters);
    }
}

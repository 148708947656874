import { ServiceBase } from "../ServiceBase";
import { MESSAGE } from "../../../utils/ServiceUtils";
import { INewMessageRequest } from "./requests/NewMessageRequest";
import { NewMessageResponse } from "./responses/NewMessageResponse";
import { IListMessageRequest } from "./requests/ListMessageRequest";
import { ListMessageResponse } from "./responses/ListMessageResponse";
import { IListMessageTemplateRequest } from "./requests/ListMessageTemplateRequest";
import { ListMessageTemplateResponse } from "./responses/ListMessageTemplateResponse";
import { ListMessageContainerNotificationResponse } from "./responses/ListMessageContainerNotificationResponse";
import { IListMessageContainerNotificationRequest } from "./requests/ListMessageContainerNotificationRequest";
import { ListMessageActionResponse } from "./responses/ListMessageActionResponse";
import { IListMessageActionRequest } from "./requests/ListMessageActionRequest";
import { ISetSeenMessageRequest } from "./requests/SetSeenMessageRequest";
import { SetSeenMessageResponse } from "./responses/SetSeenMessageResponse";
import { IGetMessageRequest } from "./requests/GetMessageRequest";
import { GetMessageResponse } from "./responses/GetMessageResponse";

export interface IMessageService {
    newMessage(request:INewMessageRequest): Promise<NewMessageResponse>;
    listMessage(request:IListMessageRequest): Promise<ListMessageResponse>;
    listMessageTemplate(request:IListMessageTemplateRequest): Promise<ListMessageTemplateResponse>;
    listMessageContainerNotification(request:IListMessageContainerNotificationRequest): Promise<ListMessageContainerNotificationResponse>;
    listMessageAction(request:IListMessageActionRequest): Promise<ListMessageActionResponse>;
    setSeenMessage(request:ISetSeenMessageRequest): Promise<SetSeenMessageResponse>;
    getMessage(request: IGetMessageRequest): Promise<GetMessageResponse>;
}

export class MessageService extends ServiceBase implements IMessageService {
    public async newMessage(request:INewMessageRequest): Promise<NewMessageResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), MESSAGE.newMessage, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(MESSAGE.newMessage, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(MESSAGE.newMessage, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['TO', 'CC', 'BCC', 'USER', 'ITEM', 'CONTACT_RELATION']).NewMessageResponse as NewMessageResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listMessage(request:IListMessageRequest): Promise<ListMessageResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), MESSAGE.listMessage, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(MESSAGE.listMessage, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(MESSAGE.listMessage, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['MESSAGE', 'CONTACT_RELATION']).ListMessageResponse as ListMessageResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listMessageTemplate(request:IListMessageTemplateRequest): Promise<ListMessageTemplateResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), MESSAGE.listMessageTemplate, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(MESSAGE.listMessageTemplate, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(MESSAGE.listMessageTemplate, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['ITEM', 'TO']).ListMessageTemplateResponse as ListMessageTemplateResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listMessageContainerNotification(request:IListMessageContainerNotificationRequest): Promise<ListMessageContainerNotificationResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), MESSAGE.listMessageContainerNotification, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(MESSAGE.listMessageContainerNotification, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(MESSAGE.listMessageContainerNotification, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['ROW']).ListMessageContainerNotificationResponse as ListMessageContainerNotificationResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listMessageAction(request:IListMessageActionRequest): Promise<ListMessageActionResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), MESSAGE.listMessageAction, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(MESSAGE.listMessageAction, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(MESSAGE.listMessageAction, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['ROW']).ListMessageActionResponse as ListMessageActionResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async setSeenMessage(request:ISetSeenMessageRequest): Promise<SetSeenMessageResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), MESSAGE.setSeenMessage, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(MESSAGE.setSeenMessage, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(MESSAGE.setSeenMessage, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, []).SetSeenMessageResponse as SetSeenMessageResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async getMessage(request: IGetMessageRequest): Promise<GetMessageResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), MESSAGE.getMessage, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(MESSAGE.getMessage, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(MESSAGE.getMessage, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,[]).GetMessageResponse as GetMessageResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
}
import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Folder, toFolderXml } from '../../dataObjects/Folder';

export interface ICreateSimpleFolderRequest extends IBaseRequest {
    folder:    Folder;
}

export class CreateSimpleFolderRequest extends BaseRequest implements ICreateSimpleFolderRequest {
    public folder: Folder;
    constructor(server: string, session: string, _folder: Folder) {
        super(server, session);
        this.folder = _folder;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        if(this.folder) parameters += toFolderXml(this.folder);
        return super.wrapperXml('CreateSimpleFolder', parameters);
    }
}

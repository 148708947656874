import { useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import {
  Flex,
  View,
  Link,
  ProgressCircle,
  DialogTrigger,
  Dialog,
  Content,
  ActionButton,
  Button,
  Form,
  TextArea,
  Footer,
  ComboBox,
  Item,
  Provider,
  DatePicker,
  MenuTrigger,
  Menu,
} from '@adobe/react-spectrum';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { ToastQueue } from '@react-spectrum/toast';
import { useReefConfig } from '../../../../hooks/UseReefConfig';
import { getImagePath, ImageVariant } from '../../../../utils/ImagePathUtils';
import { ListTimelineRequest } from '../../../../services/soap/features/requests/ListTimelineRequest';
import { NewMessageRequest } from '../../../../services/soap/message/requests/NewMessageRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import { ListUserRequest } from '../../../../services/soap/access/requests/ListUserRequest';
import { ListFolderTypeRequest } from '../../../../services/soap/project/requests/ListFolderTypeRequest';
import { ListProjectTypeRequest } from '../../../../services/soap/project/requests/ListProjectTypeRequest';
import { ListActivityTypeRequest } from '../../../../services/soap/project/requests/ListActivityTypeRequest';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { invokeFinderApp } from '../../../../utils/NavigationUtils';
import styles from './TimeLineComponent.module.css';

interface TimeLineProps {
  selectedItem?: FPAData;
}

interface Header {
  title: string;
}

type formDataType = {
  userId: string;
  periodFrom: any;
  periodTo: any;
  entryType: Set<any>;
  marketingType: Set<any>;
  contextType: Set<any>;
};

function TimeLine({ selectedItem }: TimeLineProps) {
  const { t } = useTranslation();
  const PAGE_SIZE: number = 30;
  const { baseUrl } = useReefConfig();
  const [reloadComponent] = useComonentReload();
  const { projectService, accessService, messageService, featureService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const [showLoader, setShowLoader] = useState(false);
  const [offset, setOffset] = useState(0);
  const [groupedData, setGroupedData] = useState<Record<string, any[]>>({});
  const [arrHeaders, setArrHeaders] = useState<Header[]>([]);

  const [visibilityStatesID, setVisibilityStatesID] = useState<any>();
  const [iconClassesID, setIconClassesID] = useState<any>();

  const [userData, setUserData] = useState<any>([]);
  const [contextType, setContextType] = useState<any>([]);
  let [requestParams, setRequestParams] = useState<any>([]);
  const [formValue, setFormValue] = useState<formDataType>({
    userId: '',
    periodFrom: null,
    periodTo: null,
    entryType: new Set(),
    marketingType: new Set(),
    contextType: new Set(),
  });
  const newComment = useRef('');
  const secondLastItemRef = useRef<HTMLDivElement | null>(null);
  const scrollId = useRef(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const [componentWidth, setComponentWidth] = useState<number>(0);

  let typeEntryOptions = [
    { id: 'EMAIL', name: 'Email' },
    { id: 'NOTICE', name: 'Notice' },
    { id: 'MEETING', name: 'Meeting' },
    { id: 'MASSEMAIL', name: 'Massmail' },
    { id: 'MAILMERGE', name: 'Mailmerge' },
    { id: 'DOCUMENT', name: 'Document' },
    { id: 'WORKFLOW', name: 'Workflow' },
    { id: 'PHONECALL', name: 'Phonecall' },
    { id: 'LETTER', name: 'Letter' },
    { id: 'SMS', name: 'SMS' },
    { id: 'FORM', name: 'Form' },
    { id: 'ACTIVITY', name: 'Activity' },
    { id: 'PROJECT', name: 'Project' },
    { id: 'FOLDER', name: 'Folder' },
    { id: 'MESSAGE', name: 'Message' },
  ];

  let typeMarketingOptions = [
    { id: 'GOAL-ACHIEVED', name: 'Goal achieved' },
    { id: 'UNSUBSCRIBE,', name: 'Unsubscribe' },
    { id: 'MAIL-OPEN', name: 'Mail open' },
    { id: 'URL-VISIT', name: 'URL visit' },
    { id: 'MAIL-URL-VISIT', name: 'Main URL visit' },
    { id: 'NEW-PHONE-NUMBER', name: 'New phone number' },
  ];

  useEffect(() => {
    if (componentRef.current) {
      setComponentWidth(componentRef.current.offsetWidth);
    }
  }, [componentRef.current?.offsetWidth]);

  useEffect(() => {
    (async () => {
      await reloadTimeline();
      getUserCount().then((totalRecords: any) => getUserData(totalRecords));
      await getFolderProjectActivityType();
    })();
  }, [selectedItem, reloadComponent]);

  const getUserCount = async () => {
    let userList = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, undefined, undefined, '1'));
    if (userList.result === 'OK') {
      return userList.count;
    }
  };

  const getUserData = async (totalRecords: number) => {
    let userResp = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, totalRecords));

    if (userResp.result === 'OK') {
      const formattedArray = userResp.USERS.map(({ USER }) => ({
        id: USER.id,
        name: `${USER.name1} ${USER.name3}`,
      }));
      setUserData(formattedArray);
    }
  };

  const getFolderProjectActivityType = async () => {
    const [folderTypes, projectTypes, activityTypes] = await Promise.all([
      projectService.listFolderType(new ListFolderTypeRequest(store.Server, store.SessionId, true)).then(res => res.TYPE_LIST),
      projectService.listProjectType(new ListProjectTypeRequest(store.Server, store.SessionId)).then(res => res.TYPE_LIST),
      projectService.listActivityType(new ListActivityTypeRequest(store.Server, store.SessionId, {})).then(res => res.TYPE),
    ]);

    const extractIdAndName = (types: any[], key: string) =>
      types.map((type: any) => ({
        id: type[key]?.id || type.id,
        name: type[key]?.name || type.name,
      }));

    const folderTypeDetails = extractIdAndName(folderTypes, 'TYPE');
    const projectTypeDetails = extractIdAndName(projectTypes, 'TYPE');
    const activityTypeDetails = extractIdAndName(activityTypes, '');

    const allTypeDetails = [...folderTypeDetails, ...projectTypeDetails, ...activityTypeDetails];

    setContextType(allTypeDetails);
  };

  // Function to load the timeline data
  const loadTimeline = async (newOffset = 0, reqParams: any = []) => {
    setShowLoader(true); // Show loader while loading data
    try {
      // Fetch timeline data from the server
      const resTimeline = await featureService.listTimeline(
        new ListTimelineRequest(store.Server, store.SessionId, {
          limit: PAGE_SIZE,
          offset: newOffset,
          contextRoot: selectedItem?.id,
          includeSlave: 'false',
          ...reqParams,
          ORDER: { COL: [{ name: 'created', desc: 'true' }] },
        })
      );

      //console.log('"resTimeline"', resTimeline);

      // If no more data to load, show a toast message and stop the loader
      if (resTimeline.count == 0) {
        ToastQueue.info(t('no_more_data_to_load', { ns: 'layout_components' }), { timeout: 100 });
        setShowLoader(false);
        return;
      }

      // Group the timeline by created month
      const grouped = groupByCreatedMonth(resTimeline?.CHANGE);
      // Create headers based on the grouped data
      const headers = Object.keys(grouped).map(date => ({ title: date }));
      // Update the headers state
      setArrHeaders(prevHeaders => [...prevHeaders, ...headers]);

      // Update the timeline data with formatted date and other properties
      const updatedData = await updateTimelineData(grouped, headers);
      // Re-Group the updated data by created month
      const newGrouped = groupByCreatedMonth(updatedData);

      // Merge the new grouped data with the existing grouped data
      setGroupedData(prevData => mergeGroupedData(prevData, newGrouped));

      // Update the offset for the next load
      setOffset(newOffset + PAGE_SIZE);
      if (newOffset > 0) {
        // Scroll to the second last item of the previous load after the DOM updates
        setTimeout(() => {
          secondLastItemRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      }
    } catch (error) {
      console.error('Error loading timeline:', error); // Log any errors
    } finally {
      setShowLoader(false); // Hide loader after loading data
    }
  };

  // Function to merge new grouped data with previous data
  const mergeGroupedData = (prevData: any, newGrouped: Record<string, any[]>) => {
    const lastId = getLastIdOfPrevData(prevData);
    scrollId.current = lastId;
    const mergedData = { ...prevData }; // Create a shallow copy of the previous data
    for (const [key, value] of Object.entries(newGrouped)) {
      if (!mergedData[key]) {
        mergedData[key] = value; // If the key does not exist in mergedData, directly assign the value
      } else {
        // If the key exists, create a Map to track unique items based on their id
        const uniqueItemsMap = new Map(mergedData[key].map((item: any) => [item.id, item]));
        // Add all new items in value to the Map, overwriting duplicates
        value.forEach((item: any) => uniqueItemsMap.set(item.id, item));
        // Convert the Map values back to an array and assign it to mergedData[key]
        mergedData[key] = Array.from(uniqueItemsMap.values());
      }
    }

    // Extract ids from the merged data
    const ids = extractIds(mergedData);
    // Create visibility states map with initial value false
    setVisibilityStatesID(createStateMap(ids, false));
    // Create icon classes map with initial value 'bi bi-plus-circle'
    setIconClassesID(createStateMap(ids, 'bi bi-plus-circle'));

    return mergedData; // Return the merged data with unique items
  };

  // Function to create a state map for given ids with an initial value
  const createStateMap = (ids: any, initialValue: any) => {
    return ids.reduce((acc: any, id: any) => {
      acc[id] = initialValue;
      return acc;
    }, {});
  };

  // Function to extract ids from the data
  const extractIds = (data: any) => {
    return Object.values(data)
      .flatMap((items: any) => items)
      .filter((item: any) => item.id)
      .map((item: any) => item.id);
  };

  const getLastIdOfPrevData = (prevData: any) => {
    // Get all keys of prevData
    const keys = Object.keys(prevData);

    // If there are no keys, return null
    if (keys.length === 0) return null;

    // Get the last key
    const lastKey = keys[keys.length - 1];

    // Get the array associated with the last key
    const lastArray = prevData[lastKey];

    // If the array is empty, return null
    if (lastArray.length === 0) return null;

    // Get the last item in the array
    const lastItem = lastArray[lastArray.length - 1];

    // Return the id of the last item
    return lastItem.id;
  };

  const loadMore = () => {
    loadTimeline(offset, requestParams);
  };

  const reloadTimeline = async (reqParams = []) => {
    setGroupedData({});
    setArrHeaders([]);
    setOffset(0);
    newComment.current = '';
    await loadTimeline(0, reqParams);
  };

  const updateTimelineData = async (grouped: Record<string, any[]>, headers: any[]) => {
    if (!grouped) return [];

    const results = await Promise.all(
      headers.map(async header => {
        const items = grouped[header.title] || [];
        return Promise.all(
          items.map(async (item: any) => ({
            ...item,
            date: formatDetailedDate(item.created),
            contextPath: await getFullPath(item),
            contextPathArray: await getFullPathArray(item),
            title: createTimeLineTitle(item.objectName, item.changeType, item),
            status: (item.objectName == 'FOLDER' || item.objectName == 'PROJECT' || item.objectName == 'ACTIVITY') && item.ITEMS.ITEM.length >= 3 ? getItemValue('customStateName', item) : '',
            statusColor: (item.objectName === 'FOLDER' || item.objectName === 'PROJECT' || item.objectName === 'ACTIVITY') && getItemValue('customStateColor', item),
            emailText: item.objectName === 'EMAIL' ? getItemValue('teaser', item) : '',
            formValueName: item.objectName === 'FORMVALUE' ? item.ITEMS.ITEM[0]?.name : '',
            formValueNew: item.objectName === 'FORMVALUE' ? item.ITEMS.ITEM[0]?.valueNew : '',
            formValueOld: item.objectName === 'FORMVALUE' ? item.ITEMS.ITEM[0]?.valueOld ?? '' : '',
            profileImage: getImagePath(item.profileImage, ImageVariant.PICTURE_SMALL, baseUrl, store.Server, store.SessionId),
          }))
        );
      })
    );
    return results.flat();
  };

  const groupByCreatedMonth = (data: any[]): Record<string, any[]> => {
    return data.reduce((acc: any, item: any) => {
      const createdDate = new Date(item.created);
      const monthYear = `${createdDate.getFullYear()}-${String(createdDate.getMonth() + 1).padStart(2, '0')}`;
      if (!acc[monthYear]) acc[monthYear] = [];
      acc[monthYear].push(item);
      return acc;
    }, {});
  };

  const formatDate = (dateString: string) => {
    const [year, month] = dateString.split('-');
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return `${monthNames[parseInt(month, 10) - 1]} ${year}`;
  };

  const formatDetailedDate = (dateString: string) => {
    const date = moment(dateString);
    //return date.format('MM.DD.YYYY HH:mm');
    if (date.isSame(moment(), 'day')) {
      return t('today', { ns: 'layout_components' });
    } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
      return t('yesterday', { ns: 'layout_components' });
    } else {
      return date.format('MM.DD.YYYY HH:mm');
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const getFullPath = async (item: any) => {
    if (selectedItem?.type === FPADataTypes.ACTIVITY) return '';

    const response = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, item.context));
    let modifiedList = response.LIST.toReversed();
    let strPath = '';

    // Handle different index start conditions for PROJECT and FOLDER
    const startIndex = selectedItem?.type === FPADataTypes.PROJECT ? 1 : 0;

    modifiedList.forEach((item: any, index: number) => {
      if (index !== startIndex) {
        strPath += item.CONTEXT.name + ' > ';
      }
    });

    // Remove the trailing ' > ' if it exists
    return strPath.endsWith(' > ') ? strPath.slice(0, -3) : strPath;
  };

  const getFullPathArray = async (item: any) => {
    if (selectedItem?.type === FPADataTypes.ACTIVITY) return [];
    let arrPath: any = [];
    const response = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, item.context));
    let modifiedList = response.LIST.toReversed();
    // Handle different index start conditions for PROJECT and FOLDER
    //const startIndex = selectedItem?.type === FPADataTypes.PROJECT || selectedItem?.type === FPADataTypes.FOLDER ? 1 : 0;
    let startIndex = 0;
    if (selectedItem?.type === FPADataTypes.FOLDER && item.objectName === 'PROJECT') {
      startIndex = 2;
    } else if (selectedItem?.type === FPADataTypes.PROJECT) {
      startIndex = 1;
    }
    modifiedList.forEach((item: any, index: number) => {
      if (index !== startIndex) {
        arrPath.push({ id: item.CONTEXT.id, name: item.CONTEXT.name });
      }
    });
    return arrPath;
  };

  const toggleVisibility = (id: any) => {
    const newVisibilityStatesID = { ...visibilityStatesID };
    const newIconClassesID = { ...iconClassesID };

    newVisibilityStatesID[id] = !newVisibilityStatesID[id];
    newIconClassesID[id] = newVisibilityStatesID[id] ? 'bi bi-dash-circle' : 'bi bi-plus-circle';

    setVisibilityStatesID(newVisibilityStatesID);
    setIconClassesID(newIconClassesID);

    //console.log('newVisibilityStatesID', newVisibilityStatesID);
    //console.log('newIconClassesID', newIconClassesID);
  };

  const getItemValue = (name: string, item: any) => {
    const foundItem = item.ITEMS.ITEM.find((i: any) => i.name === name);
    return foundItem ? foundItem.valueNew : '';
  };

  const getChangeStatusValue = (name: string, item: any) => {
    const foundItem = item.ITEMS.ITEM.find((i: any) => i.name === name);
    return foundItem ? `Status changes from ${foundItem.valueOld} to ${foundItem.valueNew}` : '';
  };

  const createTimeLineTitle = (objectName: string, changeType: string, item: any) => {
    switch (objectName) {
      case 'EMAIL':
        return getItemValue('subject', item) || 'Email';
      case 'NOTICE':
        return getItemValue('comment', item);
      case 'FOLDER':
      case 'PROJECT':
      case 'ACTIVITY':
        if (changeType === 'UPDATED') {
          return getChangeStatusValue('customStateName', item);
        } else {
          const name = getItemValue('name', item);
          const comment = getItemValue('comment', item);
          const activityTypeName = getItemValue('activityTypeName', item);
          return [name && `Name: ${name}`, comment && `Comment: ${comment}`, activityTypeName && `Type: ${activityTypeName}`].filter(Boolean).join(' ').trim();
        }
      case 'FORMVALUE':
        return item.description;
      default:
        return '';
    }
  };

  const createFormValueDetail = (item: any) => {
    //console.log('createFormValueDetail', item.ITEMS);
    return (
      <Flex
        top={'10px'}
        direction="column"
        position="relative"
        justifyContent="start"
        alignContent="start"
        alignItems="start"
        width="100%"
        UNSAFE_style={{ float: 'right', overflowY: 'auto', scrollBehavior: 'smooth' }}
      >
        <View width={'100%'}>
          <table width={'100%'}>
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
              </tr>

              {item.ITEMS.ITEM.map((item: any) => (
                <tr>
                  <td>
                    {item.name}:{item.valueOld}
                  </td>
                  <td>{item.valueNew}</td>
                </tr>
              ))}
            </thead>
          </table>
          {/* {item.ITEMS.ITEM.map((item: any) => (
            <View key={item.id}>
              <View UNSAFE_style={{ lineHeight: '18px' }}>{item.name}</View>
              <View UNSAFE_style={{ lineHeight: '18px' }}>From: {item.valueOld}</View>
              <View UNSAFE_style={{ lineHeight: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>To: {item.valueNew}</View>
            </View>
          ))} */}
        </View>

        {/* <View UNSAFE_style={{ lineHeight: '18px' }}>{item.formValueName}</View>
        <View UNSAFE_style={{ lineHeight: '18px' }}>From: {item.formValueOld}</View>
        <View UNSAFE_style={{ lineHeight: '18px', fontWeight: 'bold' }}>To: {item.formValueNew}</View> */}
      </Flex>
    );
  };

  function statusColor(customStateColor: string) {
    //console.log('customStateColor', customStateColor);
    if (customStateColor === '') return '';
    let finalColor = (+customStateColor).toString(16).split('').reverse();
    let padding = Array(6 - finalColor?.length).fill('0');
    let finalColorString = '#' + [...finalColor, ...padding].join('');
    //console.log('finalColorString', finalColorString);
    if (finalColorString === '#ffffff') return '#FBFCF8';
    return finalColorString;
  }

  async function addNewComment() {
    if (newComment.current === '') return;
    let resMessage = await messageService.newMessage(
      new NewMessageRequest(store.Server, store.SessionId, {
        numberOfAttachments: 0,
        priority: 2,
        type: 1,
        contextId: selectedItem?.id,
        schedulerId: 0,
        SUBJECT: {
          string: newComment.current,
        },
        RECIPIENT: {},
        COMMENT: {
          string: newComment.current,
        },
        CONTACT_RELATIONS: {},
      })
    );
    //console.log('resMessage', resMessage);
    await reloadTimeline();
  }

  const handleKeyUp = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (newComment.current === '') return;
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      await addNewComment();
      await reloadTimeline();
    }
  };

  const searchTimeLine = async () => {
    let periodTo = formValue.periodTo && moment(new Date(formValue.periodTo)).format('YYYY-MM-DD');
    let periodFrom = formValue.periodFrom && moment(new Date(formValue.periodFrom)).format('YYYY-MM-DD');

    let entrymarketingType = Array.from(formValue.marketingType).join(',') + ',' + Array.from(formValue.entryType).join(',');
    entrymarketingType = entrymarketingType.replace(/(^,|,$)/g, '');
    let contextType = Array.from(formValue.contextType).join(', ');

    let reqParams: any = {
      FILTER: {
        COL: [
          {
            name: 'created',
            type: 'greaterOrEquals',
            value: periodFrom,
          },
          {
            name: 'created',
            type: 'lessOrEquals',
            value: periodTo,
          },
          {
            name: 'createdBy',
            type: 'in',
            value: formValue.userId,
          },
          {
            name: 'objectName',
            type: 'in',
            value: entrymarketingType,
          },
          {
            name: 'contextType',
            type: 'in',
            value: contextType,
          },
        ],
      },
    };

    if (!periodFrom || periodFrom === '') {
      delete reqParams.FILTER.COL[0];
    }

    if (!periodTo || periodTo === '') {
      delete reqParams.FILTER.COL[1];
    }

    if (formValue.userId === '') {
      delete reqParams.FILTER.COL[2];
    }

    if (entrymarketingType === '') {
      delete reqParams.FILTER.COL[3];
    }

    if (contextType === '') {
      delete reqParams.FILTER.COL[4];
    }
    setRequestParams(reqParams);
    reloadTimeline(reqParams);
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <div ref={componentRef}>
        <View width={'100%'} height={'500px'} UNSAFE_className={styles.parent_container}>
          <Flex
            direction={'row'}
            position={'sticky'}
            width={'100%'}
            top={'-4px'}
            left={'0px'}
            height={'32px'}
            alignItems={'start'}
            justifyContent={'start'}
            zIndex={999}
            UNSAFE_className={styles.button_parent}
          >
            <View UNSAFE_className={styles.label}>
              <b>{t('new_message', { ns: 'layout_components' })}</b>
            </View>
            <Flex direction={'row'} position={'absolute'} top={'3px'} right={'0px'} alignItems={'start'} justifyContent={'end'} gap={'24px'}>
              <button
                className={`${styles.icon_stack} bi bi-arrow-clockwise fs-6`}
                style={{ top: '-2px' }}
                onClick={async () => {
                  await reloadTimeline();
                }}
              ></button>

              <DialogTrigger type="popover">
                <ActionButton UNSAFE_className={`${styles.icon_stack} bi bi-chat fs-6`}></ActionButton>
                {close => (
                  <Dialog>
                    <Content>
                      <div onKeyUp={handleKeyUp}>
                        <Form>
                          <TextArea
                            isRequired
                            autoFocus
                            label={t('new_comment', { ns: 'layout_components' })}
                            onChange={(e: any) => {
                              newComment.current = e;
                            }}
                          />
                        </Form>
                      </div>
                    </Content>
                    <Footer>
                      <Button
                        width={'100%'}
                        variant="accent"
                        onPress={async () => {
                          await addNewComment();
                        }}
                        UNSAFE_style={{ borderRadius: '8px' }}
                      >
                        {t('add_comment', { ns: 'layout_components' })}
                      </Button>
                    </Footer>
                  </Dialog>
                )}
              </DialogTrigger>

              <DialogTrigger type="popover">
                <ActionButton UNSAFE_className={`${styles.icon_stack} bi bi-funnel fs-6`}></ActionButton>
                {close => (
                  <Dialog size="M">
                    <Content>
                      <Form>
                        <Flex direction={isMobile ? 'column' : 'row'} gap="size-100">
                          <Provider locale="en-US">
                            <DatePicker
                              autoFocus
                              label={t('period_from', { ns: 'layout_components' })}
                              width={'100%'}
                              value={formValue.periodFrom}
                              onChange={(value: any) => {
                                setFormValue((prevValue: any) => ({ ...prevValue, periodFrom: value }));
                              }}
                              UNSAFE_style={{ backgroundColor: 'white' }}
                            />
                          </Provider>
                          <Provider locale="en-US">
                            <DatePicker
                              label={t('period_to', { ns: 'layout_components' })}
                              width={'100%'}
                              value={formValue.periodTo}
                              onChange={(value: any) => {
                                setFormValue((prevValue: any) => ({ ...prevValue, periodTo: value }));
                              }}
                              UNSAFE_style={{ backgroundColor: 'white' }}
                            />
                          </Provider>
                        </Flex>
                        <ComboBox
                          width={'100%'}
                          label={t('user', { ns: 'layout_components' })}
                          selectedKey={formValue.userId}
                          defaultItems={userData}
                          onSelectionChange={value => {
                            setFormValue((prevValue: any) => ({ ...prevValue, userId: value }));
                          }}
                        >
                          {(item: any) => <Item key={'user_id_' + item.id}>{item.name}</Item>}
                        </ComboBox>
                        <MenuTrigger>
                          <ActionButton>{t('type_of_entry', { ns: 'layout_components' })} </ActionButton>
                          <Menu
                            width={'100%'}
                            items={typeEntryOptions}
                            selectedKeys={formValue.entryType}
                            onSelectionChange={value => {
                              setFormValue((prevValue: any) => ({ ...prevValue, entryType: value }));
                            }}
                            selectionMode="multiple"
                          >
                            {(item: any) => <Item key={'entry_type_' + item.id}>{item.name}</Item>}
                          </Menu>
                        </MenuTrigger>
                        <MenuTrigger>
                          <ActionButton>{t('marketing_trigger', { ns: 'layout_components' })} </ActionButton>
                          <Menu
                            items={typeMarketingOptions}
                            selectedKeys={formValue.marketingType}
                            onSelectionChange={value => {
                              setFormValue((prevValue: any) => ({ ...prevValue, marketingType: value }));
                            }}
                            selectionMode="multiple"
                          >
                            {(item: any) => <Item key={'marketingType_id_' + item.id}>{item.name}</Item>}
                          </Menu>
                        </MenuTrigger>
                        <MenuTrigger>
                          <ActionButton>{t('context_type', { ns: 'layout_components' })}</ActionButton>
                          <Menu
                            items={contextType}
                            selectedKeys={formValue.contextType}
                            onSelectionChange={value => {
                              setFormValue((prevValue: any) => ({ ...prevValue, contextType: value }));
                            }}
                            selectionMode="multiple"
                          >
                            {(item: any) => <Item key={'contextType_id_' + item.id}>{item.name}</Item>}
                          </Menu>
                        </MenuTrigger>
                      </Form>
                    </Content>
                    <Footer UNSAFE_style={{ paddingTop: '20px' }}>
                      <Flex direction={isMobile ? 'column' : 'row'} width={'100%'} gap="size-100">
                        <Button
                          width={isMobile ? '100%' : '30%'}
                          variant="primary"
                          onPress={() => {
                            setRequestParams([]);
                            setFormValue({ userId: '', periodFrom: null, periodTo: null, entryType: new Set(), marketingType: new Set(), contextType: new Set() });
                          }}
                          UNSAFE_style={{ borderRadius: '8px', color: '#1976d2', borderColor: '#1976d2' }}
                        >
                          {t('clear', { ns: 'layout_components' })}
                        </Button>
                        <Button
                          width={isMobile ? '100%' : '70%'}
                          variant="accent"
                          onPress={async () => {
                            await searchTimeLine();
                            close();
                          }}
                          UNSAFE_style={{ borderRadius: '8px' }}
                        >
                          {t('apply', { ns: 'layout_components' })}
                        </Button>
                      </Flex>
                    </Footer>
                  </Dialog>
                )}
              </DialogTrigger>
            </Flex>
          </Flex>

          <View width="100%" key={selectedItem?.id}>
            {arrHeaders.map((header: any, headerIndex: number) => (
              <Flex key={`header_${headerIndex}_${header.id}`} direction="column" width="100%" marginBottom="10px">
                <View UNSAFE_className={styles.date_header_parent} width="100%" height="32px">
                  <b key={`date_${headerIndex}_${header.id}`} className={styles.date_header_text}>
                    {formatDate(header.title)}
                  </b>
                </View>
                <View key={`group_${headerIndex}_${header.id}`} UNSAFE_className={styles.timeline_item} width="100%" marginTop="10px">
                  {groupedData[header.title]?.map((item: any, itemIndex: any) => (
                    <Flex key={header.id} direction="column" width="100%" marginBottom="10px">
                      <View position="relative" height={visibilityStatesID[item.id] ? '114px' : '64px'} width="94%" margin="0 auto" marginStart="25px">
                        <div ref={item.id == scrollId.current ? secondLastItemRef : null}>
                          <View position="absolute" top="20px" left="40px" UNSAFE_className={styles.status_text}>
                            <span title={item.title} id={`title_tooltip_${item.id}`}>
                              {isMobile || componentWidth < 800 ? truncateText(item.title, 30) : truncateText(item.title, 100)}
                            </span>
                            {isMobile && <Tooltip anchorSelect={`#title_tooltip_${item.id}`} content={item.title} />}
                          </View>

                          {(item.objectName === 'FORMVALUE' || item.objectName === 'EMAIL') && (
                            <>
                              <button
                                id={`icon_detail_${item.id}`}
                                title={item.emailText}
                                className={`${styles.icon_detail} ${iconClassesID[item.id]}`}
                                onClick={() => toggleVisibility(item.id)}
                                aria-expanded={visibilityStatesID[item.id]}
                                aria-controls={`details_${itemIndex}`}
                                style={{ position: 'absolute', top: '20px', right: '40px' }}
                              ></button>
                              {isMobile && <Tooltip anchorSelect={`#icon_detail_${item.id}`} content={item.emailText} />}
                            </>
                          )}
                          {visibilityStatesID[item.id] && (
                            <Flex
                              direction="row"
                              position="relative"
                              justifyContent="end"
                              alignContent="end"
                              alignItems="start"
                              right="10px"
                              width="80%"
                              top="40px"
                              UNSAFE_style={{ float: 'right', overflowY: 'auto', scrollBehavior: 'smooth', marginBottom: '10px' }}
                              height="85px"
                            >
                              {(() => {
                                if (item.objectName === 'EMAIL') {
                                  return (
                                    <div
                                      key={'divEmail_' + item.id}
                                      style={{ width: '100%', padding: '5px', scrollBehavior: 'smooth', backgroundColor: '#E5E5E5' }}
                                      dangerouslySetInnerHTML={{ __html: item.emailText.replace(/\n/g, '<br/>') }}
                                    />
                                  );
                                } else if (item.objectName === 'FORMVALUE') {
                                  return createFormValueDetail(item);
                                } else {
                                  return '';
                                }
                              })()}
                            </Flex>
                          )}

                          <Flex key={'spanPath_' + item.id} direction="column" position="absolute" top="0px" right="0px" alignItems="center" justifyContent="end" gap="2px">
                            <View UNSAFE_className={styles.label}>
                              {item.contextPathArray.map(
                                (context: any, index: number) =>
                                  index !== 0 && (
                                    <a id={`spanPath_${context.id}_${index}`} title={item.contextPath} target="_blank" onClick={() => invokeFinderApp(context.id)}>
                                      {isMobile ? truncateText(context.name, 10) : truncateText(context.name, 15)} {index !== item.contextPathArray.length - 1 && ' > '}
                                    </a>
                                  )
                              )}
                            </View>
                          </Flex>

                          <View position="absolute" top="0" left="-25px" UNSAFE_className={styles.div_icon}>
                            <i
                              id={`icon_` + item.id}
                              title={item.objectName}
                              className="bi bi-circle-fill"
                              style={{
                                color: item.status !== '' ? statusColor(item.statusColor) : '',
                              }}
                            ></i>
                            {isMobile && <Tooltip anchorSelect={`#icon_${item.id}`} content={item.objectName} />}
                          </View>
                          <View position="absolute" top="0px" left="0px">
                            {item.date}
                          </View>
                          <Flex direction="row" position="absolute" top="46px" left="40px" alignItems="start" justifyContent="start" gap="4px" UNSAFE_style={{ fontSize: '14px' }}>
                            <View
                              position="absolute"
                              left="-59px"
                              top="-28px"
                              width="2px"
                              height={visibilityStatesID[item.id] ? 'calc(100% + 88px)' : 'calc(100% + 38px)'}
                              UNSAFE_className={styles.side_line}
                            ></View>
                            {item.status !== '' ? (
                              <>
                                <View UNSAFE_style={{ lineHeight: '18px' }}>{t('new_status', { ns: 'layout_components' })}: </View>
                                <View UNSAFE_style={{ lineHeight: '18px', color: '#455a64' }}>{item.status}</View>
                              </>
                            ) : (
                              <>
                                <View UNSAFE_style={{ lineHeight: '18px' }}>&nbsp; </View>
                                <View UNSAFE_style={{ lineHeight: '18px', color: '#455a64' }}>&nbsp;</View>
                              </>
                            )}
                          </Flex>
                          <View position="absolute" top="23px" left="0px" overflow="hidden" UNSAFE_className={styles.icon_avatar}>
                            <img
                              title={item.createdByName}
                              width="28px"
                              height="28px"
                              alt={item.profileImage}
                              onError={e => {
                                e.currentTarget.src = '../../../../../../assets/images/avatar--desktop--light@2x.png';
                              }}
                              src={item.profileImage}
                            />
                          </View>
                        </div>
                      </View>
                    </Flex>
                  ))}
                </View>
              </Flex>
            ))}
          </View>
          <Flex
            direction={'row'}
            // position={'fixed'}
            bottom={'5px'}
            left={'calc(70% - 75px)'}
            alignItems={'center'}
            justifyContent={'center'}
            UNSAFE_className={styles.btn_see_more}
            // UNSAFE_style={{ display: 'none' }}
          >
            <Link isQuiet onPress={loadMore} UNSAFE_className={`${styles.btn_see_more} ${styles.label}`}>
              {t('see_more', { ns: 'layout_components' })}
            </Link>
          </Flex>
        </View>
      </div>
    );
  }
}

export const TimeLineComponent = LayoutComponent(TimeLine);

import { Flex, View } from '@adobe/react-spectrum';
import styles from './Contact.module.css';
import * as React from 'react';
import { useContacts } from './Contact.hooks';

export interface IContactProps {
}

export function Contact (props: IContactProps) {
    const { contacts, loading, error } = useContacts();

    if(error){
        return <div className={styles.errorMessage}>{error}</div>
    }

    return (
        <Flex direction="column" gap="size-100" UNSAFE_className={styles.container}>
            {loading && <div className={styles.loading}>Loading...</div>}
            <View flex>
                Contacts Goes here...
            </View>
        </Flex>
    );
}

import React, { useCallback, useEffect, useState } from 'react';
import { Floppy, Printer, Send } from 'react-bootstrap-icons';
import { Flex, ProgressCircle, Radio, RadioGroup, View } from '@adobe/react-spectrum';
import { useDependency } from '../../../../../../contexts/DependencyProvider';
import { ListFPATypeDocumentParsRequest } from '../../../../../../services/soap/project/requests/ListFPATypeDocumentParsRequest';
import { ListLanguagesRequest } from '../../../../../../services/soap/translate/reqeusts/ListLanguagesRequest';
import { Col, FilterCol, Order } from '../../../../../../services/soap/project/requests/ListFPARequest';
import { useReefConfig } from '../../../../../../hooks/UseReefConfig';
//import './print_diallog.css';
import styles from './print_dialog.module.css';

interface PrintDialogProps {
  open?: boolean;
  onClose?: () => void;
  onSend?: () => void;
  onPrint?: () => void;
  onSave?: () => void;
  selectedItem?: any;
}

const PrintDialog: React.FC<PrintDialogProps> = ({ open, onClose, onSend, onPrint, onSave, selectedItem }) => {
  const { store, projectService, translateService } = useDependency();
  const { baseUrl, loginInfo } = useReefConfig();
  const [docTemplates, setDocTemplates] = useState<any>([]);
  const [langList, setLangList] = useState<any>([]);
  const [langName, setLangName] = useState<string>('');
  const [docType, setDocType] = useState<string>('pdf');
  const [selectDocumentID, setSelectDocumentID] = useState<any>('');
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await loadDocumentParams();
      await loadLanguage();
      setShowLoader(false);
    })();
  }, []);

  const loadDocumentParams = useCallback(async () => {
    let resDocPars = await projectService.listFPATypeDocumentPars(
      new ListFPATypeDocumentParsRequest(store.Server, store.SessionId, {
        limit: 50,
        offset: 0,
        FILTER: [new FilterCol('type', 'equals', selectedItem?.item_type_id)],
        ORDER: new Order([new Col('priority'), new Col('description')]),
      })
    );

    if (resDocPars.result === 'OK' && resDocPars?.FPA_TYPE_DOCUMENT_PAR?.length > 0) {
      let docList: any = resDocPars.FPA_TYPE_DOCUMENT_PAR;
      //console.log('docList', docList);
      docList.unshift({
        id: 0,
        description: 'System default',
      });
      const defaultDocument = resDocPars.FPA_TYPE_DOCUMENT_PAR.find((doc: any) => doc.isDefault === 'true');
      setDocTemplates(resDocPars.FPA_TYPE_DOCUMENT_PAR);
      setSelectDocumentID(String(defaultDocument?.document));
    }
  }, [selectedItem]);

  const loadLanguage = useCallback(async () => {
    let langResp = await translateService.listLanguages(new ListLanguagesRequest(store.Server, store.SessionId));
    if (langResp.result === 'OK' && langResp?.LANGUAGE?.length > 0) {
      const filterList: any = langResp.LANGUAGE.filter((lang: any) => lang.useForTranslate === '1');
      filterList.unshift({
        languageShort: '',
        languageLong: 'Default',
      });
      //console.log('langList', filterList);
      setLangList(filterList);
    }
  }, [selectedItem]);

  const onPrintClick = () => {
    //  console.log('onPrintClick');
    let windowReference: any = window.open('', '_blank', 'scrollbars=yes,status=yes,menubar=no,toolbar=no');
    //console.log('selectDocumentID', selectDocumentID);
    let strURL = `${baseUrl}/crab/printservice/file?server=${loginInfo.instanceName}&session=${store.SessionId}&print_type=${selectedItem.type}&object_id=${
      selectedItem.id
    }&output_format=${docType}&lang=${langName}&template_id=${selectDocumentID == 'undefined' ? '' : selectDocumentID}`;

    if (docType === 'http') {
      strURL = `${baseUrl}/crab/printservice/tedy?server=${loginInfo.instanceName}&session=${store.SessionId}&print_type=${selectedItem.type}&object_id=${selectedItem.id}`;
    }

    windowReference?.location.replace(strURL);
  };
  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <View width={'100%'} position={'relative'} height={'auto'} UNSAFE_className={styles.print_dialog_parent}>
        {docTemplates.length > 0 && (
          <>
            <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
              Document template
            </Flex>
            <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
              <RadioGroup label="" defaultValue={selectDocumentID} onChange={(value: any) => setSelectDocumentID(value)} orientation="vertical">
                {docTemplates.map((docTemplate: any) => (
                  <Radio key={docTemplate.id} value={String(docTemplate.document)}>
                    {docTemplate.description}
                  </Radio>
                ))}
              </RadioGroup>
            </Flex>
          </>
        )}

        <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
          Document type
        </Flex>
        <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
          <RadioGroup label="" orientation="horizontal" defaultValue={'pdf'} onChange={value => setDocType(value)}>
            <Radio value="pdf">PDF</Radio>
            <Radio value="docx">Word doc</Radio>
            <Radio value="xlsx">Excel doc</Radio>
            <Radio value="http">Http</Radio>
          </RadioGroup>
        </Flex>

        <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
          Document language
        </Flex>
        <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
          <RadioGroup label="" orientation="horizontal" onChange={value => setLangName(value)} defaultValue={''}>
            {langList.map((lang: any) => (
              <Radio key={lang.languageShort} value={lang.languageShort}>
                {lang.languageLong}
              </Radio>
            ))}
          </RadioGroup>
        </Flex>

        <View position={'relative'} bottom={'0px'} left={'0px'} width={'100%'} height={'55px'} UNSAFE_className={styles.footer}>
          <Flex direction={'row'} position={'absolute'} bottom={'0px'} left={'calc(50% - 150px)'} alignItems={'start'} justifyContent={'start'} gap={'10px'}>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.footer_btn}>
              <Send onClick={onSend} size={32} />
              <View UNSAFE_className={styles.btn_label}>Send</View>
            </Flex>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.footer_btn}>
              <div style={{ display: 'contents' }} onClick={onPrintClick}>
                <Printer size={16} />
                <View UNSAFE_className={styles.btn_label}>Print</View>
              </div>
            </Flex>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.footer_btn}>
              <Floppy onClick={onSave} size={32} />
              <View UNSAFE_className={styles.btn_label}>Save</View>
            </Flex>
          </Flex>
        </View>
      </View>
    );
  }
};

export default PrintDialog;

import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Filter, toFilterXml } from '../../dataObjects/Filter';
import { Order, toOrderXml } from '../../dataObjects/Order';

export interface IListProjectAdvancedRequest extends IBaseRequest {
    limit?:     number;
    offset?:    number;
    orderBy?:   string;
    countOnly?: boolean;
    formItems?: FormItems;
    FILTER?:    Filter;
    ORDER?:     Order;
    FIELDS?:    Fields;
}

export interface Fields {
    field: string[];
}

function toFieldsXml(fields: Fields): string {
    return fields.field.map((field: string) => {
        return `<field>${field}</field>`;
    }).join('');
}

export interface FormItems {
    formItem: string[];
}

function toFormItemsXml(formItems: FormItems): string {
    return formItems.formItem.map((formItem: string) => {
        return `<formItem>${formItem}</formItem>`;
    }).join('');
}

export class ListProjectAdvancedRequest extends BaseRequest implements IListProjectAdvancedRequest {

    public limit?:     number;
    public offset?:    number;
    public orderBy?:   string;
    public countOnly?: boolean;
    public formItems?: FormItems;
    public FILTER?:    Filter;
    public ORDER?:     Order;
    public FIELDS?:    Fields;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.limit = otherOptions.limit;
        this.offset = otherOptions.offset;
        this.orderBy = otherOptions.orderBy;
        this.countOnly = otherOptions.countOnly;
        this.formItems = otherOptions.formItems;
        this.FILTER = otherOptions.FILTER;
        this.ORDER = otherOptions.ORDER;
        this.FIELDS = otherOptions.FIELDS;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if(this.limit) parameters += `<limit>${this.limit}</limit>`;
        if(this.offset) parameters += `<offset>${this.offset}</offset>`;
        if(this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>`;
        if(this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>`;
        if(this.formItems) parameters += `<formItems>${toFormItemsXml(this.formItems)}</formItems>`;
        if(this.FILTER) parameters += toFilterXml(this.FILTER);
        if(this.ORDER) parameters += toOrderXml(this.ORDER);
        if(this.FIELDS) parameters += `<FIELDS>${toFieldsXml(this.FIELDS)}</FIELDS>`;
        
        return super.wrapperXml('ListProjectAdvanced', parameters);
    }
}

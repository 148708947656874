import { TimeSheetItem } from '../../dataObjects/TimeSheetItem';

export function generateExpenseItem(
  userId: string,
  userName: string,
  typeOfWorkId: string,
  typeOfWorkName: string,
  description: string,
  qty: number,
  activityId: string,
  activityName: string,
  projectId: string,
  projectName: string,
  folderId: string,
  folderName: string,
  entryDate: Date,
  unitPrice: number,
  totalPrice: number,
  currency: string,
  u_item: TimeSheetItem = {}
): TimeSheetItem {
  var dateTo = new Date(entryDate.getTime() + 1000 * 60 * 1);
  var item: TimeSheetItem = {
    ...u_item,
    userId: userId,
    userName: userName,
    typeOfWorkId: typeOfWorkId,
    typeOfWorkName: typeOfWorkName,
    description: description,
    qty: '' + qty,
    activityId: activityId,
    activityName: activityName,
    projectId: projectId,
    projectName: projectName,
    folderId: folderId,
    folderName: folderName,
    entryDate: entryDate,
    dateTo: dateTo,

    minutes: '60',
    dimension3: '0',
    dimension2: '0',
    dimension1: '0',
    resultCost: '0',
    hourlyCost: '0',
    switchTotal: '0',
    vat: '0',
    usedQuantity: '0',
    resultPrice: '' + totalPrice,
    isExpense: '1',
    taskId: '0',
    currency: currency,
    currencyCost: 'CZK',
    rate: '' + unitPrice,
    status: '1',
    comments: '',
    masterFolderId: '0',
    masterFolderName: '',
    masterProjectId: '0',
    masterProjectName: '',
  };
  return item;
}
export function generateTimeSheetItem(
  userId: string,
  userName: string,
  typeOfWorkId: string,
  typeOfWorkName: string,
  minutes: number,
  activityId: string,
  activityName: string,
  projectId: string,
  projectName: string,
  folderId: string,
  folderName: string,
  chargeable: boolean,
  entryDate: Date,
  unitPrice: number,
  totalPrice: number,
  currency: string,
  u_item: TimeSheetItem = {}
): TimeSheetItem {
  var dateTo = new Date(entryDate.getTime() + 1000 * 60 * 24.5);
  var item: TimeSheetItem = {
    ...u_item,
    userId: userId,
    userName: userName,
    typeOfWorkId: typeOfWorkId,
    typeOfWorkName: typeOfWorkName,
    minutes: '' + minutes,
    activityId: activityId,
    activityName: activityName,
    projectId: projectId,
    projectName: projectName,
    folderId: folderId,
    folderName: folderName,
    chargeable: chargeable ? '1' : '0',
    entryDate: entryDate,
    dateTo: dateTo,

    dimension3: '0',
    dimension2: '0',
    dimension1: '0',
    resultCost: '0',
    hourlyCost: '0',
    switchTotal: '0',
    vat: '0',
    qty: '0',
    usedQuantity: '0',
    resultPrice: '' + totalPrice,
    isExpense: '0',
    taskId: '0',
    currency: currency,
    currencyCost: 'CZK',
    rate: '' + unitPrice,
    status: '1',
    comments: '',
    description: '',
    masterFolderId: '0',
    masterFolderName: '',
    masterProjectId: '0',
    masterProjectName: '',
  };
  return item;
}

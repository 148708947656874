// DataComponent.tsx
import { useEffect, useRef, useState } from 'react';
import { ComboBox, Item, Key, Text } from '@adobe/react-spectrum';
import { DataComponentProps } from './DataComponentProps';
import { Contact } from '../../../services/soap/main/responses/GetContactListResponse';
import { useDependency } from '../../../contexts/DependencyProvider';
import { GetContactListRequest } from '../../../services/soap/main/requests/GetContactListRequest';
import ConstantUtils from '../../../utils/ConstantUtils';
import { useTranslation } from 'react-i18next';

interface SurnameDropDownProps extends DataComponentProps {
  apiOptions: any;
  firstName?: string;
}
const SurnameDropDown: React.FC<SurnameDropDownProps> = ({ compKey, label, width, isRequired, onValueChange, apiOptions, value:dvalue, firstName }) => {
  const { t } = useTranslation();
  const _t = (key: string) => t(`newContextDialog.dataComponents.${key}`, { ns: 'finder' });
  const message = _t('is_required').split('[content]').join(label);
  const [errorMessage, setErrorMessage] = useState(isRequired ? message : '');
  const [value, setValue] = useState<Key | null>('');
  const [input, setInput] = useState('');
  const [contacts, setContacts] = useState<Contact[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<string>('');
  const { mainService, store } = useDependency();

  inputRef.current = input;

  useEffect(() => {
    if(dvalue){
      setInput(dvalue);
    }
  }, [dvalue]);

  const onSelectionChange = (value: Key | null) => {
    if(onValueChange)
        onValueChange(value ? value.toString() : '', input);
    setValue(value);
    if(isRequired && !value && input === '') 
      setErrorMessage(message);
    else 
      setErrorMessage('');
  };

  const timer_cb = async () => {
    if(input.length >= 3) {
      var filter:string = `${input}*`;
      if(firstName) {
        filter = `${filter} ${firstName}`;
      }
      const response = await mainService.getContactList(new GetContactListRequest(store.Server, store.SessionId,{ ...apiOptions, contains: filter }));
      if(response.EXCEPTION) {
        console.error(response.EXCEPTION.message);
      } else if(response.CONTACT) {
        setContacts(response.CONTACT);
      } else {
        setContacts([]);
      }
    }else {
      setContacts([]);
    }
  }

  const onInputChange = async (value: string) => {
    setInput(value);

    if(isRequired && input === '') {
      setErrorMessage(message);
    } else {
      setErrorMessage('');
    }

    if(timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(timer_cb, ConstantUtils.CONTEXT_NEW_SEARCH_DELAY);
  }

  return (
    <ComboBox key={compKey} 
        label={label} 
        width={width} 
        isRequired={isRequired} 
        selectedKey={value}
        onSelectionChange={onSelectionChange} 
        onInputChange={onInputChange}
        validationState={errorMessage ? 'invalid' : undefined}
        errorMessage={errorMessage}
        inputValue={input}
        defaultItems={contacts}
        allowsCustomValue
        >
      {(item: Contact) => (<Item key={item.id} textValue={item.name1}>
        <Text>{item.name1}</Text>
        <Text slot="description">{item.name1} {item.name2} {item.name3}</Text>
      </Item>)}
    </ComboBox>
  );
};

export default SurnameDropDown;

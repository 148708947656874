import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListContactSettingsRequest extends IBaseRequest {
    limit?:      string;
    offset?:     string;
    orderBy?:    string;
    filterName?: string;
    treeNode?:   string;
    list?:       string;
}

export class ListContactSettingsRequest extends BaseRequest implements IListContactSettingsRequest {
    public limit?:      string;
    public offset?:     string;
    public orderBy?:    string;
    public filterName?: string;
    public treeNode?:   string;
    public list?:       string;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.limit = otherOptions.limit;
    this.offset = otherOptions.offset;
    this.orderBy = otherOptions.orderBy;
    this.filterName = otherOptions.filterName;
    this.treeNode = otherOptions.treeNode;
    this.list = otherOptions.list;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if(this.limit) parameters += '<limit>' + this.limit + '</limit>';
    if(this.offset) parameters += '<offset>' + this.offset + '</offset>';
    if(this.orderBy) parameters += '<orderBy>' + this.orderBy + '</orderBy>';
    if(this.filterName) parameters += '<filterName>' + this.filterName + '</filterName>';
    if(this.treeNode) parameters += '<treeNode>' + this.treeNode + '</treeNode>';
    if(this.list) parameters += '<list>' + this.list + '</list>';
    
    return super.wrapperXml('ListContactSettings', parameters);
  }
}

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { LayoutComponent } from '../LayoutComponent';
import { statusColor } from '../../../../utils/helperUtil';
import moment from 'moment';
import { useAsyncList } from 'react-stately';
import { Flex, View, ProgressCircle, TableView, TableBody, TableHeader, Row, Cell, Column, Content, Link, DialogContainer } from '@adobe/react-spectrum';
import { ArrowRight, CircleFill } from 'react-bootstrap-icons';
import { invokeFinderApp } from '../../../../utils/NavigationUtils';
import { ListActivityAdvancedRequest } from '../../../../services/soap/project/requests/ListActivityAdvancedRequest';
import { ListStatusRequest, ListStatusType } from '../../../../services/soap/project/requests/ListStatusRequest';
import { ListFormSettingsRequest } from '../../../../services/soap/features/requests/ListFormSettingsRequest';
import { GetFormSettingsRequest } from '../../../../services/soap/features/requests/GetFormSettingsRequest';
import { useTranslation } from 'react-i18next';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import styles from './ActivityLineComponent.module.css';
import { DataProvider } from '../../data-components/Contexts/DataContext';
import { ContextNewDialog } from '../../Context/ContextNewDialog';

interface ActivityLineProps {
  selectedItem?: FPAData;
  componentTitle?: string;
  showActivities?: string;
  fpaTypeCategory?: string;
}

interface Character {
  id: string;
  name: string;
}

const ActivityLine: React.FC<ActivityLineProps> = ({ selectedItem, componentTitle, showActivities, fpaTypeCategory }) => {
  const { t } = useTranslation();
  const { store, projectService, featureService } = useDependency();
  const [showLoader, setShowLoader] = useState(true);
  const [rowsData, setRowsData] = useState<any>([]);
  const [columnsData, setColumnsData] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [activityStatusList, setActivityStatusList] = useState<any>([]);
  const [showAdd, setShowAdd] = useState(false);
  const serviceActivityValue = useRef<string>('');
  const queryParams = useRef<any>({ FILTER: { COL: [] } });
  const totalActivityListRec = useRef<number>(0);
  const offSetLimit = useRef<number>(0);
  const PAGE_SIZE = 50;

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      getFormSettings('');
      queryParams.current = { FILTER: { COL: [] } };
      serviceActivityValue.current = 'com.atollon.technology.equipmentchecks';
      Promise.all([getActivityStatusArray()]);
    })();
  }, []);

  const getFormSettings = useCallback(async (settingId: string) => {
    //console.log('settingId', settingId);
    if (settingId === '') {
      const resFormSettings = await featureService.listFormSettings(new ListFormSettingsRequest(store.Server, store.SessionId));
      let filteredData = resFormSettings.SETTING.filter((item: any) => item.formId === 'ListActivityAdvanced' && item.isDefault == '1');
      if (filteredData.length > 0) {
        settingId = filteredData[0].id.toString();
      }
    }
    const response = await featureService.getFormSettings(new GetFormSettingsRequest(store.Server, store.SessionId, settingId));
    if (response?.result === 'OK' && response?.count > 0) {
      const filteredData = response.SETTING[0]?.ITEM?.filter(item => String(item.visibility) === '1');
      const result = filteredData?.map(item => {
        const key = item.itemname.includes('|') ? item.itemname.split('|')[1] : item.itemname;
        const name = item.caption && item.caption.trim() !== '' ? item.caption : item.name;
        const width: any = null;
        return { key, name, width };
      });
      result.push({ key: 'button', name: '', width: 50 });
      //console.log('result', result);
      setColumnsData(result);
    }
  }, []);

  const getActivityStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listActivityStatus2(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Activity, serviceActivityValue.current));

    if (res.result === 'OK' && res.STATUS.length > 0) {
      res?.STATUS.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color } = status;
        let colorString = statusColor(color);
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color: colorString });
      });

      statusArray.unshift({ id: 'All states', name: 'All states', color: 'black', types: [] });
      setActivityStatusList(statusArray);
    }
  }, []);

  const getAcitivityListCount = async () => {
    // console.log('selectedItem', selectedItem);
    // console.log('selectedItem.type', selectedItem?.type);
    // console.log('showActivities', showActivities);
    if (selectedItem) {
      switch (selectedItem.type) {
        case FPADataTypes.ACTIVITY:
        case FPADataTypes.FOLDER:
          if (showActivities === 'slaveActivities') {
            queryParams.current.FILTER.COL.push({
              name: 'masterActivity',
              type: 'equals',
              value: selectedItem?.id,
            });
          } else {
            queryParams.current.FILTER.COL.push({
              name: 'project',
              type: 'equals',
              value: selectedItem?.parent_id,
            });
          }
          break;
        case FPADataTypes.PROJECT:
          queryParams.current.FILTER.COL.push({
            name: 'project',
            type: 'equals',
            value: selectedItem?.id,
          });

          break;
      }
    }

    // Remove duplicates in the FILTER.COL array
    const uniqueCOL: any = [];
    const typeName = new Set();

    queryParams.current.FILTER.COL.forEach((item: any) => {
      if (!typeName.has(item.name)) {
        typeName.add(item.name);
        uniqueCOL.push(item);
      }
    });

    // Update the COL array with unique values
    queryParams.current.FILTER.COL = uniqueCOL;

    let activityRes = await projectService.listActivityAdvanced(
      new ListActivityAdvancedRequest(store.Server, store.SessionId, {
        countOnly: '1',
        FILTER: queryParams.current.FILTER,
      })
    );
    if (activityRes?.result == 'OK') {
      totalActivityListRec.current = activityRes.count != 0 ? activityRes.count : 0;
      return activityRes.count != 0 ? activityRes.count : 0;
    }
  };

  const loadServiceActivities = async () => {
    try {
      let activityRes = await projectService.listActivityAdvanced(
        new ListActivityAdvancedRequest(store.Server, store.SessionId, {
          limit: PAGE_SIZE,
          offset: offSetLimit.current ?? 0,
          FILTER: queryParams.current.FILTER,
          ORDER: {
            COL: [
              {
                name: 'created',
                desc: 'true',
              },
            ],
          },
          FIELDS: {
            field: columnsData.map((column: any) => column.key),
          },
        })
      );

      if (activityRes?.result == 'OK' && activityRes?.count > 0) {
        // add fullPath to each activity
        let listActivity: any = activityRes?.ACTIVITIES?.ACTIVITY;
        //console.log('listActivity', listActivity);
        setRowsData(listActivity);
        if (listActivity.length > 0) {
          setSelectedKeys(new Set([listActivity[0].id]));
        }
        return listActivity;
      } else {
        setRowsData([]);
        //ToastQueue.info(t('no_results', { ns: 'layout_components' }), { timeout: 50 });
        return [];
      }
    } finally {
      setShowLoader(false);
    }
  };

  let listActivityList = useAsyncList<Character>({
    async load({ cursor }) {
      //console.log('cursor', cursor);

      if (cursor && Number(cursor) * PAGE_SIZE >= totalActivityListRec.current) {
        setShowLoader(false);
        return { items: rowsData, cursor: undefined };
      }
      let totalRecords = await getAcitivityListCount();
      totalRecords = totalRecords ? Number(totalRecords) : 0;
      //console.log('totalRecords', totalRecords);
      let currentPage = cursor ? parseInt(cursor) : 0;
      //console.log('currentPage', currentPage);
      let offSet = PAGE_SIZE * currentPage;
      //console.log('offset', offSet);
      offSetLimit.current = offSet;
      let itemList = await loadServiceActivities();
      //console.log('itemList', itemList);
      return {
        items: itemList,
        cursor: String(offSet >= totalRecords ? undefined : currentPage + 1),
      };
    },
  });

  const handleTableRowClick = (rowId: any): void => {
    invokeFinderApp(rowId);
  };

  const getStatusColor = (statusName: any) => {
    const statusObj = activityStatusList.find((obj: any) => obj.name === statusName);
    return (
      <>
        <CircleFill style={{ paddingRight: '10px' }} size={'18px'} color={statusObj?.color} /> {statusName}
      </>
    );
  };

  const handleAddNew = () => {
    console.log('selected item', selectedItem);
    setShowAdd(true);
  };

  const handleAddNewClose = (reload_flag: boolean = false) => {
    if (reload_flag) listActivityList.reload();
    setShowAdd(false);
  };
  const handleAddNewCloseDismiss = () => {
    setShowAdd(false);
    listActivityList.reload();
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <>
        <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
          <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
            <Content position={'relative'} UNSAFE_className={styles.heading_text}>
              {componentTitle}
            </Content>
            <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
              <Content>
                <Link isQuiet onPress={handleAddNew}>
                  <i className="bi bi-plus fs-5">
                    <View UNSAFE_className={styles.icon_add_text}>{t('add_new', { ns: 'layout_components' })}</View>
                  </i>
                </Link>
              </Content>
            </Flex>
          </Flex>
          <Flex direction={'column'}>
            <Flex maxHeight={{ base: '1000px', L: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
              {columnsData.length === 0 ? (
                <Flex width="100%" justifyContent={'center'} marginTop={10}>
                  <ProgressCircle aria-label="Loading…" isIndeterminate />
                </Flex>
              ) : (
                <TableView
                  UNSAFE_className="tblLayoutComponent"
                  aria-label="Service Activities"
                  // onSelectionChange={handleTableRowClick}
                  selectionMode="single"
                  defaultSelectedKeys={selectedKeys}
                  selectionStyle="highlight"
                  width={{ base: '100%', L: '100%', M: '1100px' }}
                  minHeight={'100px'}
                  maxHeight={{ base: '100vh', L: '625px' }}
                  onAction={handleTableRowClick}
                >
                  <TableHeader columns={columnsData}>
                    {(column: any) => (
                      <Column showDivider key={column.key} width={column?.width}>
                        {column.name}
                      </Column>
                    )}
                  </TableHeader>
                  <TableBody items={listActivityList.items} loadingState={listActivityList.loadingState}>
                    {(item: any) => (
                      <Row>
                        {columnKey => (
                          <Cell>
                            {(() => {
                              if (moment(item[columnKey], moment.ISO_8601, true).isValid()) {
                                return moment(item[columnKey]).format('MM/DD/YYYY | hh:mm A');
                              } else if (columnKey === 'customStateName') {
                                return getStatusColor(item[columnKey]);
                              } else if (columnKey === 'button') {
                                return <ArrowRight size={20} color={'#AABBCC'} />;
                              } else {
                                return item[columnKey];
                              }
                            })()}
                          </Cell>
                        )}
                      </Row>
                    )}
                  </TableBody>
                </TableView>
              )}
            </Flex>
          </Flex>
        </Flex>
        <DataProvider>
          <DialogContainer onDismiss={handleAddNewCloseDismiss}>
            {showAdd && (
              <ContextNewDialog
                parentItem={selectedItem ?? null}
                onClose={handleAddNewClose}
                additionalMode={true}
                additionalData={{ fpaTypeCategory: serviceActivityValue.current, fpaType: 'ACTIVITY', haveMasterActivity: true }}
              />
            )}
          </DialogContainer>
        </DataProvider>
      </>
    );
  }
};

export const ActivityLineComponent = LayoutComponent(ActivityLine);

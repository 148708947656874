import { useCallback, useEffect, useState } from "react";
import { Contact } from "../../../services/soap/main/responses/GetContactListResponse";
import { useDependency } from "../../../contexts/DependencyProvider";
import { GetContactListRequest } from "../../../services/soap/main/requests/GetContactListRequest";

export function useContacts() {
    const { 
        mainService, 
        store 
    } = useDependency();

    const [contacts, setContacts] = useState<Contact[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchContacts = useCallback( async () => {
        try {
            setLoading(true);
            const response = await mainService.getContactList(new GetContactListRequest(store.Server, store.SessionId,{}));
            if (response.result === "OK") {
                setContacts(response.CONTACT);
            } else {
                setError('Failed to fetch contacts: '+ response.EXCEPTION.message);
            }            
        } catch (error:any) {
            setError('fetch error: '+ error.message);
        } finally {
            setLoading(false);
        }
    }, [mainService, store.Server, store.SessionId]);

    useEffect(() => {
        fetchContacts()
    }, [fetchContacts]);

    return { contacts, loading, error };
}
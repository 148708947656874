import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Activity, toActivityXml } from '../../dataObjects/Activity';

export interface ICreateActivityRequest extends IBaseRequest {
    ACTIVITY: Activity;
}

export class CreateActivityRequest extends BaseRequest implements ICreateActivityRequest {
    public ACTIVITY: Activity;

    constructor(server: string, session: string, activity: Activity) {
        super(server, session);
        this.ACTIVITY = activity;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += toActivityXml(this.ACTIVITY);
        return super.wrapperXml('CreateActivity', parameters);
    }
}

import { Grid, Flex, View } from '@adobe/react-spectrum';
import { Outlet } from 'react-router-dom';
import jsonPackage from '../../../package.json';
import { useReefConfig } from '../../hooks/UseReefConfig';

const WebLayout = () => {
  const { hasLoaded } = useReefConfig();
  var today = new Date();
  console.log('%cREEF version: '+ jsonPackage.version, 'background: #92C0EF; color: black; font-size: x-large');
  return (
    <>
      { !hasLoaded && (<div className='disabler'></div>)}
      <div className={`public-background ${hasLoaded ? '' : 'black-n-white'}`}>
        <Grid
          areas={{
            base: ['header', 'main', 'footer'],
            L: ['header header header', 'left-side main right-side', 'footer footer footer'],
          }}
          columns={{
            base: ['1fr'],
            L: ['1fr', '3fr', '1fr'],
          }}
          rows={{
            base: ['auto'],
            L: ['auto', '1fr', 'auto'],
          }}
        >
          <View gridArea={'header'}>
            <Flex direction="row" alignItems="center">
              <View>
                {/* <TooltipTrigger delay={0}>
                  <Text>{isOnline?<LinkIcon color="positive" />:<UnLinkIcon color="negative" />}</Text>
                  <Tooltip>{isOnline?'Online':'Offline'}</Tooltip>
                </TooltipTrigger> */}
              </View>
            </Flex>
          </View>
          <View gridArea={'left-side'} isHidden={{ base: true, L: false }}>
            &nbsp;
          </View>
          <View gridArea={'main'}>
            <Outlet />
          </View>
          <View gridArea={'right-side'} isHidden={{ base: true, L: false }}>
            &nbsp;
          </View>
          <View gridArea={'footer'}>
            <Flex direction="column" alignItems="center">
              <View isHidden={true}>
                <p>
                  v{jsonPackage.version} Copyright by Atollon {today.getFullYear()}
                </p>
              </View>
            </Flex>
          </View>
        </Grid>
      </div>
    </>
  );
};

export default WebLayout;

import React, { useEffect, useState } from 'react';
import { ActionButton, Content, DatePicker, Dialog, DialogTrigger, Flex, Item, Link, NumberField, Picker, TextField, View, Provider } from '@adobe/react-spectrum';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { RowData } from './ExpenseLineComponent';
import { useTranslation } from 'react-i18next';
import { parseDate } from '@internationalized/date';
import moment from 'moment';
import { GetFinanceSettingsRequest } from '../../../../services/soap/finance/requests/GetFinanceSettingsRequest';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListCurrencyRequest } from '../../../../services/soap/global/requests/ListCurrencyRequest';
interface ExpenseLineAddEditProps {
  id?: string;
  defaultRowData: {};
  selectedRowData: RowData;
  typeofWorkList: any[];
  handleRowClose: () => void;
  addRecord: (record: any) => void;
  updateRecord: (record: any) => void;
  deleteRecord: (record: any) => void;
  closeDialog: (isOpen: boolean) => void;
  isUnitPriceColumnVisible?: boolean;
  isTotalPriceColumnVisible?: boolean;
  styles: any;
}

const ExpenseLineAddEdit: React.FC<ExpenseLineAddEditProps> = ({
  id,
  defaultRowData,
  selectedRowData,
  typeofWorkList,
  handleRowClose,
  addRecord,
  updateRecord,
  deleteRecord,
  closeDialog,
  isUnitPriceColumnVisible,
  isTotalPriceColumnVisible,
  styles,
}) => {
  const { isMobile } = useViewInfo();
  const { globalService, financeService, store } = useDependency();
  const [selectedRow, setSelectedRow] = useState<RowData>(selectedRowData || defaultRowData);
  const [currencyList, setCurrencyList] = useState<any>([]);
  const [currencySetting, setCurrencySetting] = useState<string>('');
  const [validationMessages, setValidationMessages] = useState<any>({ typeOfWork: '', entryDate: '', qty: '', description: '', currency: '' });
  const { t } = useTranslation();

  useEffect(() => {
    //console.log('selectedRowData', selectedRowData);
    //console.log('id', id);
    getCurrencyList();
    setValidationMessages(
      selectedRowData.ident !== 0
        ? { typeOfWork: '', entryDate: '', qty: '', description: '', unitPrice: '', currency: '' }
        : { typeOfWork: 'required', entryDate: '', qty: '', description: '', unitPrice: '', currency: '' }
    );
    setSelectedRow(selectedRowData || defaultRowData);
  }, [id, selectedRowData]);

  const getCurrencyList = async () => {
    let currencyList = await globalService.listCurrency(new ListCurrencyRequest(store.Server, store.SessionId, 50, 0));
    if (currencyList?.result === 'OK') {
      //console.log('currencyList', currencyList);
      const formattedArray = currencyList.ROWS.map(({ ITEM }: { ITEM: { id: string; code: string } }) => ({
        id: ITEM.id,
        code: ITEM.code,
      }));
      setCurrencyList(formattedArray);
      //console.log('selectedRow.id', selectedRowData.id);
      selectedRowData.ident === 0 && (await getCurrencyDetails(formattedArray));
    }
  };

  const getCurrencyDetails = async (arrCurrencyList: any) => {
    let currencySetting = await financeService.getFinanceSettings(new GetFinanceSettingsRequest(store.Server, store.SessionId));
    if (currencySetting.result === 'OK' && currencySetting.count > 0) {
      //console.log('currencySetting', currencySetting.SETTINGS.currency);
      setCurrencySetting(currencySetting.SETTINGS.currency);
      //console.log('arrCurrencyList', arrCurrencyList);
      setSelectedRow(prev => ({ ...prev, currency: arrCurrencyList.find((item: any) => item.id === currencySetting.SETTINGS.currency)?.code }));
    }
  };

  const calculateTotalPrice = (qty: any, unitPrice: any) => {
    const totalPrice = (qty * Number(unitPrice)).toFixed(2);
    const amountWithVat = (qty * Number(selectedRowData.amountWithVat)).toFixed(2);
    setSelectedRow(prev => ({ ...prev, totalPrice: totalPrice, totalPriceWithVat: amountWithVat }));
  };

  const validateFields = () => !['typeOfWork', 'entryDate', 'qty', 'unitPrice', 'currency'].some(field => validationMessages[field] !== '');

  const validateAndSubmit = () => {
    if (!validateFields()) return;
    //console.log('selectedRowData', selectedRowData);
    const action = (selectedRowData && Number(selectedRowData.ident) !== 0) || Number(selectedRowData.id) !== 0 ? updateRecord : addRecord;
    //console.log('selectedRow', selectedRow); // Optional: Only if needed for both cases
    closeDialog(false);
    action(selectedRow);
  };

  return (
    <>
      {isMobile ? (
        <Flex direction="column" marginTop={'size-200'}>
          <View>
            <Picker
              label={t('type_of_expense', { ns: 'layout_components' })}
              isRequired
              width={'100%'}
              items={typeofWorkList}
              onSelectionChange={value => {
                setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                const typeOfWorkName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, typeOfWorkName }));
              }}
              selectedKey={selectedRow.typeOfWorkId}
              validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
            >
              {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
            </Picker>
          </View>
          <View>
            <Provider locale="en-US">
              <DatePicker
                label={t('date', { ns: 'layout_components' })}
                isRequired
                width={'100%'}
                value={parseDate(moment(selectedRow.entryDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                onChange={(value: any) => {
                  setSelectedRow(prev => ({ ...prev, entryDate: value }));
                }}
              />
            </Provider>
          </View>
          <View>
            <NumberField
              width={'100%'}
              value={Number(selectedRow.qty)}
              isRequired
              minValue={1}
              label={t('quantity', { ns: 'layout_components' })}
              onChange={value => {
                validationMessages.qty = isNaN(value) || value <= 0 ? 'required' : '';
                setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                setSelectedRow((prev: any) => ({ ...prev, qty: String(value) }));
                calculateTotalPrice(value, selectedRow.unitPrice);
              }}
              validationState={validationMessages.qty !== '' ? 'invalid' : 'valid'}
            />
          </View>
          <View>
            <TextField
              label={t('description', { ns: 'layout_components' })}
              width={'100%'}
              value={selectedRow.description}
              onChange={value => setSelectedRow(prev => ({ ...prev, description: value }))}
            />
          </View>
          {isUnitPriceColumnVisible && (
            <View>
              <NumberField
                width={'100%'}
                isRequired
                minValue={0}
                value={selectedRow.unitPrice}
                label={t('unit_price', { ns: 'layout_components' })}
                onChange={value => {
                  validationMessages.unitPrice = isNaN(value) || value <= 0 ? t('quantity_must_be_greater_than_zero', { ns: 'layout_components' }) : '';
                  setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                  setSelectedRow(prev => ({ ...prev, unitPrice: value }));
                  calculateTotalPrice(selectedRowData.qty, value);
                }}
                validationState={validationMessages.unitPrice !== '' ? 'invalid' : 'valid'}
              />
              <Picker
                width={'100%'}
                label={t('currency', { ns: 'layout_components' })}
                isRequired
                items={currencyList}
                onSelectionChange={value => {
                  setValidationMessages((prev: any) => ({ ...prev, currency: '' }));
                  const currencyCode = currencyList.find((item: any) => item.id === value)?.code;
                  setSelectedRow(prev => ({ ...prev, currency: currencyCode }));
                }}
                selectedKey={currencyList.find((item: any) => item.code === selectedRow.currency)?.id}
                validationState={validationMessages.currency !== '' ? 'invalid' : 'valid'}
              >
                {(item: any) => <Item key={item.id}>{item.code}</Item>}
              </Picker>
            </View>
          )}
          {isTotalPriceColumnVisible && (
            <View>
              <TextField
                width={'100%'}
                isDisabled
                value={`${selectedRow.totalPrice} ${selectedRow.currency}`}
                label={t('total_price', { ns: 'layout_components' })}
                onChange={value => setSelectedRow(prev => ({ ...prev, totalPrice: value }))}
              />
            </View>
          )}
        </Flex>
      ) : (
        <table className="spectrum-Table" style={{ marginTop: '20px' }} cellSpacing={0}>
          <thead className="spectrum-Table-head">
            <tr>
              <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <Picker
                    label=""
                    width={'100%'}
                    items={typeofWorkList}
                    onSelectionChange={value => {
                      setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                      const workerName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                      setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, workerName }));
                    }}
                    selectedKey={selectedRow.typeOfWorkId}
                    validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
                  >
                    {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
                  </Picker>
                </span>
              </th>
              <th style={{ width: '15%' }} className="spectrum-Table-headCell date_bg_fix">
                <Provider locale="en-US">
                  <DatePicker
                    UNSAFE_style={{ fontWeight: '400' }}
                    isRequired
                    width={'100%'}
                    value={parseDate(moment(selectedRow.entryDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                    onChange={(value: any) => {
                      setSelectedRow(prev => ({ ...prev, entryDate: value }));
                    }}
                  />
                </Provider>
              </th>
              <th style={{ width: '15%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <NumberField
                    width={'100%'}
                    value={Number(selectedRow.qty)}
                    hideStepper
                    isRequired
                    minValue={1}
                    label=""
                    onChange={value => {
                      validationMessages.qty = isNaN(value) || value <= 0 ? 'Qty must be greater than 0' : '';
                      setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                      setSelectedRow(prev => ({ ...prev, qty: String(value) }));
                      calculateTotalPrice(value, selectedRow.unitPrice);
                    }}
                    validationState={validationMessages.qty !== '' ? 'invalid' : 'valid'}
                  />
                </span>
              </th>
              <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <TextField width={'100%'} value={selectedRow.description} onChange={value => setSelectedRow(prev => ({ ...prev, description: value }))} />
                </span>
              </th>
              {isUnitPriceColumnVisible && (
                <th style={{ width: '18%' }} className="spectrum-Table-headCell">
                  <span className="spectrum-Table-columnTitle">
                    <table width={'100%'} style={{ marginTop: '-2px' }}>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width={'50%'}>
                            <NumberField
                              width={'100%'}
                              hideStepper
                              isRequired
                              minValue={0}
                              value={selectedRow.unitPrice}
                              label=""
                              onChange={value => {
                                validationMessages.unitPrice = isNaN(value) || value <= 0 ? t('quantity_must_be_greater_than_zero', { ns: 'layout_components' }) : '';
                                setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                                setSelectedRow(prev => ({ ...prev, unitPrice: value }));
                                calculateTotalPrice(selectedRow.qty, value);
                              }}
                              validationState={validationMessages.unitPrice !== '' ? 'invalid' : 'valid'}
                            />
                          </td>
                          <td>
                            <Picker
                              label=""
                              width={'100%'}
                              items={currencyList}
                              onSelectionChange={value => {
                                setValidationMessages((prev: any) => ({ ...prev, currency: '' }));
                                const currencyCode = currencyList.find((item: any) => item.id === value)?.code;
                                setSelectedRow(prev => ({ ...prev, currency: currencyCode }));
                              }}
                              selectedKey={currencyList.find((item: any) => item.code === selectedRow.currency)?.id || currencySetting}
                              validationState={validationMessages.currency !== '' ? 'invalid' : 'valid'}
                            >
                              {(item: any) => <Item key={item.id}>{item.code}</Item>}
                            </Picker>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                </th>
              )}

              {isTotalPriceColumnVisible && (
                <th style={{ width: '20%' }} className="spectrum-Table-headCell">
                  <span className="spectrum-Table-columnTitle">
                    <TextField
                      isDisabled
                      width={'98%'}
                      value={`${selectedRow.totalPrice} ${selectedRow.currency}`}
                      label=""
                      onChange={value => setSelectedRow(prev => ({ ...prev, totalPrice: value }))}
                    />
                  </span>
                </th>
              )}
            </tr>
          </thead>
        </table>
      )}

      <Flex direction={{ base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-175'} marginTop={'size-200'}>
        <Flex>
          {Number(selectedRowData.ident) !== 0 && (
            <Flex>
              <DialogTrigger isDismissable type="modal">
                <ActionButton UNSAFE_className={styles.btn_delete}>{t('remove_expense', { ns: 'layout_components' })}</ActionButton>
                {close => (
                  <Dialog size="S">
                    <Flex UNSAFE_className={styles.delete_pop_up_parent}>
                      <Content UNSAFE_className={styles.delete_pop_up_header}>{t('remove_expense', { ns: 'layout_components' })} </Content>
                      <View UNSAFE_className={styles.delete_pop_up_line}></View>
                      <View UNSAFE_className={styles.delete_pop_up_warning_text}>{t('are_you_sure_you_want_to_remove_expense', { ns: 'layout_components' })}</View>
                      <View UNSAFE_className={styles.buttons_parent}>
                        <View UNSAFE_className={styles.button_cancel}>
                          <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
                            {t('no_keep_it', { ns: 'layout_components' })}
                          </Link>
                        </View>
                        <View UNSAFE_className={styles.button_confirm}>
                          <div className={styles.trash_icon}>
                            <TrashIcon size={32} />
                          </div>
                          <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedRow)}>
                            {t('yes_remove', { ns: 'layout_components' })}
                          </Link>
                        </View>
                      </View>
                      <View UNSAFE_className={styles.ellipse_div}>
                        <View UNSAFE_className={styles.delete_alert_icon}>
                          <AlertIconFill size={72} />
                        </View>
                      </View>
                    </Flex>
                  </Dialog>
                )}
              </DialogTrigger>
            </Flex>
          )}
        </Flex>
        <Flex direction={{ base: 'row', L: 'row' }} gap={'size-200'}>
          <Flex>
            <Link UNSAFE_className={styles.btn_cancel} isQuiet onPress={handleRowClose}>
              {t('cancel', { ns: 'layout_components' })}
            </Link>
          </Flex>
          <Flex>
            <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={() => validateAndSubmit()}>
              {t('confirm_expense', { ns: 'layout_components' })}
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ExpenseLineAddEdit;

import { useState, useEffect } from 'react';
import { ToastQueue } from '@react-spectrum/toast';
import { Flex, View, Link, ToggleButton } from '@adobe/react-spectrum';
import { Grid, Fullscreen } from 'react-bootstrap-icons';
import FilterSearchTextBox from './FilterSearchTextBox';
import { useTranslation } from 'react-i18next';
import styles from './header_component.module.css';

interface IHeaderComponentProps {
  onPageViewChange: (view: string) => void;
  autoChanged: string;
  filterParams: any;
  onSearchChange: (params: {}) => void;
  fpaTypeCategory: string;
  onAddNew: () => void;
}

const HeaderComponent: React.FC<IHeaderComponentProps> = ({ onPageViewChange, autoChanged, filterParams, onSearchChange, fpaTypeCategory, onAddNew }) => {
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState<string>('Grid');

  useEffect(() => {
    setSelectedButton(prev => (prev === autoChanged ? prev : autoChanged));
  }, [autoChanged]);

  const handleToggle = (view: string) => {
    setSelectedButton(prev => (prev === view ? prev : view));
    onPageViewChange(view);
  };

  return (
    <View position={'relative'} width={'100%'} height={'56px'} overflow={'hidden'} UNSAFE_className={styles.header_parent}>
      <Flex direction={'row'} position={'absolute'} top={'0px'} left={'16px'} alignItems={'center'} justifyContent={'start'}>
        <Link
          isQuiet
          onPress={e => {
            onAddNew();
          }}
          UNSAFE_className={styles.add_new_btn}
        >
          <i className="bi bi-plus fs-5">
            <View UNSAFE_className={styles.add_new_btn_text}>{t('add_new', { ns: 'layout_components' })}</View>
          </i>
        </Link>

        <Flex direction={'column'} width={'217px'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.activity_search_input} marginStart={'20px'} marginEnd={'20px'}>
          <Flex direction={'row'} height={'32px'} alignSelf={'stretch'} flexShrink={0} alignItems={'center'} justifyContent={'end'} UNSAFE_className={styles.activity_search}>
            {/* <TextField width={'100%'} onChange={handleFiltering} /> */}
            <FilterSearchTextBox onSearchChange={onSearchChange} filterParams={filterParams} fpaTypeCategory={fpaTypeCategory} />
            <i className={`${styles.icon_activity_search} bi bi-search`}></i>
          </Flex>
        </Flex>
        <View position={'relative'} width={'1px'} height={'56px'} UNSAFE_className={styles.frame_divider}></View>
      </Flex>

      <Flex direction={'row'} position={'absolute'} top={'0px'} right={'0px'} alignItems={'center'} justifyContent={'end'} UNSAFE_className={styles.toggleBtn}>
        <ToggleButton isSelected={selectedButton === 'Grid'} onChange={() => handleToggle('Grid')} isEmphasized UNSAFE_className={styles.grid_view_icon}>
          <Grid />
        </ToggleButton>
        <ToggleButton isSelected={selectedButton === 'Full'} onChange={() => handleToggle('Full')} isEmphasized UNSAFE_className={styles.full_view_icon}>
          <Fullscreen />
        </ToggleButton>
      </Flex>
    </View>
  );
};

export default HeaderComponent;

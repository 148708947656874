// DataComponent.tsx
import { useState } from 'react';
import { useDataContext } from './Contexts/DataContext';
import { ComboBox, Item, Key } from '@adobe/react-spectrum';
import { DataComponentProps } from './DataComponentProps';
import { useTranslation } from 'react-i18next';

const PositionDropDown: React.FC<DataComponentProps> = ({ compKey, label, width, isRequired, onValueChange }) => {
  const { t } = useTranslation();
  const _t = (key: string) => t(`newContextDialog.dataComponents.${key}`, { ns: 'finder' });
  const message = _t('is_required').split('[content]').join(label);
  const [errorMessage, setErrorMessage] = useState(isRequired ? message : '');
  const [value, setValue] = useState<Key | null>('');
  const [input, setInput] = useState('');
  const { getPositionData } = useDataContext();
  const { data, error, loading } = getPositionData();
  const onSelectionChange = (value: Key | null) => {
    if(onValueChange)
        onValueChange(value ? value.toString() : '', input);
    setValue(value);
    if(isRequired && !value) 
      setErrorMessage(message);
    else 
      setErrorMessage('');
  };

  const onInputChange = (value: string) => {
    setInput(value);
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ComboBox key={compKey} 
        label={label} 
        width={width} 
        isRequired={isRequired} 
        selectedKey={value}
        onSelectionChange={onSelectionChange} 
        onInputChange={onInputChange} 
        validationState={errorMessage ? 'invalid' : undefined}
        errorMessage={errorMessage}
        >
      {data.map((item) => (
        <Item key={item.id}>
          {item.name}
        </Item>
      ))}
    </ComboBox>
  );
};

export default PositionDropDown;

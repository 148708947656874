// DataComponent.tsx
import { useEffect, useRef, useState } from 'react';
import { ComboBox, Item, Key } from '@adobe/react-spectrum';
import { DataComponentProps } from './DataComponentProps';
import { Contact } from '../../../services/soap/main/responses/GetContactListResponse';
import { useDependency } from '../../../contexts/DependencyProvider';
import { GetContactListRequest } from '../../../services/soap/main/requests/GetContactListRequest';
import ConstantUtils from '../../../utils/ConstantUtils';
import { useTranslation } from 'react-i18next';

interface EmailDropDownProps extends DataComponentProps {
  apiOptions: any;
}
const EmailDropDown: React.FC<EmailDropDownProps> = ({ compKey, label, width, isRequired, onValueChange, apiOptions, value:dvalue }) => {
  const { t } = useTranslation();
  const _t = (key: string) => t(`newContextDialog.dataComponents.${key}`, { ns: 'finder' });
  const message = _t('is_required').split('[content]').join(label);
  const [errorMessage, setErrorMessage] = useState(isRequired ? message : '');
  const [value, setValue] = useState<Key | null>('');
  const [input, setInput] = useState('');
  const [contacts, setContacts] = useState<Contact[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<string>('');
  const { mainService, store } = useDependency();

  inputRef.current = input;
  
  useEffect(() => {
    if(dvalue){
      setInput(dvalue);
    }
  }, [dvalue]);

  const onSelectionChange = (value: Key | null) => {
    if(onValueChange)
        onValueChange(value ? value.toString() : '', input);
    setValue(value);
    if(isRequired && !value) 
      setErrorMessage(message);
    else 
      setErrorMessage('');
  };

  const timer_cb = async () => {
    if(inputRef.current.length >= 3) {
      const response = await mainService.getContactList(new GetContactListRequest(store.Server, store.SessionId,{ ...apiOptions, contactContact: inputRef.current }));
      if(response.EXCEPTION) {
        console.error(response.EXCEPTION.message);
      } else if(response.CONTACT) {
        setContacts(response.CONTACT);
      } else {
        setContacts([]);
      }
    }else {
      setContacts([]);
    }
  }
  const onInputChange = async (value: string) => {
    setInput(value);
    if(isRequired && !value) 
      setErrorMessage(message);
    else 
      setErrorMessage('');

    if(timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(timer_cb, ConstantUtils.CONTEXT_NEW_SEARCH_DELAY);
  }

  return (
    <ComboBox key={compKey} 
        label={label} 
        width={width} 
        isRequired={isRequired} 
        selectedKey={value}
        onSelectionChange={onSelectionChange} 
        onInputChange={onInputChange}
        validationState={errorMessage ? 'invalid' : undefined}
        errorMessage={errorMessage}
        inputValue={input}
        allowsCustomValue
        >
      {contacts.map((item) => (
        <Item key={item.id}>
          {`${item.email}`}
        </Item>
      ))}
    </ComboBox>
  );
};

export default EmailDropDown;

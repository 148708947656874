import { useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Cell, Column, Row, TableView, TableBody, TableHeader, Flex, Heading, Content, View, Link, ProgressCircle, Dialog, DialogContainer } from '@adobe/react-spectrum';
import { useAsyncList } from 'react-stately';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ExpenseLineAddEdit from './ExpenseLineAddEdit';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { ToastQueue } from '@react-spectrum/toast';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { ListTimesheetRequest } from '../../../../services/soap/scheduler/requests/ListTimesheetRequest';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import { UpdateTimesheetRequest } from '../../../../services/soap/scheduler/requests/UpdateTimesheetRequest';
import { EvaluateTimesheetRequest } from '../../../../services/soap/scheduler/requests/EvaluateTimesheetRequest';
import { DeleteTimesheetRequest } from '../../../../services/soap/scheduler/requests/DeleteTimesheetRequest';
import { CreateTimesheetRequest } from '../../../../services/soap/scheduler/requests/CreateTimesheetRequest';
import { generateExpenseItem } from '../../../../services/soap/scheduler/requests/CreateTimesheetOptions';
import { GetTypeOfWorkForContextRequest } from '../../../../services/soap/scheduler/requests/GetTypeOfWorkForContextRequest';
import { GetSchedulerSettingsRequest, SettingsItemType } from '../../../../services/soap/scheduler/requests/GetSchedulerSettingsRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import styles from './ExpenseLineComponent.module.css';

interface ExpenseLineProps {
  isTotalPriceColumnVisible?: boolean;
  isUnitPriceColumnVisible?: boolean;
  selectedItem?: FPAData;
  isNew?: boolean;
  setData?: (dataItems: any[]) => void;
}

export interface RowData {
  ident: number;
  id: number;
  typeOfWorkId: string;
  typeOfWorkName: string;
  entryDateFormatted: string;
  entryDate: string;
  qty: string;
  description: string;
  currency: string;
  unitPrice: number;
  amountWithVat: number;
  vatValue: number;
  totalPrice: string;
  totalPriceWithVat: string;
}
interface Character {
  typeOfWorkId: string;
  typeOfWorkName: string;
  entryDateFormatted: string;
  entryDate: string;
  qty: any;
  name: any;
}

const ExpenseLine: React.FC<ExpenseLineProps> = ({ selectedItem, isTotalPriceColumnVisible, isUnitPriceColumnVisible, isNew, setData }) => {
  const { t } = useTranslation();
  const PAGE_SIZE: number = 50;

  let columnsData = [
    { name: t('type_of_expense', { ns: 'layout_components' }), key: 'typeOfWorkName' },
    { name: t('date', { ns: 'layout_components' }), key: 'entryDateFormatted' },
    { name: t('quantity', { ns: 'layout_components' }), key: 'qty' },
    { name: t('description', { ns: 'layout_components' }), key: 'description' },
    { name: t('unit_price', { ns: 'layout_components' }), key: 'unitPriceCurr' },
    { name: t('total_price', { ns: 'layout_components' }), key: 'totalPriceCurr' },
  ];

  let columnsDataMobile = [
    { name: t('type_of_expense', { ns: 'layout_components' }), key: 'typeOfWorkName', width: 250 },
    { name: t('date', { ns: 'layout_components' }), key: 'entryDateFormatted', width: 150 },
    { name: t('quantity', { ns: 'layout_components' }), key: 'qty', width: 150 },
    { name: t('description', { ns: 'layout_components' }), key: 'description', width: 250 },
    { name: t('unit_price', { ns: 'layout_components' }), key: 'unitPriceCurr', width: 130 },
    { name: t('total_price', { ns: 'layout_components' }), key: 'totalPriceCurr', width: 130 },
  ];

  if (!isUnitPriceColumnVisible) {
    columnsData = columnsData.filter(column => column.key !== 'unitPriceCurr');
    columnsDataMobile = columnsDataMobile.filter(column => column.key !== 'unitPriceCurr');
  }

  if (!isTotalPriceColumnVisible) {
    columnsData = columnsData.filter(column => column.key !== 'totalPriceCurr');
    columnsDataMobile = columnsDataMobile.filter(column => column.key !== 'totalPriceCurr');
  }

  let defaultRowData: RowData = {
    ident: 0,
    id: 0,
    typeOfWorkId: '',
    typeOfWorkName: '',
    entryDateFormatted: '',
    entryDate: '',
    qty: '',
    description: '',
    currency: '',
    unitPrice: 0,
    amountWithVat: 0,
    vatValue: 0,
    totalPrice: '0',
    totalPriceWithVat: '0',
  };

  const [reloadComponent] = useComonentReload();
  const { schedulerService, projectService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const [expenseLineData, setExpenseLineData] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const [totalTimeSheetRec, setTotalTimeSheetRec] = useState<number | undefined>(undefined);
  const [selectedRow, setSelectedRow] = useState<RowData>(defaultRowData);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showAddEditRow, setShowAddEditRow] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [folder_ID, setFolder_ID] = useState<string>('');
  const [typeofWorkList, setTypeofWorkList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setShowAddEditRow(false);
    (async () => {
      await getTypeofWorkList();
      const contextRespone = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, Number(selectedItem?.id)));
      //console.log('contextRespone', contextRespone);
      const folder_ID = contextRespone.LIST[1]?.CONTEXT.parentId;
      setFolder_ID(String(folder_ID));
      setShowLoader(false);
    })();
  }, [selectedItem]);

  useEffect(() => {
    listExpenseData.reload();
  }, [reloadComponent, selectedItem]);

  const getTypeofWorkList = async () => {
    let scheduleResp = await schedulerService.getSchedulerSettings(new GetSchedulerSettingsRequest(store.Server, store.SessionId, '4', SettingsItemType.EXPENSE_ITEM_TYPE, 50, 0));
    //console.log('scheduleResp', scheduleResp);

    let typeofWorkContextResp: any = await schedulerService.getTypeOfWorkForContext(new GetTypeOfWorkForContextRequest(store.Server, store.SessionId, true));
    //console.log('typeofWorkContextResp', typeofWorkContextResp);

    const filteredTypeofWork = scheduleResp.ITEMS.filter(item => typeofWorkContextResp.SETTINGS.some((setting: any) => setting.SETTING.typeOfWork === item.ITEM.ident));
    //console.log('filteredTypeofWork', filteredTypeofWork);

    setTypeofWorkList(filteredTypeofWork);
  };

  const getExpenseLineCount = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);

    // Call API to fetch expense line data
    let expenseResp: any = await schedulerService.listTimeSheet(
      new ListTimesheetRequest(store.Server, store.SessionId, {
        etypeFilter: '2',
        statusFilter: '0',
        chargeFilter: '0',
        typeOfWorkFilter: '0',
        countOnly: '1',
        FOLDERS: {
          count: '1',
          FOLDER: {
            folderId: String(folder_ID),
            projectId: String(selectedItem?.parent_id),
            activityId: String(selectedItem?.id),
          },
        },
      })
    );
    //console.log('expenseResp.ITEMS[0].ITEM.totalRecords', expenseResp.ITEMS[0].ITEM.totalRecords);

    // If API call is successful
    if (expenseResp.result === 'OK') {
      setTotalTimeSheetRec(expenseResp.ITEMS[0].ITEM.totalRecords != 0 ? expenseResp.ITEMS[0].ITEM.totalRecords : undefined);
      return expenseResp.ITEMS[0].ITEM.totalRecords != 0 ? expenseResp.ITEMS[0].ITEM.totalRecords : undefined;
    }
  };

  const formatDecimalToString = (amount: any) => {
    // Check if the amount ends with '.00'
    if (amount?.endsWith('.00')) {
      // If yes, remove the decimal part
      return amount.substring(0, amount.length - 3);
    }
    // If the decimal part is not '.00', return the original amount
    return amount;
  };

  let listExpenseData = useAsyncList<Character>({
    async load({ cursor }) {
      //console.log('cursor', cursor);
      if (isNew) {
        return { items: [], cursor: undefined };
      }
      let totalRecords = totalTimeSheetRec ?? (await getExpenseLineCount());
      //console.log('totalRecords', totalRecords);
      let currentPage = cursor ? parseInt(cursor) : 0;
      //console.log('currentPage', currentPage);
      let offset = PAGE_SIZE * currentPage;
      //console.log('offset', offset);
      if (isNaN(offset) || offset >= totalRecords) {
        setShowLoader(false);
        return { items: [], cursor: undefined };
      }

      let expenseResp = await schedulerService.listTimeSheet(
        new ListTimesheetRequest(store.Server, store.SessionId, {
          limit: PAGE_SIZE,
          offset,
          etypeFilter: '2',
          statusFilter: '0',
          chargeFilter: '0',
          typeOfWorkFilter: '0',
          FOLDERS: {
            count: '1',
            FOLDER: {
              folderId: String(folder_ID),
              projectId: String(selectedItem?.parent_id),
              activityId: String(selectedItem?.id),
            },
          },
        })
      );

      if (expenseResp.result === 'OK') {
        //console.log('expenseResp', expenseResp);
        setExpenseLineData(expenseResp.ITEMS);
        let expenseLineData: any = expenseResp.ITEMS.map(item => ({
          id: item.ITEM.ident,
          typeOfWorkId: item.ITEM.typeOfWorkId,
          typeOfWorkName: item.ITEM.typeOfWorkName,
          entryDateFormatted: moment(item.ITEM.entryDate).format('MM/DD/YYYY'),
          entryDate: moment(item.ITEM.entryDate).format('YYYY-MM-DD'),
          qty: formatDecimalToString(item.ITEM.qty),
          description: item.ITEM.description,
          currency: item.ITEM.currency,
          vatValue: item.ITEM.itmVat,
          unitPrice: formatDecimalToString(item.ITEM.rate),
          unitPriceCurr: formatDecimalToString(Number.parseFloat(item.ITEM.rate ?? '0').toFixed(2)) + ' ' + item.ITEM.currency,
          totalPrice: formatDecimalToString(Number.parseFloat(item.ITEM.resultPrice ?? '0').toFixed(2)),
          totalPriceCurr: formatDecimalToString(Number.parseFloat(item.ITEM.resultPrice ?? '0').toFixed(2)) + ' ' + item.ITEM.currency,
          purchasePrice: item.ITEM.rate,
        }));
        setRowsData(expenseLineData);
        return {
          items: expenseLineData,
          cursor: String(offset >= totalRecords ? undefined : currentPage + 1),
        };
      }
      setShowLoader(false);
      return { items: [], cursor: undefined };
    },
  });

  const handleRowClick = (rowId: any): void => {
    //console.log('rowId', rowId.currentKey);
    //console.log('expenseLineData', expenseLineData);

    const rowData: any = isNew ? expenseLineData.find((row: any) => row.id === rowId.currentKey) : rowsData.find((row: any) => row.id === rowId.currentKey);
    //console.log('rowData', rowData);
    setSelectedRow(rowData);
    setShowAddEditRow(true);
    isMobile && setIsOpen(true);
    setSelectedKeys(rowId);
  };

  const handleRowClose = (): void => {
    setSelectedRow(defaultRowData);
    setShowAddEditRow(false);
    isMobile && setIsOpen(false);
    handleUnselect();
  };

  const handleUnselect = () => {
    setSelectedKeys(new Set());
  };

  const addRecord = async (record: any) => {
    try {
      if (isNew && setData) {
        //console.log('record', record);
        const typeOfWork = typeofWorkList.find((item: any) => item.ITEM.ident == record.typeOfWorkId);
        const updatedRowsData = {
          ...record,
          ident: -1,
          id: Math.random().toString(36).substring(7),
          typeOfWorkName: typeOfWork.ITEM.title,
          entryDateFormatted: moment(new Date(record.entryDate)).format('MM/DD/YYYY'),
          unitPrice: record.unitPrice,
          unitPriceCurr: formatDecimalToString(Number.parseFloat(record.unitPrice ?? '0').toFixed(2)) + ' ' + record.currency,
          totalPrice: formatDecimalToString(Number.parseFloat(record.totalPrice ?? '0').toFixed(2)),
          totalPriceCurr: formatDecimalToString(Number.parseFloat(record.totalPrice ?? '0').toFixed(2)) + ' ' + record.currency,
          purchasePrice: record.unitPrice,
        };

        // Add the new record to the updated rows data
        const newRowsData: any = [...expenseLineData, updatedRowsData];
        // console.log('newRowsData', newRowsData);
        // Update the state with the new rows data
        setExpenseLineData(newRowsData);
        setData(newRowsData);
        handleRowClose();
        return;
      }
      setShowLoader(true);
      //console.log('addRecord', record);

      let result = await schedulerService.createTimeSheet(
        new CreateTimesheetRequest(
          store.Server,
          store.SessionId,
          generateExpenseItem(
            String(store.UserId),
            '',
            record.typeOfWorkId,
            '',
            record.description,
            record.qty,
            String(selectedItem?.id),
            '',
            String(selectedItem?.parent_id),
            '',
            folder_ID,
            '',
            new Date(record.entryDate),
            record.unitPrice,
            record.totalPrice,
            record.currency
          )
        )
      );

      //console.log('addRecord result', result);

      if (result.result === 'OK') {
        await schedulerService.evaluateTimeSheet(new EvaluateTimesheetRequest(store.Server, store.SessionId, { recordId: result.itemId }));
        setTotalTimeSheetRec(undefined);
        handleRowClose();
        listExpenseData.reload();
        ToastQueue.positive(t('record_saved_successfully', { ns: 'layout_components' }), { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error: any) {
      ToastQueue.negative(error.message, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const updateRecord = async (record: any) => {
    try {
      //console.log('updateRecord', record);
      if (isNew && setData) {
        const typeOfWork = typeofWorkList.find((item: any) => item.ITEM.ident == record.typeOfWorkId || item.ITEM.id == record.typeOfWorkId);
        //console.log('typeOfWork', typeOfWork);
        record.typeOfWorkName = typeOfWork.ITEM.title;
        record.entryDateFormatted = moment(new Date(record.entryDate)).format('MM/DD/YYYY');
        record.unitPriceCurr = formatDecimalToString(Number.parseFloat(record.unitPrice ?? '0').toFixed(2)) + ' ' + record.currency;
        record.totalPrice = formatDecimalToString(Number.parseFloat(record.totalPrice ?? '0').toFixed(2));
        record.totalPriceCurr = formatDecimalToString(Number.parseFloat(record.totalPrice ?? '0').toFixed(2)) + ' ' + record.currency;
        record.purchasePrice = record.unitPrice;
        const updatedRowsData = expenseLineData.map((row: any) => (row.id === record.id ? record : row));
        //console.log('updatedRowsData', updatedRowsData);
        setExpenseLineData(updatedRowsData);
        setData(updatedRowsData);
        handleRowClose();
        return;
      }
      setShowLoader(true);
      //console.log('expenseLineData', expenseLineData);
      //console.log('update record', record);
      let itemRow = expenseLineData.find((row: any) => row.ITEM.ident == record.ident || row.ITEM.ident == record.id);
      //console.log('itemRow', itemRow);

      if (typeof record.entryDate === 'object') {
        record.entryDate = new Date(record.entryDate);
      }

      let result = await schedulerService.updateTimeSheet(
        new UpdateTimesheetRequest(store.Server, store.SessionId, {
          ...itemRow.ITEM,
          description: record.description,
          entryDate: record.entryDate,
          qty: record.qty,
          typeOfWorkId: record.typeOfWorkId,
          rate: record.unitPrice,
          resultPrice: record.totalPrice,
          currency: record.currency,
        })
      );

      if (result.result === 'OK') {
        //console.log('result', result);
        await schedulerService.evaluateTimeSheet(new EvaluateTimesheetRequest(store.Server, store.SessionId, { recordId: result.itemId }));
        handleRowClose();
        listExpenseData.reload();
        ToastQueue.positive(t('record_updated_successfully', { ns: 'layout_components' }), { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const deleteRecord = async (record: any) => {
    try {
      if (isNew && setData) {
        //console.log('record', record);
        const updatedRowsData = expenseLineData.filter((row: any) => row.id !== record.id);
        //console.log('updatedRowsData', updatedRowsData);
        setExpenseLineData(updatedRowsData);
        setData(updatedRowsData);
        handleRowClose();
        return;
      }
      setShowLoader(true);
      //console.log('record', record);
      let result = await schedulerService.deleteTimeSheet(new DeleteTimesheetRequest(store.Server, store.SessionId, record.ident ?? record.id));
      //console.log('result', result);
      if (result.result === 'OK') {
        handleRowClose();
        listExpenseData.reload();
        ToastQueue.positive(t('record_deleted_successfully', { ns: 'layout_components' }), { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            {t('expense', { ns: 'layout_components' })}
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isQuiet
                onPress={e => {
                  defaultRowData.entryDate = new Date().toISOString();
                  defaultRowData.qty = '1';
                  setSelectedRow(defaultRowData);
                  handleUnselect();
                  isMobile && setIsOpen(true);
                  setShowAddEditRow(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>{t('add_expense', { ns: 'layout_components' })}</View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            {listExpenseData.isLoading ? (
              <Flex width="100%" justifyContent={'center'} marginTop={10}>
                <ProgressCircle aria-label="Loading…" isIndeterminate />
              </Flex>
            ) : (
              <TableView
                UNSAFE_className="tblLayoutComponent"
                aria-label="expense line"
                onSelectionChange={handleRowClick}
                selectionMode="single"
                selectedKeys={selectedKeys}
                selectionStyle="highlight"
                width={{ base: '100%', L: '100%', M: '100%' }}
                minHeight={'80px'}
                maxHeight={{ base: '1000px', L: '450px' }}
                marginBottom={'size-250'}
              >
                <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                  {(column: any) => (
                    <Column showDivider width={column?.width}>
                      {column.name}
                    </Column>
                  )}
                </TableHeader>
                <TableBody
                  items={isNew ? expenseLineData : listExpenseData.items}
                  loadingState={isNew ? undefined : listExpenseData.loadingState}
                  onLoadMore={isNew ? undefined : listExpenseData.loadMore}
                >
                  {item => <Row>{columnKey => <Cell>{(item as any)[columnKey] === 'Yes' ? <strong>{(item as any)[columnKey]}</strong> : (item as any)[columnKey]}</Cell>}</Row>}
                </TableBody>
              </TableView>
            )}
          </Flex>

          {isMobile ? (
            <DialogContainer
              isDismissable
              onDismiss={() => {
                setIsOpen(false);
              }}
            >
              {isOpen && (
                <Dialog>
                  <Heading>
                    {selectedRow.ident === 0 ? t('add', { ns: 'layout_components' }) : t('edit', { ns: 'layout_components' })} {t('expense', { ns: 'layout_components' })}
                  </Heading>
                  <Content>
                    <ExpenseLineAddEdit
                      id={String(selectedItem?.id)}
                      defaultRowData={defaultRowData}
                      typeofWorkList={typeofWorkList}
                      selectedRowData={selectedRow}
                      handleRowClose={handleRowClose}
                      addRecord={addRecord}
                      updateRecord={updateRecord}
                      deleteRecord={deleteRecord}
                      closeDialog={closeDialog}
                      styles={styles}
                      isUnitPriceColumnVisible={isUnitPriceColumnVisible}
                      isTotalPriceColumnVisible={isTotalPriceColumnVisible}
                    />
                  </Content>
                </Dialog>
              )}
            </DialogContainer>
          ) : (
            showAddEditRow && (
              <ExpenseLineAddEdit
                id={String(selectedItem?.id)}
                defaultRowData={defaultRowData}
                typeofWorkList={typeofWorkList}
                selectedRowData={selectedRow}
                handleRowClose={handleRowClose}
                addRecord={addRecord}
                updateRecord={updateRecord}
                deleteRecord={deleteRecord}
                closeDialog={closeDialog}
                styles={styles}
                isUnitPriceColumnVisible={isUnitPriceColumnVisible}
                isTotalPriceColumnVisible={isTotalPriceColumnVisible}
              />
            )
          )}
        </Flex>
      </Flex>
    );
  }
};

export const ExpenseLineComponent = LayoutComponent(ExpenseLine);

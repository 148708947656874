export interface Order {
    COL: OrderCol[];
}
export function toOrderXml(order: Order): string {
    var xml = `<ORDER>`;
    if(order.COL) {
        order.COL.forEach(function (orderCol) {
            xml += toOrderColXml(orderCol);
        });
    }
    xml += `</ORDER>`;
    return xml;
}

export interface OrderCol {
    name: string;
    desc: string;
}

export function toOrderColXml(orderCol: OrderCol): string {
    var xml = `<COL>`;
    xml += `<name>${orderCol.name}</name>`;
    xml += `<desc>${orderCol.desc}</desc>`;
    xml += `</COL>`;
    return xml;
}
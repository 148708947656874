import { RestServiceBase } from '../RestServiceBase';
import { PrintContextRequest } from './requests/PrintContextRequest';
import { PrintContextResponse } from './responses/PrintContextResponse';

export interface IPrintRestService {
  printContext(request: PrintContextRequest): Promise<any>;
  printDocument(request: PrintContextRequest): Promise<PrintContextResponse>;
}

export class PrintRestService extends RestServiceBase implements IPrintRestService {
  public async printContext(request: PrintContextRequest): Promise<any> {
    //const CALL_TAG = `${this.REST_URL}file?server=${request.server}&session=${request.session}&print_type=${request.print_type}&object_id=${request.object_id}&output_format=${request.output_format}`;
    const CALL_TAG = `file?server=${request.server}&session=${request.session}&print_type=${request.print_type}&object_id=${request.object_id}&output_format=${request.output_format}`;
    let response: any = null;
    var request_data = {
      signatureName: request.signatureName,
      signaturePicture: request.signaturePicture,
    };
    try {
      let tmp_response = await this.makeCall(request_data, CALL_TAG, request.cancelSource, 'arraybuffer');
      response = tmp_response.data;
      await this.updateCache(CALL_TAG, JSON.stringify(request_data), response);
    } catch (error: any) {
      response = await this.getFromCache(CALL_TAG, JSON.stringify(request_data));
    }
    return response;
  }
  public async printDocument(request: PrintContextRequest): Promise<PrintContextResponse> {
    //const CALL_TAG = `${this.REST_URL}document?server=${request.server}&session=${request.session}&print_type=${request.print_type}&object_id=${request.object_id}`;
    const CALL_TAG = `document?server=${request.server}&session=${request.session}&print_type=${request.print_type}&object_id=${request.object_id}`;
    let response: any = null;
    var request_data = {
      signatureName: request.signatureName,
      signaturePicture: request.signaturePicture,
    };
    try {
      let tmp_response = await this.makeCall(request_data, CALL_TAG, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(CALL_TAG, JSON.stringify(request_data), response);
    } catch (error: any) {
      response = await this.getFromCache(CALL_TAG, JSON.stringify(request_data));
    }
    var parsedResponse = response as PrintContextResponse;
    // if(!super._checkErrors(parsedResponse)){

    // };
    return parsedResponse;
  }
}

import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IUpdateExternalContactRequest extends IBaseRequest {
    EXTERNAL_CONTACT: ExternalContact;
}

export interface ExternalContact {
    id?:                string;
    contextId?:         string;
    contactId?:         string;
    note?:              string;
    isPrimary?:         string;
    role?:              string;
    relationshipLevel?: string;
    deleteContact?:     string;
    name1?:             string;
    name2?:             string;
    name3?:             string;
    type?:              string;
    email?:             string;
    phone?:             string;
    mobile?:            string;
}

export class UpdateExternalContactRequest extends BaseRequest implements IUpdateExternalContactRequest {

    public EXTERNAL_CONTACT: ExternalContact;
    constructor(server: string, session: string, externalContact: ExternalContact) {
        super(server, session);
        this.EXTERNAL_CONTACT = externalContact;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        parameters += '<EXTERNAL_CONTACT>';
        if (this.EXTERNAL_CONTACT.id) parameters += `<id>${this.EXTERNAL_CONTACT.id}</id>`;
        if (this.EXTERNAL_CONTACT.contextId) parameters += `<contextId>${this.EXTERNAL_CONTACT.contextId}</contextId>`;
        if (this.EXTERNAL_CONTACT.contactId) parameters += `<contactId>${this.EXTERNAL_CONTACT.contactId}</contactId>`;
        if (this.EXTERNAL_CONTACT.note) parameters += `<note>${this.EXTERNAL_CONTACT.note}</note>`;
        if (this.EXTERNAL_CONTACT.isPrimary) parameters += `<isPrimary>${this.EXTERNAL_CONTACT.isPrimary}</isPrimary>`;
        if (this.EXTERNAL_CONTACT.role) parameters += `<role>${this.EXTERNAL_CONTACT.role}</role>`;
        if (this.EXTERNAL_CONTACT.relationshipLevel) parameters += `<relationshipLevel>${this.EXTERNAL_CONTACT.relationshipLevel}</relationshipLevel>`;
        if (this.EXTERNAL_CONTACT.deleteContact) parameters += `<deleteContact>${this.EXTERNAL_CONTACT.deleteContact}</deleteContact>`;
        if (this.EXTERNAL_CONTACT.name1) parameters += `<name1>${this.EXTERNAL_CONTACT.name1}</name1>`;
        if (this.EXTERNAL_CONTACT.name2) parameters += `<name2>${this.EXTERNAL_CONTACT.name2}</name2>`;
        if (this.EXTERNAL_CONTACT.name3) parameters += `<name3>${this.EXTERNAL_CONTACT.name3}</name3>`;
        if (this.EXTERNAL_CONTACT.type) parameters += `<type>${this.EXTERNAL_CONTACT.type}</type>`;
        if (this.EXTERNAL_CONTACT.email) parameters += `<email>${this.EXTERNAL_CONTACT.email}</email>`;
        if (this.EXTERNAL_CONTACT.phone) parameters += `<phone>${this.EXTERNAL_CONTACT.phone}</phone>`;
        if (this.EXTERNAL_CONTACT.mobile) parameters += `<mobile>${this.EXTERNAL_CONTACT.mobile}</mobile>`;
        parameters += '</EXTERNAL_CONTACT>';

        return super.wrapperXml('UpdateExternalContact', parameters);
    }
}

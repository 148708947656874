import { BaseRequest, IBaseRequest } from "../../BaseRequest";
import { Filter, toFilterXml } from "../../dataObjects/Filter";
import { Order, toOrderXml } from "../../dataObjects/Order";

export interface Parent {
    id: number;
    type: string;
}
function toParentXml(p:Parent):string{
    return `<PARENT>
    <id>${p.id}</id>
    <type>${p.type}</type>
    </PARENT>`;
}

export interface IListFPARequest2 extends IBaseRequest{
    limit?: number;
    offset?: number;
    orderBy?: string;
    countOnly?: boolean;
    flatView?: boolean;
    includeSlave?: boolean;
    fulltext?: string;
    parent?: Parent;
    folderFilter?: Filter;
    projectFilter?: Filter;
    activityFilter?: Filter;
    ORDER?: Order;    
}

export class ListFPARequest2 extends BaseRequest implements IListFPARequest2 {
    limit?: number;
    offset?: number;
    orderBy?: string;
    countOnly?: boolean;
    flatView?: boolean;
    includeSlave?: boolean;
    fulltext?: string;
    parent?: Parent;
    folderFilter?: Filter;
    projectFilter?: Filter;
    activityFilter?: Filter;
    ORDER?: Order;    

    constructor(server: string, 
        session: string,
        otherOptions: any) {
            super(server, session);
            this.limit = otherOptions.limit;
            this.offset = otherOptions.offset;
            this.orderBy = otherOptions.orderBy;
            this.countOnly = otherOptions.countOnly;
            this.flatView = otherOptions.flatView;
            this.includeSlave = otherOptions.includeSlave;
            this.fulltext = otherOptions.fulltext;
            this.parent = otherOptions.parent;
            this.folderFilter = otherOptions.folderFilter;
            this.projectFilter = otherOptions.projectFilter;
            this.activityFilter = otherOptions.activityFilter;
            this.ORDER = otherOptions.ORDER;
    }

    toXml(): string {
        var parameters:string = super.toXml();
        if(this.limit) parameters += `<limit>${this.limit}</limit>\n`;
        if(this.offset !== undefined) parameters += `<offset>${this.offset}</offset>\n`;
        if(this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>\n`;
        if(this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>\n`;
        if(this.flatView) parameters += `<flatView>${this.flatView}</flatView>\n`;
        if(this.includeSlave) parameters += `<includeSlave>${this.includeSlave}</includeSlave>\n`;
        if(this.fulltext) parameters += `<fulltext>${this.fulltext}</fulltext>\n`;
        if(this.parent) parameters += toParentXml(this.parent);
        if(this.folderFilter) parameters += toFilterXml(this.folderFilter, "FILTER_FOLDER");
        if(this.projectFilter) parameters += toFilterXml(this.projectFilter, "FILTER_PROJECT");
        if(this.activityFilter) parameters += toFilterXml(this.activityFilter, "FILTER_ACTIVITY");
        if(this.ORDER) parameters += toOrderXml(this.ORDER);

        return super.wrapperXml("ListFPA", parameters);
    }
}
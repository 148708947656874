import { SCHEDULER } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { ICreateTaskRequest } from "./requests/CreateTaskRequest";
import { ICreateTimesheetRequest } from "./requests/CreateTimesheetRequest";
import { IDeleteTaskRequest } from "./requests/DeleteTaskRequest";
import { IDeleteTimesheetRequest } from "./requests/DeleteTimesheetRequest";
import { IEvaluateTimesheetRequest } from "./requests/EvaluateTimesheetRequest";
import { IGetSchedulerSettingsRequest } from "./requests/GetSchedulerSettingsRequest";
import { IGetTypeOfWorkForContextRequest } from "./requests/GetTypeOfWorkForContextRequest";
import { IListEventRequest } from "./requests/ListEventRequest";
import { IListTaskRequest } from "./requests/ListTaskRequest";
import { IListTimesheetRequest } from "./requests/ListTimesheetRequest";
import { IUpdateTaskRequest } from "./requests/UpdateTaskRequest";
import { IUpdateTimesheetRequest } from "./requests/UpdateTimesheetRequest";
import { CreateTaskResponse } from "./responses/CreateTaskResponse";
import { CreateTimesheetResponse } from "./responses/CreateTimesheetResponse";
import { DeleteTaskResponse } from "./responses/DeleteTaskResponse";
import { DeleteTimesheetResponse } from "./responses/DeleteTimesheetResponse";
import { EvaluateTimesheetResponse } from "./responses/EvaluateTimesheetResponse";
import { GetSchedulerSettingsResponse } from "./responses/GetSchedulerSettingsResponse";
import { GetTypeOfWorkForContextResponse } from "./responses/GetTypeOfWorkForContextResponse";
import { ListEventResponse } from "./responses/ListEventResponse";
import { ListTaskResponse } from "./responses/ListTaskResponse";
import { ListTimesheetResponse } from "./responses/ListTimesheetResponse";
import { UpdateTaskResponse } from "./responses/UpdateTaskResponse";
import { UpdateTimesheetResponse } from "./responses/UpdateTimesheetResponse";

export interface ISchedulerService {
    getSchedulerSettings(request: IGetSchedulerSettingsRequest): Promise<GetSchedulerSettingsResponse>;
    listTimeSheet(request: IListTimesheetRequest): Promise<ListTimesheetResponse>;
    createTimeSheet(request: ICreateTimesheetRequest): Promise<CreateTimesheetResponse>;
    updateTimeSheet(request: IUpdateTimesheetRequest): Promise<UpdateTimesheetResponse>;
    deleteTimeSheet(request: IDeleteTimesheetRequest): Promise<DeleteTimesheetResponse>;
    getTypeOfWorkForContext(request: IGetTypeOfWorkForContextRequest): Promise<GetTypeOfWorkForContextResponse>;
    evaluateTimeSheet(request: IEvaluateTimesheetRequest): Promise<EvaluateTimesheetResponse>;

    createTask(request: ICreateTaskRequest): Promise<CreateTaskResponse>;
    updateTask(request: IUpdateTaskRequest): Promise<UpdateTaskResponse>;
    listTask(request: IListTaskRequest): Promise<ListTaskResponse>;
    deleteTask(request: IDeleteTaskRequest): Promise<DeleteTaskResponse>;

    listEvent(request: IListEventRequest): Promise<ListEventResponse>;
}

export class SchedulerService extends ServiceBase implements ISchedulerService {
    private _addRootElementForItem(xml: string, rootElement: string = 'ListTimesheetResponse'): string {
        xml = xml.replaceAll('</name><ITEM>', '</name><ITEMS><ITEM>');
        xml = xml.replaceAll(`</ITEM></ns1:${rootElement}>`, `</ITEM></ITEMS></ns1:${rootElement}>`);
        return xml;
    }

    public async getSchedulerSettings(request: IGetSchedulerSettingsRequest): Promise<GetSchedulerSettingsResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.getSchedulerSettings, request.cancelSource));
            response = this._addRootElementForItem( tmp_response.data, 'GetSchedulerSettingsResponse');
            await this.updateCache(SCHEDULER.getSchedulerSettings, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.getSchedulerSettings, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetSchedulerSettingsResponse as GetSchedulerSettingsResponse;
        if(!super._checkErrors(parsedResponse)){

        }else{
            parsedResponse.ITEMS = parsedResponse.ITEMS.filter((item) => ((+item.ITEM.validFor) & request.itemType) !== 0);
        }

        return parsedResponse;        
    }

    public async listTimeSheet(request: IListTimesheetRequest): Promise<ListTimesheetResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.listTimeSheet, request.cancelSource));
            response =  this._addRootElementForItem(tmp_response.data);
            await this.updateCache(SCHEDULER.listTimeSheet, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.listTimeSheet, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListTimesheetResponse as ListTimesheetResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async createTimeSheet(request: ICreateTimesheetRequest): Promise<CreateTimesheetResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.createTimeSheet, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(SCHEDULER.createTimeSheet, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.createTimeSheet, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CreateTimesheetResponse as CreateTimesheetResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async updateTimeSheet(request: IUpdateTimesheetRequest): Promise<UpdateTimesheetResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.updateTimeSheet, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(SCHEDULER.updateTimeSheet, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.updateTimeSheet, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateTimesheetResponse as UpdateTimesheetResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async deleteTimeSheet(request: IDeleteTimesheetRequest): Promise<DeleteTimesheetResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.deleteTimeSheet, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(SCHEDULER.deleteTimeSheet, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.deleteTimeSheet, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).DeleteTimesheetResponse as DeleteTimesheetResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async getTypeOfWorkForContext(request: IGetTypeOfWorkForContextRequest): Promise<GetTypeOfWorkForContextResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.getTypeOfWorkForContext, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(SCHEDULER.getTypeOfWorkForContext, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.getTypeOfWorkForContext, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetTypeOfWorkForContextResponse as GetTypeOfWorkForContextResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async evaluateTimeSheet(request: IEvaluateTimesheetRequest): Promise<EvaluateTimesheetResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.evaluateTimeSheet, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(SCHEDULER.evaluateTimeSheet, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.evaluateTimeSheet, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).EvaluateTimesheetResponse as EvaluateTimesheetResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async createTask(request: ICreateTaskRequest): Promise<CreateTaskResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.createTask, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(SCHEDULER.createTask, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.createTask, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CreateTaskResponse as CreateTaskResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async updateTask(request: IUpdateTaskRequest): Promise<UpdateTaskResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.updateTask, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(SCHEDULER.updateTask, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.updateTask, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateTaskResponse as UpdateTaskResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listTask(request: IListTaskRequest): Promise<ListTaskResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.listTask, request.cancelSource));
            response = this._addRootElementForItem(tmp_response.data, 'ListTaskResponse');
            await this.updateCache(SCHEDULER.listTask, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.listTask, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListTaskResponse as ListTaskResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async deleteTask(request: IDeleteTaskRequest): Promise<DeleteTaskResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.deleteTask, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(SCHEDULER.deleteTask, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.deleteTask, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).DeleteTaskResponse as DeleteTaskResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listEvent(request: IListEventRequest): Promise<ListEventResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), SCHEDULER.listEvent, request.cancelSource));
            response = this._addRootElementForItem(tmp_response.data, 'ListEventResponse');
            await this.updateCache(SCHEDULER.listEvent, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(SCHEDULER.listEvent, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response,['ITEM', 'USER']).ListEventResponse as ListEventResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
}
// DataComponent.tsx
import { useEffect, useState } from 'react';
import { useDataContext } from './Contexts/DataContext';
import { ComboBox, Item, Key } from '@adobe/react-spectrum';
import { DataComponentProps } from './DataComponentProps';
import { useTranslation } from 'react-i18next';

interface ActivityDropDownProps extends DataComponentProps {
    parent_project_id?: number;
    parent_activity_id?: number;
    related_to_activity_type?: number;
}
const ActivityDropDown: React.FC<ActivityDropDownProps> = ({ 
    parent_project_id,
    parent_activity_id,
    related_to_activity_type,
    compKey, 
    label, 
    width, 
    isRequired, 
    onValueChange ,
    value: dvalue
}) => {
  const { t } = useTranslation();
  const _t = (key: string) => t(`newContextDialog.dataComponents.${key}`, { ns: 'finder' });
  const message = _t('is_required').split('[content]').join(label);
  const [errorMessage, setErrorMessage] = useState(isRequired ? message : '');
  const [value, setValue] = useState<Key | null>('');
  const [input, setInput] = useState('');
  const { getActivityData } = useDataContext();
  const { data, error, loading } = getActivityData({ parent_project_id, parent_activity_id, related_to_activity_type });

  useEffect(() => {
    if(dvalue && dvalue != 'undefined') {
        setSelection(dvalue);
    }
  },[dvalue]);
  
  const setSelection = (p_value:string) => {
    if(onValueChange)
      onValueChange(p_value ? p_value.toString() : '', input);
    setValue(p_value);

    if(isRequired && !p_value) 
      setErrorMessage(message);
    else 
      setErrorMessage('');
  }
  const onSelectionChange = (p_value: Key | null) => {
    setSelection(p_value ? p_value.toString() : '');
  };

  const onInputChange = (p_value: string) => {
    setInput(p_value);
    if(isRequired && !p_value) 
      setErrorMessage(message);
    else 
      setErrorMessage('');
  }
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ComboBox key={compKey} 
        label={label} 
        width={width} 
        isRequired={isRequired} 
        selectedKey={value}
        onSelectionChange={onSelectionChange} 
        onInputChange={onInputChange}
        validationState={errorMessage ? 'invalid' : undefined}
        errorMessage={errorMessage}
        >
      {data.map((item) => (
        <Item key={item.id} textValue={item.title}>
          {item.title}
        </Item>
      ))}
    </ComboBox>
  );
};

export default ActivityDropDown;

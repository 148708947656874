import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Project, toProjectXml } from '../../dataObjects/Folder';

export interface ICreateProjectRequest extends IBaseRequest {
     PROJECT: Project;
}

export class CreateProjectRequest extends BaseRequest implements ICreateProjectRequest {
    public PROJECT: Project;

    constructor(server: string, session: string, project: Project) {
        super(server, session);
        this.PROJECT = project;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += toProjectXml(this.PROJECT);
        return super.wrapperXml('CreateProject', parameters);
    }
}

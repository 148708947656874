// DataComponent.tsx
import { useState } from 'react';
import { useDataContext } from './Contexts/DataContext';
import { ComboBox, Item, Key } from '@adobe/react-spectrum';
import { DataComponentProps } from './DataComponentProps';
import { useTranslation } from 'react-i18next';

interface ContactDropDownProps extends DataComponentProps {
  apiOptions: any;
}
const ContactDropDown: React.FC<ContactDropDownProps> = ({ compKey, label, width, isRequired, onValueChange, apiOptions }) => {
  const { t } = useTranslation();
  const _t = (key: string) => t(`newContextDialog.dataComponents.${key}`, { ns: 'finder' });
  const message = _t('is_required').split('[content]').join(label);
  const [errorMessage, setErrorMessage] = useState(isRequired ? message : '');
  const [value, setValue] = useState<Key | null>('');
  const [input, setInput] = useState('');
  const { getContactData } = useDataContext();
  const { data, error, loading } = getContactData(apiOptions);

  const onSelectionChange = (value: Key | null) => {
    if(onValueChange)
        onValueChange(value ? value.toString() : '', input);
    setValue(value);
    // if(isRequired && !value) 
    //   setErrorMessage(message);
    // else 
    //   setErrorMessage('');
  };

  const onInputChange = (value: string) => {
    setInput(value);
    if(isRequired && !value) 
      setErrorMessage(message);
    else 
      setErrorMessage('');
  }
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ComboBox key={compKey} 
        label={label} 
        width={width} 
        isRequired={isRequired} 
        selectedKey={value}
        onSelectionChange={onSelectionChange} 
        onInputChange={onInputChange}
        validationState={errorMessage ? 'invalid' : undefined}
        errorMessage={errorMessage}
        allowsCustomValue
        >
      {data.map((item) => (
        <Item key={item.id}>
          {`${item.name1} ${item.name2 ?? ''} ${item.name3 ?? ''}`}
        </Item>
      ))}
    </ComboBox>
  );
};

export default ContactDropDown;

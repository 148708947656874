import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListActivityTemplateRequest extends IBaseRequest {
    iType?:           string;
    fpaTypeCategory?: string;
}

export class ListActivityTemplateRequest extends BaseRequest implements IListActivityTemplateRequest {
    public iType?: string;
    public fpaTypeCategory?: string;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.iType = otherOptions.iType;
        this.fpaTypeCategory = otherOptions.fpaTypeCategory
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.iType) parameters += '<iType>' + this.iType + '</iType>';
        if (this.fpaTypeCategory) parameters += '<fpaTypeCategory>' + this.fpaTypeCategory + '</fpaTypeCategory>';
        
        return super.wrapperXml('ListActivityTemplate', parameters);
    }
}

import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface ICreateCompanyPersonRelationRequest extends IBaseRequest {
    EMPLOYEE:  Employee;
}

export interface Employee {
    id?:             string;
    contact?:        string;
    company?:        string;
    department?:     string;
    departmentInfo?: string;
    position?:       string;
    positionInfo?:   string;
    isPrimary?:      boolean;
    validFrom?:      Date;
    validTo?:        Date;
    type?:           string;
    originalName?:   string;
    custom1?:        string;
    custom2?:        string;
    custom3?:        string;
}

function toEmployeeXml(emp:Employee): string{
    var xml = '';

    if(emp.id) xml += `<id>${emp.id}</id>`;
    if(emp.contact) xml += `<contact>${emp.contact}</contact>`;
    if(emp.company) xml += `<company>${emp.company}</company>`;
    if(emp.department) xml += `<department>${emp.department}</department>`;
    if(emp.departmentInfo) xml += `<departmentInfo>${emp.departmentInfo}</departmentInfo>`;
    if(emp.position) xml += `<position>${emp.position}</position>`;
    if(emp.positionInfo) xml += `<positionInfo>${emp.positionInfo}</positionInfo>`;
    if(emp.isPrimary) xml += `<isPrimary>${emp.isPrimary}</isPrimary>`;
    if(emp.validFrom) xml += `<validFrom>${emp.validFrom}</validFrom>`;
    if(emp.validTo) xml += `<validTo>${emp.validTo}</validTo>`;
    if(emp.type) xml += `<type>${emp.type}</type>`;
    if(emp.originalName) xml += `<originalName>${emp.originalName}</originalName>`;
    if(emp.custom1) xml += `<custom1>${emp.custom1}</custom1>`;
    if(emp.custom2) xml += `<custom2>${emp.custom2}</custom2>`;
    if(emp.custom3) xml += `<custom3>${emp.custom3}</custom3>`;

    return `<EMPLOYEE>${xml}</EMPLOYEE>`;
}
export class CreateCompanyPersonRelationRequest extends BaseRequest implements ICreateCompanyPersonRelationRequest {
    public EMPLOYEE: Employee;
    constructor(server: string, session: string, employee: Employee) {
        super(server, session);
        this.EMPLOYEE = employee;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += toEmployeeXml(this.EMPLOYEE);
        return super.wrapperXml('CreateCompanyPersonRelation', parameters);
    }
}

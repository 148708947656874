import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { FilterCol, Order } from './ListFPARequest';

export interface IListFPATypeDocumentParsRequest extends IBaseRequest {
  limit?: number;
  offset?: number;
  orderBy?: string;
  countOnly?: boolean;
  FILTER?: FilterCol[];
  ORDER?: Order;
}

export class ListFPATypeDocumentParsRequest extends BaseRequest implements IListFPATypeDocumentParsRequest {
  public limit?: number;
  public offset?: number;
  public orderBy?: string;
  public countOnly?: boolean;
  public FILTER?: FilterCol[];
  public ORDER?: Order;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.limit = otherOptions.limit;
    this.offset = otherOptions.offset;
    this.orderBy = otherOptions.orderBy;
    this.countOnly = otherOptions.countOnly;
    this.FILTER = otherOptions.FILTER;
    this.ORDER = otherOptions.ORDER;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.limit) parameters += `<limit>${this.limit}</limit>`;
    if (this.limit) parameters += `<offset>${this.offset}</offset>`;
    if (this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>`;
    if (this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>`;
    if (this.FILTER) parameters += `<FILTER>${this.FILTER.map(filter => filter.toXml()).join('')}</FILTER>`;
    if (this.ORDER) parameters += this.ORDER.toXml();

    return super.wrapperXml('ListFPATypeDocumentPars', parameters);
  }
}

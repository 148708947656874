import { useCallback, useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Flex, ProgressCircle, Content, TableView, TableHeader, TableBody, Column, Row, Cell } from '@adobe/react-spectrum';
import { EventTaskCompletedIcon } from '../../Icons/IconsLib';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import useComonentReload from '../../../../hooks/UseComponentReload';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { CircleFill } from 'react-bootstrap-icons';
import { statusColor } from '../../../../utils/helperUtil';
import { ListEventRequest } from '../../../../services/soap/scheduler/requests/ListEventRequest';
import { GetSchedulerSettingsRequest, SettingsItemType } from '../../../../services/soap/scheduler/requests/GetSchedulerSettingsRequest';
import styles from './events_past_line_completed.module.css';

interface EventsPastLineCompletedComponentProps {
  selectedItem?: FPAData;
}

function EventsPastLineCompleted({ selectedItem }: Readonly<EventsPastLineCompletedComponentProps>) {
  const { t } = useTranslation();

  let columnsData = [
    {
      key: 'icon',
      name: '',
      width: 50,
    },
    {
      key: 'dateTo',
      name: t('date', { ns: 'layout_components' }),
      width: 170,
    },
    {
      key: 'solutionStatus',
      name: t('status', { ns: 'layout_components' }),
      width: null,
    },
    {
      key: 'title',
      name: t('subject', { ns: 'layout_components' }),
      width: null,
    },
    {
      key: 'userList',
      name: t('user', { ns: 'layout_components' }),
      width: null,
    },
    {
      key: 'entryTypeName',
      name: t('type_of_work', { ns: 'layout_components' }),
      width: null,
    },
  ];

  let columnsDataMobile = [
    {
      key: 'icon',
      name: '',
      width: 50,
    },
    {
      key: 'dateTo',
      name: t('date', { ns: 'layout_components' }),
      width: 170,
    },
    {
      key: 'solutionStatus',
      name: t('status', { ns: 'layout_components' }),
      width: 150,
    },
    {
      key: 'title',
      name: t('subject', { ns: 'layout_components' }),
      width: 250,
    },
    {
      key: 'userList',
      name: t('user', { ns: 'layout_components' }),
      width: 250,
    },
    {
      key: 'entryTypeName',
      name: t('type_of_work', { ns: 'layout_components' }),
      width: 150,
    },
  ];

  const [reloadComponent] = useComonentReload();
  const { schedulerService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const [showLoader, setShowLoader] = useState(false);
  const [rowsData, setRowsData] = useState<any>([]);

  useEffect(() => {
    (async () => {
      getEventStatusList().then(async (statusArray: any) => {
        await loadEventsPastLineCompleted(statusArray);
      });
    })();
  }, [selectedItem, reloadComponent]);

  const getEventStatusList = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await schedulerService.getSchedulerSettings(new GetSchedulerSettingsRequest(store.Server, store.SessionId, '1', SettingsItemType.EVENT_ITEM_TYPE, 300, 0));
    //console.log('res', res);
    if (res.result === 'OK' && res.ITEMS.length > 0) {
      res?.ITEMS.forEach((status: any) => {
        const { ident, title, color } = status.ITEM;
        let colorString = statusColor(color);
        statusArray.push({ ident, title, color: colorString });
      });

      statusArray.unshift({ ident: 'All states', title: '', color: 'black' });
      return statusArray;
    }
  }, []);

  const loadEventsPastLineCompleted = async (statusArray: any) => {
    try {
      setShowLoader(true);
      let queryParams = {
        userFilter: 1,
        orderBy1Desc: false,
        orderBy1Field: 'dateFrom',
        anyTime: 0,
        folderId: '',
        projectId: '',
        activityId: '',
        dateOfInterest: 0,
        standardStatus: 1,
        rangeEnd: new Date(),
        rangeStart: new Date(1950, 11, 30, 22, 27, 7),
        groupUsers: 1,
        userId: store.UserId,
      };

      switch (selectedItem?.type) {
        case FPADataTypes.FOLDER:
          queryParams.folderId = String(selectedItem?.id);
          break;
        case FPADataTypes.PROJECT:
          queryParams.folderId = String(selectedItem?.parent_id);
          queryParams.projectId = String(selectedItem?.id);
          break;
        case FPADataTypes.ACTIVITY:
          queryParams.folderId = String(selectedItem?.parent?.parent_id);
          queryParams.projectId = String(selectedItem?.parent_id);
          queryParams.activityId = String(selectedItem?.id);
          break;
      }

      let eventRes: any = await schedulerService.listEvent(
        new ListEventRequest(store.Server, store.SessionId, {
          ...queryParams,
        })
      );

      if (eventRes?.result == 'OK' && eventRes.ITEMS) {
        //console.log('listEvent', eventRes);

        let eventPastCompeletedData: any = eventRes.ITEMS.ITEM.map((item: any) => ({
          id: item.ident,
          typeOfWorkName: item.typeOfWorkName,
          dateTo: moment(item.dateTo).format('MM/DD/YYYY | HH:mm A'),
          title: item.title,
          entryTypeName: item.entryTypeName,
          userList: item.userList,
          solutionStatus: getStatusColor(statusArray, item.solutionStatus),
          icon: getEventIcon(),
        }));
        //sorted by dateTo
        const sortedData = [...eventPastCompeletedData].sort((a, b) => moment(b.dateTo, 'MM/DD/YYYY | hh:mm A').valueOf() - moment(a.dateTo, 'MM/DD/YYYY | hh:mm A').valueOf());
        setRowsData(sortedData);
      } else {
        setRowsData([]);
        //ToastQueue.info(t('no_results', { ns: 'layout_components' }), { timeout: 50 });
        return [];
      }
    } finally {
      setShowLoader(false);
    }
  };

  const getEventIcon = () => {
    return <EventTaskCompletedIcon size={32} />;
  };

  const getStatusColor = (statusArray: any, solutionStatus: any) => {
    const statusObj = statusArray.find((obj: any) => obj.ident == solutionStatus);
    //console.log('statusObj', statusObj);
    return (
      <>
        <CircleFill style={{ paddingRight: '10px' }} size={'18px'} color={statusObj?.color} /> {statusObj?.title}
      </>
    );
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            {t('completed_past_events', { ns: 'layout_components' })}
          </Content>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px', M: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            <TableView
              UNSAFE_className="tblLayoutComponent"
              aria-label="Event Future"
              selectionMode="single"
              selectionStyle="highlight"
              width={{ base: '100%', L: '100%', M: '100%' }}
              minHeight={'80px'}
              maxHeight={{ base: '1000px', L: '400px' }}
              marginBottom={'size-250'}
            >
              <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                {column => (
                  <Column showDivider key={column.key} width={column?.width}>
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody items={rowsData}>{item => <Row>{columnKey => <Cell>{(item as any)[String(columnKey)]}</Cell>}</Row>}</TableBody>
            </TableView>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export const EventsPastLineCompletedComponent = LayoutComponent(EventsPastLineCompleted);

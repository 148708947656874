export interface Filter {
    COL: FilterCol[];
}

export function toFilterXml(filter: Filter, nodeName:string = 'FILTER'): string {
    var xml = `<${nodeName}>`;
    if(filter.COL) {
        filter.COL.forEach(function (filterCol) {
            xml += toFilterColXml(filterCol);
        });
    }
    xml += `</${nodeName}>`;
    return xml;
}

export interface FilterCol {
    name:        string;
    type:        string;
    value?:       string;
    negation?:    string;
    includeNull?: string;
}
export function toFilterColXml(filterCol: FilterCol): string {
    var xml = `<COL>`;
    xml += `<name>${filterCol.name}</name>`;
    xml += `<type>${filterCol.type}</type>`;
    if(filterCol.value) xml += `<value>${filterCol.value}</value>`;
    if(filterCol.negation) xml += `<negation>${filterCol.negation}</negation>`;
    if(filterCol.includeNull) xml += `<includeNull>${filterCol.includeNull}</includeNull>`;
    xml += `</COL>`;
    return xml;
}
// DataContext.tsx
import React, { createContext, useContext } from 'react';
import { 
    useActivityDataLoader,
    useContactDataLoader, 
    useCountryDataLoader, 
    useDepartmentDataLoader, 
    useFolderDataLoader, 
    useIndustryDataLoader, 
    useLanguageDataLoader, 
    usePositionDataLoader, 
    useProjectDataLoader,
    useUserDataLoader
} from './DataLoader';
import { Industry } from '../../../../services/soap/main/responses/ListIndustryResponse';
import { Country } from '../../../../services/soap/translate/responses/ListCountryResponse';
import { Language } from '../../../../services/soap/translate/responses/ListLanguagesResponse';
import { Position } from '../../../../services/soap/main/responses/ListPositionResponse';
import { Department } from '../../../../services/soap/main/responses/ListDepartmentResponse';
import { Contact } from '../../../../services/soap/main/responses/GetContactListResponse';


interface DataContextProps {
    getIndustryData: () => { data: Industry[]; error: string | null; loading: boolean };
    getCountryData: () => { data: Country[]; error: string | null; loading: boolean };
    getLanguageData: () => { data: Language[]; error: string | null; loading: boolean };
    getPositionData: () => { data: Position[]; error: string | null; loading: boolean };
    getDepartmentData: () => { data: Department[]; error: string | null; loading: boolean };
    getContactData: (apiOptions:any) => { data: Contact[]; error: string | null; loading: boolean };
    getFolderData: (apiOptions:any) => { data: any[]; error: string | null; loading: boolean };
    getProjectData: (apiOptions:any) => { data: any[]; error: string | null; loading: boolean };
    getActivityData: (apiOptions:any) => { data: any[]; error: string | null; loading: boolean };
    getUserData: () => { data: any[]; error: string | null; loading: boolean };
}

const DataContext = createContext<DataContextProps | null>(null);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const useIndustryData = () => { return useIndustryDataLoader(); };
    const useCountryData = () => { return useCountryDataLoader(); };
    const useLanguageData = () => { return useLanguageDataLoader(); };
    const usePositionData = () => { return usePositionDataLoader(); };
    const useDepartmentData = () => { return useDepartmentDataLoader(); };
    const useContactData = (apiOptions:any) => { return useContactDataLoader(apiOptions); };
    const useFolderData = (apiOptions: any) => { return useFolderDataLoader(apiOptions); };
    const useProjectData = (apiOptions: any) => { return useProjectDataLoader(apiOptions); };
    const useActivityData = (apiOptions: any) => { return useActivityDataLoader(apiOptions); };
    const useUserData = () => { return useUserDataLoader(); };

    return (
        <DataContext.Provider value={{ 
            getIndustryData: useIndustryData, 
            getCountryData: useCountryData,
            getLanguageData: useLanguageData,
            getPositionData: usePositionData,
            getDepartmentData: useDepartmentData,
            getContactData: useContactData,
            getFolderData: useFolderData,
            getProjectData: useProjectData,
            getActivityData: useActivityData,
            getUserData: useUserData
        }}>
            {children}
        </DataContext.Provider>
    );
};

// Hook to use DataContext
export const useDataContext = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

import moment from "moment";

export class ConstantUtils {
    static readonly LIST_FPA_PAGE_SIZE = 50;
    static readonly LIST_CONTEXT_PAGE_SIZE = 50;

    static readonly FINDER_TITLE_MAX_LENGTH = 20;
    static readonly FINDER_LIST_ITEM_TITLE_MAX_LENGTH = 20;
    static readonly FINDER_PROJECT_LIST_ITEM_TITLE_MAX_LENGTH = 16;
    static readonly FINDER_ACTIVITY_LIST_ITEM_TITLE_MAX_LENGTH = 12;
    
    static readonly CONTEXT_NEW_SEARCH_DELAY = 300;
    static FIRST_TIME_LOAD = true;
    
    static xmlFormatDate(date: Date): string {
        return moment(date).format('YYYY-MM-DD');
    }
}

export default ConstantUtils;
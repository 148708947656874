// DataComponent.tsx
import { useEffect, useRef, useState } from 'react';
import { ComboBox, Item, Key } from '@adobe/react-spectrum';
import { DataComponentProps } from './DataComponentProps';
import { useDependency } from '../../../contexts/DependencyProvider';
import { usePreloadAssets } from '../../../hooks/UsePreloadAssets';
import { FPAData, FPADataFactory } from '../../../infra/protected/FPA/FPAData';
import ConstantUtils from '../../../utils/ConstantUtils';
import { ListFPARequest2 } from '../../../services/soap/project/requests/ListFPARequest2';
import { Col, FilterCol, Order } from '../../../services/soap/project/requests/ListFPARequest';
import { useTranslation } from 'react-i18next';

interface FolderDropDownProps extends DataComponentProps {
    folder_type_id?: number;
    parent_folder_id?: number;
    activity_type_id?: number;
    flatView: boolean;
}
const FolderDropDown: React.FC<FolderDropDownProps> = ({ 
  folder_type_id, 
  parent_folder_id,
  activity_type_id,
  compKey, 
  label, 
  width, 
  isRequired, 
  onValueChange,
  value: dvalue,
  flatView,
}) => {
  const { t } = useTranslation();
  const _t = (key: string) => t(`newContextDialog.dataComponents.${key}`, { ns: 'finder' });
  const message = _t('is_required').split('[content]').join(label);
  const [errorMessage, setErrorMessage] = useState(isRequired ? message : '');
  const [value, setValue] = useState<Key | null>('');
  const [input, setInput] = useState('');
  const [data, setData] = useState<FPAData[]>([]);
  const { projectService, store } = useDependency();
  const { folderStatusMapping, projectStatusMapping, activityStatusMapping } = usePreloadAssets();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<string>('');

  inputRef.current = input;

  useEffect(() => {
    if(dvalue && dvalue !== 'undefined') {
      loadData({id: parseInt(dvalue)}).then(() => {
        setValue(dvalue);
      });
    } else if(parent_folder_id) {
      loadData({});
    }
  },[dvalue, parent_folder_id]);

  const onSelectionChange = (value: Key | null) => {
    if(onValueChange)
        onValueChange(value ? value.toString() : '', input);
    setValue(value);
    if(isRequired && !value) 
      setErrorMessage(message);
    else 
      setErrorMessage('');
  };

  const onInputChange = (value: string) => {
    setInput(value);

    if(isRequired && !value) 
      setErrorMessage(message);
    else 
      setErrorMessage('');

    if(timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(timer_cb, ConstantUtils.CONTEXT_NEW_SEARCH_DELAY);
  }
  
  const loadData = async ({ searchText , id }: { searchText?: string, id? : number}) => {
    const folder_filter: FilterCol[] = [];

    if (folder_type_id) {
      folder_filter.push(new FilterCol('folderType', 'equals', folder_type_id.toString()));
    }
    if(parent_folder_id) {
      folder_filter.push(new FilterCol('parent', 'equals', parent_folder_id.toString()));
    }

    if(id){
      folder_filter.push(new FilterCol('id', 'equals', id.toString()));
    }

    const response = await projectService.listFPA2(
      new ListFPARequest2(
          store.Server, store.SessionId,
          {
              offset: 0,
              limit: 100,
              fulltext: searchText ? searchText : undefined,
              flatView: flatView,
              folderFilter:{
                  COL: folder_filter
              },
              ORDER: new Order([
                  new Col('name'), 
                  new Col('created', true)
              ])
          }
      )        
    );
    if(response.EXCEPTION) {
      console.error(response.EXCEPTION.message);
    } else if(response.ITEMS) {
      const result = response.ITEMS.map((item: any) => FPADataFactory.createFromItem(
                                      item, 
                                      folderStatusMapping, 
                                      projectStatusMapping, 
                                      activityStatusMapping))
      setData(result);
    } else {
      setData([]);
    }
  }
  const timer_cb = async () => {
    if(inputRef.current.length >= 3) {
      await loadData({
        searchText: `${inputRef.current}*`
      });
    }else {
      setData([]);
    }
  }

  return (
    <ComboBox key={compKey} 
        label={label} 
        width={width} 
        isRequired={isRequired} 
        selectedKey={value}
        onSelectionChange={onSelectionChange} 
        onInputChange={onInputChange}
        validationState={errorMessage ? 'invalid' : undefined}
        errorMessage={errorMessage}
        >
      {data.map((item) => (
        <Item key={item.id} textValue={item.title}>
          {item.title}
        </Item>
      ))}
    </ComboBox>
  );
};

export default FolderDropDown;

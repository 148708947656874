import { TreeView, TreeViewItem } from '@react-spectrum/tree';
import { FPAItem } from '../../../../infra/protected/FPA/FPAItem';
import { Key, Selection, Text } from '@adobe/react-spectrum';
import { useEffect } from 'react';

export interface IFinderContextTreeProps {
    items:FPAItem[];
    onSelection?: (key: string) => void;
    expandedKeys?: Set<Key>;
    setExpandedKeys?: (keys: Set<Key>) => void;
    selectedKeys?: Selection;
    setSelectedKeys?: (keys: Selection) => void;
}

export function FinderContextTree ({ 
    items, 
    onSelection,
    expandedKeys,
    setExpandedKeys,
    selectedKeys,
    setSelectedKeys,
}: IFinderContextTreeProps) {

    const handleSelectionChange = (key: any) => { 
        if (setSelectedKeys) setSelectedKeys(key);
        if (onSelection) onSelection(key.currentKey);
    };

    return (
        <TreeView
            aria-label='Finder Context Tree'
            items={items}
            selectionMode="single"
            onSelectionChange={handleSelectionChange}
            onExpandedChange={setExpandedKeys}
            height={'size-6000'}
            selectionStyle='highlight'
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
        >
            {(item) => (
                <TreeViewItem key={item.id} textValue={item.title} childItems={item.childItems}>
                    <Text>{item.title}</Text>
                </TreeViewItem>
            )}
        </TreeView>
    );
}

import { Employments, Industries, toEmploymentsXml, toIndustriesXml } from "./Folder";

export interface Person {
    id?:                   string;
    treeHandle?:           string;
    aclh?:                 string;
    account?:              string;
    accresponsiblePerson?: string;
    accresponsibleUserId?: string;
    bankCode?:             string;
    bankName?:             string;
    category?:             string;
    sysUser?:              string;
    sysUserName?:          string;
    description?:          string;
    adsAllowed?:           string;
    validFrom?:            string;
    validTo?:              string;
    modified?:             string;
    oldId?:                string;
    position?:             string;
    positionInfo?:         string;
    reference?:            string;
    swiftCode?:            string;
    sourceDb?:             string;
    trackingCode?:         string;
    name1?:                string;
    name2?:                string;
    name3?:                string;
    city?:                 string;
    country?:              string;
    fax?:                  string;
    icq?:                  string;
    mobile?:               string;
    phone?:                string;
    email?:                string;
    other?:                string;
    twitter?:              string;
    facebook?:             string;
    pager?:                string;
    region?:               string;
    street?:               string;
    zip?:                  string;
    dueDays?:              string;
    bankIBAN?:             string;
    bankNoteForeign?:      string;
    consentStatus?:        string;
    consentNote?:          string;
    anonymized?:           string;
    employer?:             string;
    massmailEnabledFrom?:  string;
    massmailEnabledTo?:    string;
    massmailEnabled?:      string;
    ADDRESSES?:            Addresses;
    CONTACTS?:             Contacts;
    BANKS?:                string;
    DGROUPS?:              string;
    EXTERNAL_IDS?:         string;
    PERSONINFO?:           Personinfo;
    EMPLOYMENTS?:          Employments;
    RELATIONS?:            string;
    FOLDERS?:              string;
    COMPANYINFO?:          Companyinfo;
    INDUSTRIES?:           Industries;
}

export function toPersonXml(p:Person, root:string = 'PERSON'):string{
    var result = '';
    if(p.id) result += `<id>${p.id}</id>`;
    if(p.treeHandle) result += `<treeHandle>${p.treeHandle}</treeHandle>`;
    if(p.aclh) result += `<aclh>${p.aclh}</aclh>`;
    if(p.account) result += `<account>${p.account}</account>`;
    if(p.accresponsiblePerson) result += `<accresponsiblePerson>${p.accresponsiblePerson}</accresponsiblePerson>`;
    if(p.accresponsibleUserId) result += `<accresponsibleUserId>${p.accresponsibleUserId}</accresponsibleUserId>`;
    if(p.bankCode) result += `<bankCode>${p.bankCode}</bankCode>`;
    if(p.bankName) result += `<bankName>${p.bankName}</bankName>`;
    if(p.category) result += `<category>${p.category}</category>`;
    if(p.sysUser) result += `<sysUser>${p.sysUser}</sysUser>`;
    if(p.sysUserName) result += `<sysUserName>${p.sysUserName}</sysUserName>`;
    if(p.description) result += `<description>${p.description}</description>`;
    if(p.adsAllowed) result += `<adsAllowed>${p.adsAllowed}</adsAllowed>`;
    if(p.validFrom) result += `<validFrom>${p.validFrom}</validFrom>`;
    if(p.validTo) result += `<validTo>${p.validTo}</validTo>`;
    if(p.modified) result += `<modified>${p.modified}</modified>`;
    if(p.oldId) result += `<oldId>${p.oldId}</oldId>`;
    if(p.position) result += `<position>${p.position}</position>`;
    if(p.positionInfo) result += `<positionInfo>${p.positionInfo}</positionInfo>`;
    if(p.reference) result += `<reference>${p.reference}</reference>`;
    if(p.swiftCode) result += `<swiftCode>${p.swiftCode}</swiftCode>`;
    if(p.sourceDb) result += `<sourceDb>${p.sourceDb}</sourceDb>`;
    if(p.trackingCode) result += `<trackingCode>${p.trackingCode}</trackingCode>`;
    if(p.name1) result += `<name1>${p.name1}</name1>`;
    if(p.name2) result += `<name2>${p.name2}</name2>`;
    if(p.name3) result += `<name3>${p.name3}</name3>`;
    if(p.city) result += `<city>${p.city}</city>`;
    if(p.country) result += `<country>${p.country}</country>`;
    if(p.fax) result += `<fax>${p.fax}</fax>`;
    if(p.icq) result += `<icq>${p.icq}</icq>`;
    if(p.mobile) result += `<mobile>${p.mobile}</mobile>`;
    if(p.other) result += `<other>${p.other}</other>`;
    if(p.twitter) result += `<twitter>${p.twitter}</twitter>`;
    if(p.facebook) result += `<facebook>${p.facebook}</facebook>`;
    if(p.pager) result += `<pager>${p.pager}</pager>`;
    if(p.region) result += `<region>${p.region}</region>`;
    if(p.street) result += `<street>${p.street}</street>`;
    if(p.zip) result += `<zip>${p.zip}</zip>`;
    if(p.dueDays) result += `<dueDays>${p.dueDays}</dueDays>`;
    if(p.bankIBAN) result += `<bankIBAN>${p.bankIBAN}</bankIBAN>`;
    if(p.bankNoteForeign) result += `<bankNoteForeign>${p.bankNoteForeign}</bankNoteForeign>`;
    if(p.consentStatus) result += `<consentStatus>${p.consentStatus}</consentStatus>`;
    if(p.consentNote) result += `<consentNote>${p.consentNote}</consentNote>`;
    if(p.anonymized) result += `<anonymized>${p.anonymized}</anonymized>`;
    if(p.employer) result += `<employer>${p.employer}</employer>`;
    if(p.massmailEnabledFrom) result += `<massmailEnabledFrom>${p.massmailEnabledFrom}</massmailEnabledFrom>`;
    if(p.massmailEnabledTo) result += `<massmailEnabledTo>${p.massmailEnabledTo}</massmailEnabledTo>`;
    if(p.massmailEnabled) result += `<massmailEnabled>${p.massmailEnabled}</massmailEnabled>`;
    if(p.ADDRESSES) result += toAddressesXml(p.ADDRESSES);
    if(p.CONTACTS) result += toContactsXml(p.CONTACTS);
    if(p.BANKS) result += `<BANKS>${p.BANKS}</BANKS>`;
    if(p.DGROUPS) result += `<DGROUPS>${p.DGROUPS}</DGROUPS>`;
    if(p.EXTERNAL_IDS) result += `<EXTERNAL_IDS>${p.EXTERNAL_IDS}</EXTERNAL_IDS>`;
    if(p.PERSONINFO) result += toPersoninfoXML(p.PERSONINFO);
    if(p.EMPLOYMENTS) result += toEmploymentsXml(p.EMPLOYMENTS);
    if(p.RELATIONS) result += `<RELATIONS>${p.RELATIONS}</RELATIONS>`;
    if(p.FOLDERS) result += `<FOLDERS>${p.FOLDERS}</FOLDERS>`;
    if(p.COMPANYINFO) result += toCompanyinfoXml(p.COMPANYINFO);
    if(p.INDUSTRIES) result += toIndustriesXml(p.INDUSTRIES);

    return `<${root}>${result}</${root}>`;
}

export interface Addresses {
    ADDRESS?: Address[];
}

function toAddressesXml(a:Addresses):string{
    var result = '<ADDRESSES>';
    if(a.ADDRESS){
        a.ADDRESS.forEach(address => {
            result += toAddressXml(address);
        });
    }
    result += '</ADDRESSES>';
    return result;
}

export interface Address {
    city?:       string;
    cityName?:   string;
    country?:    string;
    isPrimary?:  string;
    region?:     string;
    regionName?: string;
    street?:     string;
    type?:       string;
    zip?:        string;
    zipName?:    string;
}

function toAddressXml(a:Address):string {
    var result = '';
    if(a.city) result += `<city>${a.city}</city>`;
    if(a.cityName) result += `<cityName>${a.cityName}</cityName>`;
    if(a.country) result += `<country>${a.country}</country>`;
    if(a.isPrimary) result += `<isPrimary>${a.isPrimary}</isPrimary>`;
    if(a.region) result += `<region>${a.region}</region>`;
    if(a.regionName) result += `<regionName>${a.regionName}</regionName>`;
    if(a.street) result += `<street>${a.street}</street>`;
    if(a.type) result += `<type>${a.type}</type>`;
    if(a.zip) result += `<zip>${a.zip}</zip>`;
    if(a.zipName) result += `<zipName>${a.zipName}</zipName>`;

    return `<ADDRESS>${result}</ADDRESS>`;
}

export interface Companyinfo {
    legalForm?:     string;
    legalFormName?: string;
}

function toCompanyinfoXml(ci:Companyinfo):string{
    var result = '';
    if(ci.legalForm) result += `<legalForm>${ci.legalForm}</legalForm>`;
    if(ci.legalFormName) result += `<legalFormName>${ci.legalFormName}</legalFormName>`;

    return `<COMPANYINFO>${result}</COMPANYINFO>`;
}

export interface Contacts {
    CONTACT?: Contact[];
}

function toContactsXml(c:Contacts):string{
    var result = '<CONTACTS>';
    if(c.CONTACT){
        c.CONTACT.forEach(contact => {
            result += toContactXml(contact);
        });
    }
    result += '</CONTACTS>';
    return result;
}

export interface Contact {
    content?:     string;
    description?: string;
    isMain?:      string;
    isPrimary?:   string;
    type?:        string;
    typeName?:    string;
    typeType?:    string;
    category?:    string;
}

function toContactXml(c:Contact):string{
    var result = '';
    if(c.content) result += `<content>${c.content}</content>`;
    if(c.description) result += `<description>${c.description}</description>`;
    if(c.isMain) result += `<isMain>${c.isMain}</isMain>`;
    if(c.isPrimary) result += `<isPrimary>${c.isPrimary}</isPrimary>`;
    if(c.type) result += `<type>${c.type}</type>`;
    if(c.typeName) result += `<typeName>${c.typeName}</typeName>`;
    if(c.typeType) result += `<typeType>${c.typeType}</typeType>`;
    if(c.category) result += `<category>${c.category}</category>`;

    return `<CONTACT>${result}</CONTACT>`;
}

export interface Personinfo {
    prefixTitle?:   string;
    postfixTitle?:  string;
    sex?:           string;
    maritalStatus?: string;
    born?:          string;
    salutation?:    string;
    preferedLang?:  string;
}

function toPersoninfoXML(pi: Personinfo):string{
    var result = '';

    if(pi.prefixTitle) result += `<prefixTitle>${pi.prefixTitle}</prefixTitle>`;
    if(pi.postfixTitle) result += `<postfixTitle>${pi.postfixTitle}</postfixTitle>`;
    if(pi.sex) result += `<sex>${pi.sex}</sex>`;
    if(pi.maritalStatus) result += `<maritalStatus>${pi.maritalStatus}</maritalStatus>`;
    if(pi.born) result += `<born>${pi.born}</born>`;
    if(pi.salutation) result += `<salutation>${pi.salutation}</salutation>`;
    if(pi.preferedLang) result += `<preferedLang>${pi.preferedLang}</preferedLang>`;

    return `<PERSONINFO>${result}</PERSONINFO>`;
}

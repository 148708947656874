import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Filter, toFilterXml } from '../../dataObjects/Filter';
import { Order, toOrderXml } from '../../dataObjects/Order';

export interface IListActivityAdvancedRequest extends IBaseRequest {
    limit?:     number;
    offset?:    number;
    orderBy?:   string;
    countOnly?: number;
    formItems?: FormItems;
    FILTER?:    Filter;
    ORDER?:     Order;
    FIELDS?:    Fields;
}

export interface FormItems {
    formItem: string[];
}
function toFormItemsXml(formItems: FormItems): string {
    var xml = `<formItems>`;
    formItems.formItem.forEach(formItem => {
        xml += `<formItem>${formItem}</formItem>`;
    });
    xml += `</formItems>`;
    
    return xml;
}


export interface Fields {
    field: string[];
}
function toFieldsXml(fields: Fields): string {
    var xml = `<FIELDS>`;
    fields.field.forEach(field => {
        xml += `<field>${field}</field>`;
    });
    xml += `</FIELDS>`;
    
    return xml;
}

export class ListActivityAdvancedRequest extends BaseRequest implements IListActivityAdvancedRequest {
    public limit?:     number;
    public offset?:    number;
    public orderBy?:   string;
    public countOnly?: number;
    public formItems?: FormItems;
    public FILTER?:    Filter;
    public ORDER?:     Order;
    public FIELDS?:    Fields;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.limit = otherOptions.limit;
        this.offset = otherOptions.offset;
        this.orderBy = otherOptions.orderBy;
        this.countOnly = otherOptions.countOnly;
        this.formItems = otherOptions.formItems;
        this.FILTER = otherOptions.FILTER;
        this.ORDER = otherOptions.ORDER;
        this.FIELDS = otherOptions.FIELDS;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        if (this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>`;
        if (this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>`;
        if (this.formItems) parameters += toFormItemsXml(this.formItems);
        if (this.FILTER) parameters += toFilterXml(this.FILTER);
        if (this.ORDER) parameters += toOrderXml(this.ORDER);
        if (this.FIELDS) parameters += toFieldsXml(this.FIELDS);
        
        return super.wrapperXml('ListActivityAdvanced', parameters);
    }
}

import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { Divider, Flex } from '@adobe/react-spectrum';
import { CreateBillingItemsFields, CreateContextInfoFields, CreateCustomFormFieldForContextType, CreateCustomFormFields, CreatePersonFields, CreateProjectFields, CreateSalesItemsFields, CreateTechnologyItemsFields, CreateTrackingCodeFields } from './ContextFields';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';
import { FPAData } from '../../../infra/protected/FPA/FPAData';

export interface IProjectContextFieldsProps {
    settings: SettingItem[];
    parentItem: FPAData | null;
    selectedItem?: FPATemplateItem;
    setFormData?: (key: string, value: any, isNew: boolean) => void;
    contextTypeFormId?: string;
    activityType?: number;
}

export function ProjectContextFields ({
    settings,
    parentItem,
    selectedItem,
    setFormData,
    contextTypeFormId,
    activityType
}: IProjectContextFieldsProps) {
  return (
    <Flex direction={'column'} gap={'size-100'}>
        <CreateProjectFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateTrackingCodeFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <Divider size='M' />
        <CreateContextInfoFields settings={settings} 
                                parentItem={parentItem} 
                                selectedItem={selectedItem} 
                                showFolders={true} 
                                showProjects={false} 
                                showActivities={false} 
                                activityType={activityType} 
                                setFormData={setFormData}
                                />
        <Divider size='M' />
        <CreateCustomFormFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateCustomFormFieldForContextType formId={contextTypeFormId} setFormData={setFormData} />
        <CreatePersonFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateSalesItemsFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateBillingItemsFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateTechnologyItemsFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
    </Flex>    
  );
}

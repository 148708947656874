import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Filter, toFilterXml } from '../../dataObjects/Filter';
import { Order, toOrderXml } from '../../dataObjects/Order';

export interface IListTimelineRequest extends IBaseRequest {
    limit?:        string;
    offset?:       string;
    orderBy?:      string;
    countOnly?:    string;
    contextRoot?:  string;
    includeSlave?: string;
    FILTER?:       Filter;
    ORDER?:        Order;
}

export class ListTimelineRequest extends BaseRequest implements IListTimelineRequest {

    public limit?:        string;
    public offset?:       string;
    public orderBy?:      string;
    public countOnly?:    string;
    public contextRoot?:  string;
    public includeSlave?: string;
    public FILTER?:       Filter;
    public ORDER?:        Order;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.limit = otherOptions.limit;
        this.offset = otherOptions.offset;
        this.orderBy = otherOptions.orderBy;
        this.countOnly = otherOptions.countOnly;
        this.contextRoot = otherOptions.contextRoot;
        this.includeSlave = otherOptions.includeSlave;
        this.FILTER = otherOptions.FILTER;
        this.ORDER = otherOptions.ORDER
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        if (this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>`;
        if (this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>`;
        if (this.contextRoot) parameters += `<contextRoot>${this.contextRoot}</contextRoot>`;
        if (this.includeSlave) parameters += `<includeSlave>${this.includeSlave}</includeSlave>`;
        if (this.FILTER) parameters += toFilterXml(this.FILTER);
        if (this.ORDER) parameters += toOrderXml(this.ORDER);

        return super.wrapperXml('ListTimeline', parameters);
    }
}

import { useCallback, useEffect, useState } from 'react';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { Offcanvas } from 'react-bootstrap';
import { Flex, View, Link, DialogContainer, Dialog, Content, Header } from '@adobe/react-spectrum';
import { Plus, Pen } from 'react-bootstrap-icons';
import { FilterIcon } from '../../../../components/protected/Icons/IconsLib';
import moment from 'moment';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { useTranslation } from 'react-i18next';
import UsersFilterComponent from '../../../../components/protected/layout-components/FilterComponent/UsersFilter/UsersFilterComponent';
import ProjectStatusFilterComponent from '../../../../components/protected/layout-components/FilterComponent/ProjectStatusFilter/ProjectStatusFilterComponent';
import ProjectTypeFilterComponent from '../../../../components/protected/layout-components/FilterComponent/ProjectTypeFilter/ProjectTypeFilterComponent';
import { ListRegisterRequest } from '../../../../services/soap/features/requests/ListRegisterRequest';
import styles from './filter_component.module.css';

interface IFilterComponentProps {
  onFilterChange: (filterParams: {}) => void;
  filterParams: any;
  filterStyle?: any;
}

const FilterComponent: React.FC<IFilterComponentProps> = ({ onFilterChange, filterParams, filterStyle }) => {
  const { t } = useTranslation();
  const selectedApp = useSelector((state: RootState) => state.userWebLayout.selectedAppId);
  const { store, featureService } = useDependency();
  const { isMobile } = useViewInfo();
  const [show, setShow] = useState(false);
  const [selectedRecordView, setSelectedRecordView] = useState<string>('All records');
  const [selectedPeriodView, setSelectedPeriodView] = useState<string>('Any period');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedRecordView('All records');
    setSelectedPeriodView('Any period');
    filterParams = { FILTER: { COL: [] } };
  }, [selectedApp]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createParams = useCallback(
    async (filterTypeName: any, filterValue: any) => {
      //initializeFilterParams();
      const filterValueStr = filterTypeName === 'state' ? filterValue : Array.from(filterValue).join(',');
      handleFilterType(filterTypeName, filterValueStr, filterValue);
    },
    [selectedApp]
  );

  const initializeFilterParams = async () => {
    let resListRegister = await featureService.listRegister(new ListRegisterRequest(store.Server, store.SessionId, undefined, undefined, undefined, undefined, +selectedApp));
    let projectActivityValue = resListRegister.ROWS.find((row: any) => row.ROW.key === 'FPATypeCategoryForListProject')?.ROW.value ?? '';

    if (!filterParams) {
      filterParams = {
        FILTER: {
          COL: [
            {
              name: 'fpaTypeCategory',
              type: 'any',
              value: projectActivityValue,
            },
          ],
        },
      };
    } else {
      const fpaTypeCategoryExists = filterParams.FILTER.COL.some((col: any) => col.name === 'fpaTypeCategory');
      if (!fpaTypeCategoryExists) {
        filterParams.FILTER.COL.push({
          name: 'fpaTypeCategory',
          type: 'any',
          value: projectActivityValue,
        });
      }
    }
  };

  const handleFilterType = (filterTypeName: any, filterValueStr: any, filterValue: any) => {
    switch (filterTypeName) {
      case 'type':
        updateFilterParams('projectType', 'equals', filterValueStr === 'All types' ? '' : filterValueStr);
        break;
      case 'state':
        updateFilterParams('state', 'in', filterValue === 'All records' ? '' : filterValue);
        break;
      case 'period':
        updateFilterParams('finalDate', filterValue, filterValue === 'Any period' ? '' : filterValue);
        break;
      case 'customState':
        updateFilterParams('customState', 'in', filterValueStr === 'All states' ? '' : filterValueStr);
        break;
      case 'user':
        updateFilterParams('ownerId', 'equals', filterValueStr === 'All users' ? '' : filterValueStr);
        break;
      default:
        break;
    }
    filterParams.FILTER.COL = filterParams.FILTER.COL.filter((col: any) => col.value !== '');
    //console.log('filterParams', filterParams);
    onFilterChange(filterParams);
  };

  const updateFilterParams = (name: string, type: string, value: string) => {
    let ownerObj = {};
    let greaterObj = {};

    if (name === 'state') {
      ({ value, ownerObj } = handleStateFilter(name, value, ownerObj));
    }

    if (name === 'finalDate') {
      ({ value, greaterObj } = handleFinalDateFilter(name, type, value, greaterObj));
    }

    let updatedArray = updateFilterArray(name, type, value, ownerObj, greaterObj);
    //console.log('updatedArray', updatedArray);
    filterParams.FILTER.COL = updatedArray;
  };

  const handleStateFilter = (name: string, value: string, ownerObj: any) => {
    if (value === '1,2,owner') {
      value = '1,2';
      ownerObj = { name: 'ownerId', type: 'equals', value: store.UserId };
    } else {
      filterParams.FILTER.COL = filterParams.FILTER.COL.filter((col: any) => col.name !== 'ownerId');
    }
    return { value, ownerObj };
  };

  const handleFinalDateFilter = (name: string, type: string, value: string, greaterObj: any) => {
    filterParams.FILTER.COL = filterParams.FILTER.COL.filter((col: any) => col.name !== 'finalDate');
    if (type === 'less') {
      value = moment().format('YYYY-MM-DDTHH:MM:ss');
    } else if (type === 'lessOrEquals') {
      value = moment().add(3, 'months').format('YYYY-MM-DDT00:00:00');
      greaterObj = { name: 'finalDate', type: 'greaterOrEquals', value: moment().format('YYYY-MM-DDT00:00:00') };
    } else if (type === 'greater') {
      value = moment().format('YYYY-MM-DDT00:00:00');
    }
    return { value, greaterObj };
  };

  const updateFilterArray = (name: string, type: string, value: string, ownerObj: any, greaterObj: any) => {
    let updatedArray = filterParams.FILTER.COL.map((obj: any) => (obj.name === name ? { ...obj, value } : obj));
    const hasNameSearch = filterParams.FILTER.COL.some((obj: any) => obj.name === name);

    if (!hasNameSearch) {
      updatedArray.push({ name, type, value });
    }

    if (ownerObj.hasOwnProperty('name')) {
      updatedArray.push(ownerObj);
    }

    if (greaterObj.hasOwnProperty('name')) {
      updatedArray.push(greaterObj);
    }
    return updatedArray;
  };

  return isMobile || window.innerWidth < 1030 ? (
    <View position={'relative'} width={'100%'} height={'64px'} UNSAFE_className={styles.mobile_header_parent}>
      <Flex direction={'column'} position={'absolute'} top={'calc(50% - 16px)'} left={'58px'} align-items={'start'} justifyContent={'start'}>
        <View position={'relative'} UNSAFE_className={styles.mobile_header_text_1}>
          {selectedRecordView === '1,2,owner'
            ? t('my_open_records', { ns: 'layout_components' })
            : selectedRecordView === '1,2'
            ? t('all_open_records', { ns: 'layout_components' })
            : t('all_records', { ns: 'layout_components' })}
        </View>
        <View position={'relative'} UNSAFE_className={styles.mobile_header_text_2}>
          {t('project_activities', { ns: 'layout_components' })}
        </View>
      </Flex>
      <View position={'absolute'} top={'calc(50% - 12px)'} left={'16px'} width={'24px'} height={'24px'} overflow={'hidden'}>
        <span onClick={handleShow} className="me-2">
          <i className="bi bi-list"></i>
        </span>
        <Offcanvas show={show} onHide={handleClose} placement="start">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Flex
              direction={'column'}
              position={'absolute'}
              top={'50px'}
              left={'15px'}
              width={'240px'}
              alignItems={'start'}
              justifyContent={'start'}
              gap={'5px'}
              UNSAFE_className={styles.views_menu_container}
            >
              <Flex direction={'row'} UNSAFE_className={styles.views_menu_heading} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
                <View position={'relative'} UNSAFE_className={styles.menu_heading_text}>
                  {t('views', { ns: 'layout_components' })}
                </View>
              </Flex>
              <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'4px'} marginBottom={'0px'} UNSAFE_className={styles.filter_views_parent}>
                <a
                  onClick={() => {
                    setSelectedRecordView('1,2,owner');
                    createParams('state', '1,2,owner');
                    handleClose();
                  }}
                >
                  <Flex
                    direction={'row'}
                    height={'36px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    UNSAFE_className={selectedRecordView === '1,2,owner' ? styles.filter_views_container_selected : styles.filter_views_container}
                  >
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('my_open_records', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>

                <a
                  onClick={() => {
                    setSelectedRecordView('1,2');
                    createParams('state', '1,2');
                    handleClose();
                  }}
                >
                  <Flex UNSAFE_className={selectedRecordView === '1,2' ? styles.filter_views_container_selected : styles.filter_views_container}>
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('all_open_records', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>

                <a
                  onClick={() => {
                    setSelectedRecordView('All records');
                    createParams('state', 'All records');
                    handleClose();
                  }}
                >
                  <Flex UNSAFE_className={selectedRecordView === 'All records' ? styles.filter_views_container_selected : styles.filter_views_container}>
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('all_records', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>
              </Flex>

              <View position={'relative'} width={'100%'} height={'1px'} UNSAFE_className={styles.separator}></View>
              <Flex
                position={'relative'}
                direction={'column'}
                alignSelf={'stretch'}
                alignItems={'start'}
                justifyContent={'start'}
                gap={'4px'}
                top={'10px'}
                UNSAFE_className={styles.filter_views_parent}
              >
                <a
                  onClick={() => {
                    setSelectedPeriodView('Any period');
                    createParams('period', 'Any period');
                    handleClose();
                  }}
                >
                  <Flex
                    direction={'row'}
                    height={'36px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    UNSAFE_className={selectedPeriodView === 'Any period' ? styles.filter_views_container_selected : styles.filter_views_container}
                  >
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('any_period', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>

                <a
                  onClick={() => {
                    setSelectedPeriodView('less');
                    createParams('period', 'less');
                    handleClose();
                  }}
                >
                  <Flex UNSAFE_className={selectedPeriodView === 'less' ? styles.filter_views_container_selected : styles.filter_views_container}>
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('past', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>

                <a
                  onClick={() => {
                    setSelectedPeriodView('lessOrEquals');
                    createParams('period', 'lessOrEquals');
                    handleClose();
                  }}
                >
                  <Flex UNSAFE_className={selectedPeriodView === 'lessOrEquals' ? styles.filter_views_container_selected : styles.filter_views_container}>
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('current_month_plus_2', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>

                <a
                  onClick={() => {
                    setSelectedPeriodView('greater');
                    createParams('period', 'greater');
                    handleClose();
                  }}
                >
                  <Flex UNSAFE_className={selectedPeriodView === 'greater' ? styles.filter_views_container_selected : styles.filter_views_container}>
                    <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                      <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                        {t('future', { ns: 'layout_components' })}
                      </Flex>
                    </Flex>
                  </Flex>
                </a>
              </Flex>
            </Flex>
            <View position={'relative'} top={'85%'} width={'100%'} height={'1px'} UNSAFE_className={styles.separator}>
              <Flex direction={'column'} position={'absolute'} width={'240px'} alignItems={'start'} justifyContent={'start'} gap={'5px'} UNSAFE_className={styles.views_menu_container}>
                <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.button_group_parent}>
                  <Flex position={'relative'} width={'266px'} height={'56px'} flexShrink={0} UNSAFE_className={styles.button_group_wrapper}>
                    <Flex direction={'row'} position={'absolute'} top={'calc(50% - 10px)'} left={'14px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.btn_style}>
                      <Link
                        isQuiet
                        onPress={() => {
                          console.log('Clicked on Add View');
                        }}
                      >
                        <>
                          <Plus fontSize={'25px'} />
                          {t('add_view', { ns: 'layout_components' })}
                        </>
                      </Link>
                    </Flex>
                  </Flex>
                  <Flex position={'relative'} width={'266px'} height={'56px'} flexShrink={0} UNSAFE_className={styles.button_group_wrapper}>
                    <Flex
                      direction={'row'}
                      position={'absolute'}
                      gap={'8px'}
                      top={'calc(50% - 10px)'}
                      left={'14px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      UNSAFE_className={styles.btn_style}
                    >
                      <Link
                        isQuiet
                        onPress={() => {
                          console.log('Clicked on Edit View');
                        }}
                      >
                        <>
                          <Pen />
                          {t('edit_view', { ns: 'layout_components' })}
                        </>
                      </Link>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </View>
          </Offcanvas.Body>
        </Offcanvas>
      </View>

      <View position={'absolute'} top={'calc(50% - 12px)'} left={'calc(100% - 40px)'} width={'24px'} height={'24px'} overflow={'hidden'}>
        <div onClick={() => setIsOpen(true)}>
          <FilterIcon size={20} />
        </div>

        <DialogContainer
          isDismissable
          onDismiss={() => {
            setIsOpen(false);
          }}
        >
          {isOpen && (
            <Dialog size="L">
              <Content>
                <Header>
                  <Flex position={'relative'} direction={'row'} justifyContent={'center'} alignContent={'center'} width={'100%'}>
                    <View position={'relative'} UNSAFE_className={styles.popup_header_text}>
                      {t('filters', { ns: 'layout_components' })}
                    </View>
                  </Flex>
                </Header>
                <View width={'100%'} position={'relative'} height={'100vh'}>
                  <Flex
                    direction={'column'}
                    position={'absolute'}
                    width={'95%'}
                    top={'30px'}
                    left={'16px'}
                    alignItems={'start'}
                    justifyContent={'start'}
                    gap={'24px'}
                    UNSAFE_className={styles.popup_header_body_parent}
                  >
                    <Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} gap={'12px'} UNSAFE_className={styles.popup_header_body}>
                      {<ProjectStatusFilterComponent createParams={createParams} filterParams={filterParams} />}
                      {<UsersFilterComponent createParams={createParams} filterParams={filterParams} />}
                      {<ProjectTypeFilterComponent createParams={createParams} filterParams={filterParams} />}
                      {/* {<CategoryFilterComponent createParams={createParams} />} */}
                    </Flex>
                  </Flex>

                  {/* <View position={'absolute'} bottom={'0px'} left={'0px'} width={'100%'} height={'80px'} overflow={'hidden'} UNSAFE_className={styles.popup_header_footer_parent}>
                      <Flex
                        direction={'row'}
                        position={'absolute'}
                        top={'16px'}
                        left={'16px'}
                        width={'90%'}
                        height={'48px'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        UNSAFE_className={styles.popup_header_footer}
                      >
                        <View position={'relative'} UNSAFE_className={styles.popup_header_footer_btn}>
                          Choose
                        </View>
                      </Flex>
                    </View> */}
                </View>
              </Content>
            </Dialog>
          )}
        </DialogContainer>
      </View>
    </View>
  ) : (
    <View width={'100%'} position={'relative'} backgroundColor={'static-white'} UNSAFE_className={styles.filter_menu_parent} UNSAFE_style={filterStyle}>
      <Flex
        direction={'column'}
        position={'relative'}
        top={'24px'}
        left={'0px'}
        width={'240px'}
        alignItems={'start'}
        justifyContent={'start'}
        gap={'5px'}
        UNSAFE_className={styles.views_menu_container}
      >
        <Flex direction={'row'} UNSAFE_className={styles.views_menu_heading} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
          <View position={'relative'} UNSAFE_className={styles.menu_heading_text}>
            {t('views', { ns: 'layout_components' })}
          </View>
        </Flex>

        <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'4px'} marginBottom={'0px'} UNSAFE_className={styles.filter_views_parent}>
          <a
            onClick={() => {
              setSelectedRecordView('1,2,owner');
              createParams('state', '1,2,owner');
            }}
          >
            <Flex
              direction={'row'}
              height={'36px'}
              alignItems={'center'}
              justifyContent={'center'}
              UNSAFE_className={selectedRecordView === '1,2,owner' ? styles.filter_views_container_selected : styles.filter_views_container}
            >
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('my_open_records', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>
          <a
            onClick={() => {
              setSelectedRecordView('1,2');
              createParams('state', '1,2');
            }}
          >
            <Flex UNSAFE_className={selectedRecordView === '1,2' ? styles.filter_views_container_selected : styles.filter_views_container}>
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('all_open_records', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>
          <a
            onClick={() => {
              setSelectedRecordView('All records');
              createParams('state', 'All records');
            }}
          >
            <Flex UNSAFE_className={selectedRecordView === 'All records' ? styles.filter_views_container_selected : styles.filter_views_container}>
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('all_records', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>
        </Flex>

        <View position={'relative'} width={'100%'} height={'1px'} UNSAFE_className={styles.separator}></View>

        <Flex position={'relative'} direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'4px'} top={'10px'} UNSAFE_className={styles.filter_views_parent}>
          <a
            onClick={() => {
              setSelectedPeriodView('Any period');
              createParams('period', 'Any period');
            }}
          >
            <Flex
              direction={'row'}
              height={'36px'}
              alignItems={'center'}
              justifyContent={'center'}
              UNSAFE_className={selectedPeriodView === 'Any period' ? styles.filter_views_container_selected : styles.filter_views_container}
            >
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('any_period', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>

          <a
            onClick={() => {
              setSelectedPeriodView('less');
              createParams('period', 'less');
            }}
          >
            <Flex UNSAFE_className={selectedPeriodView === 'less' ? styles.filter_views_container_selected : styles.filter_views_container}>
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('past', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>

          <a
            onClick={() => {
              setSelectedPeriodView('lessOrEquals');
              createParams('period', 'lessOrEquals');
            }}
          >
            <Flex UNSAFE_className={selectedPeriodView === 'lessOrEquals' ? styles.filter_views_container_selected : styles.filter_views_container}>
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('current_month_plus_2', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>

          <a
            onClick={() => {
              setSelectedPeriodView('greater');
              createParams('period', 'greater');
            }}
          >
            <Flex UNSAFE_className={selectedPeriodView === 'greater' ? styles.filter_views_container_selected : styles.filter_views_container}>
              <Flex direction={'row'} flex={1} alignItems={'center'} justifyContent={'start'}>
                <Flex position={'relative'} flex={1} UNSAFE_className={styles.filter_views_text}>
                  {t('future', { ns: 'layout_components' })}
                </Flex>
              </Flex>
            </Flex>
          </a>
        </Flex>
      </Flex>

      <View position={'relative'} top={'40px'} width={'100%'} height={'1px'} UNSAFE_className={styles.separator}></View>

      <Flex
        direction={'column'}
        position={'relative'}
        top={'50px'}
        left={'0px'}
        width={'240px'}
        alignItems={'start'}
        justifyContent={'start'}
        gap={'5px'}
        UNSAFE_className={styles.filters_menu_container}
      >
        <Flex direction={'row'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.filters_menu_wrapper}>
          <View position={'relative'} UNSAFE_className={styles.menu_heading_text}>
            {t('filters', { ns: 'layout_components' })}
          </View>
        </Flex>
        <ProjectStatusFilterComponent createParams={createParams} />
        <UsersFilterComponent createParams={createParams} />
        <ProjectTypeFilterComponent createParams={createParams} />
        {/* <CategoryFilterComponent createParams={createParams} /> */}
      </Flex>
    </View>
  );
};

export default FilterComponent;

import { SoapUtils } from '../../../../utils/SoapUtils';
import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListEventRequest extends IBaseRequest {
    userFilter?:         number;
    orderBy1Desc?:       string;
    orderBy1Field?:      string;
    orderBy2Desc?:       string;
    orderBy2Field?:      string;
    orderBy3Desc?:       string;
    orderBy3Field?:      string;
    orderBy4Desc?:       string;
    orderBy4Field?:      string;
    anyTime?:            number;
    standardStatus?:     number;
    entryType?:          string;
    activityId?:         number;
    projectId?:          number;
    folderId?:           number;
    approvalStatus?:     string;
    chargeable?:         string;
    uid?:                string;
    limit?:              number;
    offset?:             number;
    dateOfInterest?:     number;
    rangeEnd?:           Date;
    rangeStart?:         Date;
    solutionStatus?:     string;
    groupUsers?:         number;
    userId?:             number;
    recordId?:           string;
    resourceId?:         string;
    refid?:              string;
    dependend?:          string;
    plannedTaskCurrent?: string;
    dimension1?:         string;
    dimension2?:         string;
    dimension3?:         string;
    USER?:               EventUser[];
    RESOURCE?:           Resource[];
}

export interface EventUser {
    id: string;
}

function toEventUserXml(user: EventUser): string {
    return `<USER><id>${user.id}</id></USER>`;
}

export interface Resource {
    id?:           string;
    name?:         string;
    description?:  string;
    categoryId?:   string;
    needApproval?: string;
}

function toResourceXml(resource: Resource): string {
    var xml = '<RESOURCE>';
    if (resource.id) xml += `<id>${resource.id}</id>`;
    if (resource.name) xml += `<name>${resource.name}</name>`;
    if (resource.description) xml += `<description>${resource.description}</description>`;
    if (resource.categoryId) xml += `<categoryId>${resource.categoryId}</categoryId>`;
    if (resource.needApproval) xml += `<needApproval>${resource.needApproval}</needApproval>`;
    xml += '</RESOURCE>';

    return xml;
}
export class ListEventRequest extends BaseRequest implements IListEventRequest {

    public userFilter?:         number;
    public orderBy1Desc?:       string;
    public orderBy1Field?:      string;
    public orderBy2Desc?:       string;
    public orderBy2Field?:      string;
    public orderBy3Desc?:       string;
    public orderBy3Field?:      string;
    public orderBy4Desc?:       string;
    public orderBy4Field?:      string;
    public anyTime?:            number;
    public standardStatus?:     number;
    public entryType?:          string;
    public activityId?:         number;
    public projectId?:          number;
    public folderId?:           number;
    public approvalStatus?:     string;
    public chargeable?:         string;
    public uid?:                string;
    public limit?:              number;
    public offset?:             number;
    public dateOfInterest?:     number;
    public rangeEnd?:           Date;
    public rangeStart?:         Date;
    public solutionStatus?:     string;
    public groupUsers?:         number;
    public userId?:             number;
    public recordId?:           string;
    public resourceId?:         string;
    public refid?:              string;
    public dependend?:          string;
    public plannedTaskCurrent?: string;
    public dimension1?:         string;
    public dimension2?:         string;
    public dimension3?:         string;
    public USER?:               EventUser[];
    public RESOURCE?:           Resource[];
    
    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.userFilter = otherOptions.userFilter;
        this.orderBy1Desc = otherOptions.orderBy1Desc;
        this.orderBy1Field = otherOptions.orderBy1Field;
        this.orderBy2Desc = otherOptions.orderBy2Desc;
        this.orderBy2Field = otherOptions.orderBy2Field;
        this.orderBy3Desc = otherOptions.orderBy3Desc;
        this.orderBy3Field = otherOptions.orderBy3Field;
        this.orderBy4Desc = otherOptions.orderBy4Desc;
        this.orderBy4Field = otherOptions.orderBy4Field;
        this.anyTime = otherOptions.anyTime;
        this.standardStatus = otherOptions.standardStatus;
        this.entryType = otherOptions.entryType;
        this.activityId = otherOptions.activityId;
        this.projectId = otherOptions.projectId;
        this.folderId = otherOptions.folderId;
        this.approvalStatus = otherOptions.approvalStatus;
        this.chargeable = otherOptions.chargeable;
        this.uid = otherOptions.uid;
        this.limit = otherOptions.limit;
        this.offset = otherOptions.offset;
        this.dateOfInterest = otherOptions.dateOfInterest;
        this.rangeEnd = otherOptions.rangeEnd;
        this.rangeStart = otherOptions.rangeStart;
        this.solutionStatus = otherOptions.solutionStatus;
        this.groupUsers = otherOptions.groupUsers;
        this.userId = otherOptions.userId;
        this.recordId = otherOptions.recordId;
        this.resourceId = otherOptions.resourceId;
        this.refid = otherOptions.refid;
        this.dependend = otherOptions.dependend;
        this.plannedTaskCurrent = otherOptions.plannedTaskCurrent;
        this.dimension1 = otherOptions.dimension1;
        this.dimension2 = otherOptions.dimension2;
        this.dimension3 = otherOptions.dimension3;
        this.USER = otherOptions.USER;
        this.RESOURCE = otherOptions.RESOURCE;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.userFilter) parameters += `<userFilter>${this.userFilter}</userFilter>`;
        if (this.orderBy1Desc) parameters += `<orderBy1Desc>${this.orderBy1Desc}</orderBy1Desc>`;
        if (this.orderBy1Field) parameters += `<orderBy1Field>${this.orderBy1Field}</orderBy1Field>`;
        if (this.orderBy2Desc) parameters += `<orderBy2Desc>${this.orderBy2Desc}</orderBy2Desc>`;
        if (this.orderBy2Field) parameters += `<orderBy2Field>${this.orderBy2Field}</orderBy2Field>`;
        if (this.orderBy3Desc) parameters += `<orderBy3Desc>${this.orderBy3Desc}</orderBy3Desc>`;
        if (this.orderBy3Field) parameters += `<orderBy3Field>${this.orderBy3Field}</orderBy3Field>`;
        if (this.orderBy4Desc) parameters += `<orderBy4Desc>${this.orderBy4Desc}</orderBy4Desc>`;
        if (this.orderBy4Field) parameters += `<orderBy4Field>${this.orderBy4Field}</orderBy4Field>`;
        if (this.anyTime) parameters += `<anyTime>${this.anyTime}</anyTime>`;
        if (this.standardStatus) parameters += `<standardStatus>${this.standardStatus}</standardStatus>`;
        if (this.entryType) parameters += `<entryType>${this.entryType}</entryType>`;
        if (this.activityId) parameters += `<activityId>${this.activityId}</activityId>`;
        if (this.projectId) parameters += `<projectId>${this.projectId}</projectId>`;
        if (this.folderId) parameters += `<folderId>${this.folderId}</folderId>`;
        if (this.approvalStatus) parameters += `<approvalStatus>${this.approvalStatus}</approvalStatus>`;
        if (this.chargeable) parameters += `<chargeable>${this.chargeable}</chargeable>`;
        if (this.uid) parameters += `<uid>${this.uid}</uid>`;
        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        if (this.dateOfInterest) parameters += `<dateOfInterest>${this.dateOfInterest}</dateOfInterest>`;
        if (this.rangeEnd) parameters += `<rangeEnd>${SoapUtils.toDateForXml(this.rangeEnd)}</rangeEnd>`;
        if (this.rangeStart) parameters += `<rangeStart>${SoapUtils.toDateForXml(this.rangeStart)}</rangeStart>`;
        if (this.solutionStatus) parameters += `<solutionStatus>${this.solutionStatus}</solutionStatus>`;
        if (this.groupUsers) parameters += `<groupUsers>${this.groupUsers}</groupUsers>`;
        if (this.userId) parameters += `<userId>${this.userId}</userId>`;
        if (this.recordId) parameters += `<recordId>${this.recordId}</recordId>`;
        if (this.resourceId) parameters += `<resourceId>${this.resourceId}</resourceId>`;
        if (this.refid) parameters += `<refid>${this.refid}</refid>`;
        if (this.dependend) parameters += `<dependend>${this.dependend}</dependend>`;
        if (this.plannedTaskCurrent) parameters += `<plannedTaskCurrent>${this.plannedTaskCurrent}</plannedTaskCurrent>`;
        if (this.dimension1) parameters += `<dimension1>${this.dimension1}</dimension1>`;
        if (this.dimension2) parameters += `<dimension2>${this.dimension2}</dimension2>`;
        if (this.dimension3) parameters += `<dimension3>${this.dimension3}</dimension3>`;
        if (this.USER) this.USER.forEach(user => parameters += toEventUserXml(user));
        if (this.RESOURCE) this.RESOURCE.forEach(resource => parameters += toResourceXml(resource));

        return super.wrapperXml('ListEvent', parameters);
    }
}

import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { Divider, Flex } from '@adobe/react-spectrum';
import { CreateActivityFields, CreateActivityNameField, CreateBillingItemsFields, CreateContextInfoFields, CreateCustomFormFieldForContextType, CreateCustomFormFields, CreateMasterActivityFields, CreatePersonFields, CreateResponsibleUserFields, CreateTrackingCodeFields } from './ContextFields';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';
import { FPAData } from '../../../infra/protected/FPA/FPAData';

export interface IActivityContextFieldsProps {
    settings: SettingItem[];
    parentItem: FPAData | null;
    selectedItem?: FPATemplateItem;
    setFormData?: (key: string, value: any, isNew: boolean) => void;
    contextTypeFormId?: string;
    activityType?: number;
    haveMasterActivity?: boolean;
}

export function ActivityContextFields ({
    settings,
    parentItem,
    selectedItem,
    setFormData,
    contextTypeFormId,
    activityType,
    haveMasterActivity
}: IActivityContextFieldsProps) {
  return (
    <Flex direction={'column'} gap={'size-100'}>
        <CreateActivityNameField settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateActivityFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        {haveMasterActivity && (
          <>
            <Divider size='M' />
            <CreateMasterActivityFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} activityType={activityType} />
          </>
        )}
        <Divider size='M' />
        <CreateContextInfoFields settings={settings} 
                              parentItem={parentItem} 
                              selectedItem={selectedItem} 
                              setFormData={setFormData} 
                              showFolders={true} 
                              showProjects={true} 
                              showActivities={false} 
                              activityType={activityType} 
                              />
        <Divider size='M' />
        <CreateTrackingCodeFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <Divider size='M' />
        <CreatePersonFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <Divider size='M' />
        <CreateResponsibleUserFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <Divider size='M' />
        <CreateBillingItemsFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <Divider size='M' />
        <CreateCustomFormFields settings={settings} parentItem={parentItem} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateCustomFormFieldForContextType formId={contextTypeFormId} setFormData={setFormData} />
    </Flex>    
  );
}

import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApps } from '../../contexts/AppsProvider';

export interface IRedirectProps {
}

export function Redirect (props: IRedirectProps) {
    const { setRedirectUrl } = useApps();
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        console.log('got redirect url', location);
        setRedirectUrl(location.pathname + location.search);
    }, [location.pathname, setRedirectUrl]);
    setTimeout(() => { navigate('/') }, 100);
    return (<>Navigating in progress</>);
}
